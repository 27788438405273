<template>
    <div class="support wrap">
        <div class="filters">
            <a href="javascript:void(0);" class="filters__item" @click="$goMenu('notice')">공지사항</a>
            <a href="javascript:void(0);" class="filters__item active">자주하는 질문</a>
            <a href="javascript:void(0);" class="filters__item" @click="openRemoteSupport()">원격지원</a>
        </div>
        <div>
            <div class="faq">
                <div class="top">
                    <div class="filter faq__filter"  @click="select($event)">
                        <a href="javascript:void(0);" class="filter__item faq__filter--item" :class="item.comnCdNm == selectFilter ? 'active':''" v-for="item in custBoardCdList" v-bind:key="item.value" v-bind:data-filter="item.comnCd">{{item.comnCdNm}}</a>
                    </div>
                </div>
                <ul class="accordion">
                    <li class="accordion__item faq__group" data-filter="all one" v-for="item in boardFaq"  v-bind:key="item.value">
                        <div class="accordion__head faq__head" @click="toggleAccordion($event)">
                            <a href="javascript:void(0);" ><h2 class="txt__title">{{item.boardTitle}}</h2></a>
                        </div>
                        <div class="accordion__body faq__body">
                            <p class="txt__sub" v-html="item.boardContent">  </p>
                        </div>
                    </li>
                </ul>
                <div class="btn__moveTop">
                    <a href="javascript:void(0)" @click="moveTop"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Notice",
        metaInfo: {
            title: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                custBoardCdList: [],
                selectFilter : '전체',
                boardFaq : [],
            }
        },
        methods: {
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            },
            toggleAccordion(event) {
                const accordionHead = event.currentTarget;
                accordionHead.classList.toggle('active');
            },
            select(event) {
                const target = event.target;
                this.selectFilter = event.target.dataset.filter;

                if (target.classList.contains('filter__item')) {
                    const button = document.querySelectorAll('.filter__item');
                    const item = document.querySelectorAll('.accordion__item');
                    button.forEach(element => {
                        element.classList.remove('active');
                    });
                    target.classList.add('active');

                    const filter = target.dataset.filter;

                    for (let i = 0; i < this.boardFaq.length; i++) {
                        if (filter === '100') {
                            item[i].style.display = 'block';
                        } else {
                            if (this.boardFaq[i].boardCategoryCd === filter) {
                                item[i].style.display = 'block';
                            } else {
                                item[i].style.display = 'none';
                            }
                        }
                    }
                }
            },

            //자주하는질문 COMN_CODE 호출
            getNotiStatCd(){
                var params = {
                    "comnCdGroupId": "M_BOARD_CATEGORY_CD",
                };
                this.$http.post('/api/comnCode/list',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        var comnCodeList = res.data.comnCodeList;
                        for (var code in comnCodeList) {
                            if(parseInt(comnCodeList[code].comnCd) >= 100  && parseInt(comnCodeList[code].comnCd)  < 200){
                                // var obj = {
                                //     id: code[i].id,
                                //     notiNo: code[i].notiNo,
                                //     baseAmt: code[i].baseAmt,
                                //     notiNm: code[i].notiNm,
                                //     anlsBddprAmt: anlsBddprAmt,
                                // }
                                this.custBoardCdList.push(comnCodeList[code]);
                            }
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },

            //자주하는 질문 호출
            fnSearchBoardFaq(){
                this.boardFaq = [];

                var _limit = 9999;
                var _page = 0;

                var params = {
                    "pageUnit": _limit,
                    //페이지당 보여줄 수
                    "pageIndex": _page,
                    //마지막 index
                    "strtRecordIntex": _limit * _page,
                    "boardCd": 1
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        var data = res.data.boardList;

                        this.boardFaq = data;
                    })

                    .catch(error => {console.log(error);}
                    );

            },

            goMenu(menuUrl, id) {
                if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    this.$router.push('/'+menuUrl+'/'+id);
            },
            openRemoteSupport() {
                window.open("https://939.co.kr/sysoft/", "_blank", "width=865,height=700,scrollbars=yes");
            },

        },
        created() {

        },

        mounted() {
            this.getNotiStatCd();
            this.fnSearchBoardFaq();
        }
    }


</script>



<!--<style src="./NotiSuggest.scss" lang="scss" scoped />-->