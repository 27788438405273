<template>
    <v-app>
        <div   @click="click($event)">
          <div class="analysis test" @click="$mainClick($event)">
              <div class="inner">
                  <p class="title__sub--title">AI 분석실</p>
                  <div class="analysis__top">
                      <p class="title__sub--sub">총 <span>{{mock.notiTable.noti.length}}</span>건</p>
                      <div class="analysis__search">
                          <div class="analysis__search--detaile">
                              <p class="title__sub--title-filter">상세검색</p>
                              <img src="@/assets/ico_detale-search.svg" alt="상세검색 아이콘">
                          </div>
                          <div class="analysis__search--select">
                              <div class="select-box">
                                  <button class="select-box__sel " @click="selectBox()">공고명</button>
                                  <ul class="select-box__list" @click="select()">
                                      <li><button type="button">공고명</button></li>
                                      <li><button type="button">공고번호</button></li>
                                      <li><button type="button">기초금액</button></li>
                                      <li><button type="button">발주처명</button></li>
                                  </ul>
                              </div>
                          </div>
                          <div class="search-line">
                              <input type="text" placeholder="공고명, 공고번호를 검색해 주세요.">
                          </div>
                          <button class="btn__refresh"><img src="@/assets/ic_refresh.svg" alt="새로고침 아이콘"></button>
                      </div>
                  </div>
                  <div class="analysis__bottom">
                      <div class="analysis__list--wrap">
                          <div class="analysis__list--header">
                              <div class="analysis__list--subject"><p>공고명</p></div>
                              <div class="analysis__list--num"><p>공고번호</p></div>
                              <div class="analysis__list--base"><p>기초금액</p></div>
                              <div class="analysis__list--date"><p>마감일</p></div>
                              <div class="analysis__list--character"><p>분석가</p></div>
                              <div class="analysis__list--money"><p>분석금액</p></div>
                              <div class="analysis__list--attend"><p>투찰</p></div>
                          </div>
                          <ul class="analysis__list--body">
                              <li v-for="item in mock.notiTable.noti" v-bind:key="item.id">
                                  <div class="analysis__list--subject"><p>{{item.notiNm}}</p></div>
                                  <div class="analysis__list--num"><p>{{item.notiNo}}</p>
                                      <div @click="copyNotiNo(item.notiNo)">
                                      </div>
                                  </div>
                                  <div class="analysis__list--base"><p>{{item.baseAmt | currency}}</p></div>
                                  <div class="analysis__list--date"><p :class="$moment().format('YYYYMMDD') == $moment(item.bddprFinDt).format('YYYYMMDD') ? 'today' : ''">{{item.bddprFinDt}}</p></div>
                                  <div class="analysis__list--character rabbit" v-show="item.notiAnlstId == '2'"></div>
                                  <div class="analysis__list--character lion" v-show="item.notiAnlstId == '1'"></div>
                                  <div class="analysis__list--character bear" v-show="item.notiAnlstId == '3'"></div>
                                  <div class="analysis__list--character" v-show="item.notiAnlstId != '1' && item.notiAnlstId != '2' && item.notiAnlstId != '3'"></div>
                                  <div class="analysis__list--money">
                                      <div class="price" v-show="item.anlstChoiceFlag == 'Y'">
                                          <p>{{item.anlsBddprAmt | currency}}</p>
                                      </div>
                                      <div class="ing" v-show="item.anlstChoiceFlag != 'Y' && item.anlsBddprAmt == 0">
                                          <p>분석중</p>
                                      </div>
                                      <div class="complete" v-show="item.anlstChoiceFlag != 'Y' && item.anlsBddprAmt != 0" >
                                          <p @click="confirmAmt(item.custNotiAnlsId, item.anlsBddprAmt, item.anlsBddprRt, item.custNotiId, item.notiId, item.custBizId)">분석제시가확인</p>
                                      </div>
                                  </div>
                                  <div class="analysis__list--attend" v-show="$moment().format('YYYYMMDDHHmmss') <= $moment(item.bddprFinDt).format('YYYYMMDDHHmmss')" @click="goSite(item.notiNo, item.rpsntOrgnzCd)">
                                      <a href="javascript:void(0)" class="btn-bid"><img src="@/assets/ic_go_bid.svg" alt="투찰하기 아이콘"><p>투찰하기</p></a>
                                  </div>
                              </li>
                              <!--                          <li>-->
                              <!--                              <div class="analysis__list&#45;&#45;checkbox"><input type="checkbox"></div>-->
                              <!--                              <div class="analysis__list&#45;&#45;subject"><p>공고명</p></div>-->
                              <!--                              <div class="analysis__list&#45;&#45;num"><p>공고 번호</p><div></div></div>-->
                              <!--                              <div class="analysis__list&#45;&#45;date"><p>마감일</p></div>-->
                              <!--                              <div class="analysis__list&#45;&#45;attend"><a href="#" class="btn-bid"><img src="@/assets/ic_bid.svg" alt="투찰하기 아이콘"></a></div>-->
                              <!--                          </li>-->
                          </ul>
                      </div>
                  </div>
                  <div>
                      <v-snackbar
                              v-model="snackbar"
                              vertical
                              color="blue-grey"
                              :timeout="900"
                      >
                          <div class="text-subtitle-1 pb-2">{{copyText}}</div>
                          <p>공고번호가 복사되었습니다.</p>
                      </v-snackbar>
                  </div>
              </div>
          </div>
        </div>
        <div class="filter" @click="click($event)">
            <div class="filter__wrap">
                <div class="filter__wrap--top">
                    <p class="filter__title">필터</p>
                    <div class="filter-close__wrap"><button class="filter-close"><img src="@/assets/ic_close.svg"  alt="닫기 버튼" class="btn-close"></button></div>
                </div>
                <div class="filter__wrap--bottom">
                    <div class="filter__item state">
                        <p class="filter__title">상태</p>
                        <div class="filter__option">
                            <a href="#" class="filter__btn"><p>전체</p></a>
                            <a href="#" class="filter__btn"><p>입찰전</p></a>
                            <a href="#" class="filter__btn"><p>입찰중</p></a>
                            <a href="#" class="filter__btn"><p>입찰끝</p></a>
                            <a href="#" class="filter__btn"><p>개찰끝</p></a>
                            <a href="#" class="filter__btn"><p>취소</p></a>
                        </div>
                    </div>
                    <div class="filter__item region">
                        <p class="filter__title">지역</p>
                        <div class="filter__option">
                            <a href="#" class="filter__btn"><p>전체</p></a>
                            <a href="#" class="filter__btn"><p>서울</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>경기</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>인천</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>울산</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>부산</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>세종</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>대구</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>강원</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                        </div>
                    </div>
                    <div class="filter__item license">
                        <p class="filter__title">면허</p>
                        <div class="filter__option--top">
                            <div class="search filter__option--input">
                                <input type="text" placeholder="공고명, 공고번호를 검색해 주세요.">
                            </div>
                        </div>
                        <div class="filter__option">
                            <a href="#" class="filter__btn"><p>건축공사업</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>토목공사업</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>토목건축공사업</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>조경공사업</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>산업·환경설비공사업</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>지반조성·포장공사업(대)</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                            <a href="#" class="filter__btn"><p>실내건축공사업(대)</p><img src="@/assets/ic_arrow_drop-down.svg" alt="화살표 아이콘"></a>
                        </div>
                    </div>
                    <div class="filter__item cost">
                        <p class="filter__title">금액</p>
                        <div class="filter__option--top">
                            <span class="filter__title--money">5천</span><span>~</span><span class="filter__title--money">20억</span><span class="filter__title--subscript">*기초금액 수준</span>
                        </div>
                        <div class="filter__option">
                            <div class="filter__money"><div class="filter__money--handler"></div></div>
                            <div class="filter__money--title"><span>최소</span><span>1억</span><span>10억</span><span>최대</span></div>
                        </div>
                    </div>
                    <div class="filter__item priod">
                        <p class="filter__title">기간</p>
                        <div class="filter__option--top">
                            <div class="input-style"><input type="text" placeholder="2023-01-26"></div>
                            <span>~</span>
                            <div class="input-style"><input type="text"  placeholder="2023-02-16"></div>
                        </div>
                        <div class="filter__option">
                            <a href="#" class="filter__btn"><img src="@/assets/ic_plma.svg" alt="플러스 마이너스 기호"><p>15일</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_plma.svg" alt="플러스 마이너스 기호"><p>1개월</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_plma.svg" alt="플러스 마이너스 기호"><p>3개월</p></a>
                        </div>
                    </div>
                    <div class="filter__item company">
                        <p class="filter__title">발주처</p>
                        <div class="filter__option">
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_01.svg" alt="나라장터(G28)로고"><p>나라장터(G28)</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_02.svg" alt="한국전력공사"><p>한국전력공사</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_03.svg" alt="국방종합전자조달"><p>국방종합전자조달</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_04.svg" alt="한국토지주택공사 로고"><p>한국토지주택공사</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_05.svg" alt="한국도로공사 로고"><p>한국도로공사 </p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_06.svg" alt="한국수력원자력 로고"><p>한국수력원자력</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_07.svg" alt="한국수자원공사 로고"><p>한국수자원공사</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_08.svg" alt="인천국제공항 로고"><p>인천국제공항</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_09.svg" alt="한국가스공사 로고"><p>한국가스공사</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_10.svg" alt="한국마사회 로고"><p>한국마사회</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_11.svg" alt="한국전자통신연구원 로고"><p>한국전자통신연구원</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_12.svg" alt="한국철도공사 로고"><p>한국철도공사</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_13.svg" alt="국가철도공단 로고"><p>국가철도공단</p></a>
                            <a href="#" class="filter__btn"><img src="@/assets/ic_company-logo_14.svg" alt="한국석유공사 로고"><p>한국석유공사</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
    import mock from './mock';

    export default {
        name: "Ai_Analysis",
        data() {
            return {
                mock,
                snackbar: false,
                copyText: '',
            }
        },
        created() {
            //console.log(localStorage.getItem('authToken'));
        },
        mounted() {
            this.getNotiAnlsProgInfoList();
        },
        methods: {
            goSite(notiNo, rpsntOrgnzCd) {
                var url = '';
                if (rpsntOrgnzCd == 1) { // 나라장터
                    url = 'https://www.g2b.go.kr/pt/menu/selectSubFrame.do?framesrc=/pt/menu/frameTgong.do?url=https://www.g2b.go.kr:8101/ep/tbid/tbidList.do?bidSearchType=1&bidno='+notiNo+'&searchType=2';
                } else if (rpsntOrgnzCd == 2) { // 한전
                    this.copyNotiNo(notiNo);
                    url = 'https://srm.kepco.net/index.do';
                } else if (rpsntOrgnzCd == 3) { // 국방부
                    this.copyNotiNo(notiNo);
                    url = 'https://www.d2b.go.kr/peb/bid/announceList.do?key=41';
                }

                window.open(url,"popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
            },
            selectBox() {
                var btn = document.querySelector('.select-box__sel');
                btn.classList.toggle('on');
            },
            select(){

                var btn = document.querySelector('.select-box__sel');
                if (event.target.nodeName === "BUTTON") {
                    btn.innerText = event.target.innerText;
                    btn.classList.remove('on');
                }
            },
            getNotiAnlsProgInfoList() {
                var custInfo = JSON.parse(localStorage.getItem("custInfo"));

                var postData = {pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    searchDate: this.$moment().subtract(1, 'years').format('YYYYMMDD'),
                    custBizId: custInfo.custBizList[0].id,
                    orderBySt: 'bddprFinDt',
                    notiOpbdListYn: 'Y',
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                mock.notiTable.noti = [];

                this.$http.post('/api/noti/notiAnlsProgInfoList',postData, config)
                    .then(res => {
                        //console.log(res.data);
                        var notiList = res.data.notiProgInfoList;

                        for (var i in notiList) {

                            // 분석제시목록
                            var custNotiAnlsList = notiList[i]['custNotiAnlsList'];
                            var anlsBddprAmt = 0;
                            var anlsBddprRt = 0;
                            var custNotiId = '';
                            var notiId = '';
                            var custBizId = '';
                            var anlstChoiceFlag = '';
                            var custNotiAnlsId = '';
                            //var anlstNickNm = '';
                            for (var j in custNotiAnlsList) {
                                if (j == 0) {
                                    anlsBddprAmt = custNotiAnlsList[j].anlsBddprAmt; // 분석제시가
                                    anlsBddprRt = custNotiAnlsList[j].anlsBddprRt; // 분석지점
                                    custNotiId = custNotiAnlsList[j].custNotiId; // 분석지점
                                    notiId = custNotiAnlsList[j].notiId;
                                    custBizId = custNotiAnlsList[j].custBizId;
                                    anlstChoiceFlag = custNotiAnlsList[j].anlstChoiceFlag;
                                    custNotiAnlsId = custNotiAnlsList[j].id;
                                    //anlstNickNm = custNotiAnlsList[j].anlstNickNm; // AI닉네임
                                }
                            }

                            var obj = {
                                id: notiList[i].id,
                                notiNo: notiList[i].notiNo,
                                baseAmt: notiList[i].baseAmt,
                                notiNm: notiList[i].notiNm,
                                anlsBddprAmt: anlsBddprAmt,
                                anlsBddprRt: anlsBddprRt,
                                custNotiId: custNotiId,
                                notiId: notiId,
                                custBizId: custBizId,
                                anlstChoiceFlag: anlstChoiceFlag,
                                custNotiAnlsId: custNotiAnlsId,
                                notiAnlstId: notiList[i].notiAnlstId,
                                rpsntOrgnzCd: notiList[i].rpsntOrgnzCd,
                                bddprFinDt: notiList[i].bddprFinDt,
                            }

                            if (this.$moment().format('YYYYMMDDHHmmss') <= this.$moment(notiList[i].bddprFinDt).format('YYYYMMDDHHmmss'))
                                mock.notiTable.noti.push(obj)
                        }

                    })
                    .catch(error => console.log(error));
            },
            copyNotiNo(notiNo) {
                this.$copyText(notiNo);
                this.snackbar = true;
                this.copyText = notiNo;
                //alert(notiNo + " 공고번호를 복사했습니다.");
            },
            click($event) {
                //상세 검색 아이콘 클릭 > 필터 on
                if ($event.target.closest('.analysis__search--detaile') !== null) {
                    document.querySelector('.filter').classList.toggle('on');
                } else if ($event.target.closest('.analysis__search--detaile') === null) { // 필터 영역 제외한 부분 클릭시 on 클래스 제거
                    if ($event.target.closest('.filter__wrap') === null) {
                        document.querySelector('.filter').classList.remove('on');
                    }
                }
                //필터 영역의 끄기 버튼
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                //셀렉트 박스 dud
                if (document.querySelector('.select-box__sel').classList.contains('on') && $event.target !== document.querySelector('.select-box__sel')) {
                    document.querySelector('.select-box__sel').classList.remove('on');
                }
                //로그인 바 제외한 영역 클릭시 on 클래스 제거
                // if (document.querySelector('.login-bar--wrap').classList.contains('on')) {
                //     document.querySelector('.login-bar--wrap').classList.remove('on');
                // }
            },
            confirmAmt(id, anlsBddprAmt, anlsBddprRt, custNotiId, notiId, custBizId) {
                if(confirm('분석가격을 확인하시겠습니까?')){
                    var postData = {
                        id: id,
                        anlsBddprAmt: anlsBddprAmt,
                        anlsBddprRt: anlsBddprRt,
                        custNotiId: custNotiId,
                        notiId: notiId,
                        custBizId: custBizId,
                        anlstChoiceFlag: 'Y',
                    };

                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'auth-token': localStorage.getItem("authToken"),
                            'refresh-token': localStorage.getItem("refreshToken"),
                        },
                    };

                    this.$http.post('/api/noti/updateAnlsChoiceFlag',postData, config)
                        .then(res => {
                            console.log(res.data);
                            this.$router.go();
                        })
                        .catch(error => console.log(error));
                }
            }
        }
    }
</script>

<style>
    .autocomplete {
        position: relative;
        display: inline-block;
    }

    .autocomplete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        background-color: #e9e9e9;
    }

    .autocomplete-active {
        background-color: DodgerBlue !important;
        color: #ffffff;
    }
</style>
<style src="./AiAnalysis.scss" lang="scss" scoped/>
<link rel="stylesheet" href="./autocomplete.css">