<template>
    <!-- 자동 분석 설정 변경 -->

        <div class="popup__item--container recom-announce__pop--container">
            <div class="popup__item--group recom-announce__pop--group enter-pop__group set-pop__group">
                <div class="popup__item--head">
                    <p class="txt__title">자동 분석 설정 {{isAutoAnls ? '변경' : ''}}</p>
                </div>
                <div class="popup__item--body recom-announce__pop--body">
                    <div class="set-pop__unit fist-set--help" v-show="!isAutoAnls">
                        <p class="txt--title">
                            투찰 마감일 기준 4일치(당일 포함) 공고가 <span class="empha">매일 오전 8시에</span>
                            자동 분석 요청됩니다.
                        </p>
                    </div>
                    <div class="set-pop__unit durtin__unit">
                        <h3 class="h3">① 기간<i>*주말 및 공휴일 제외</i></h3>
                        <div class="set-pop__box durtin--input">
                            <div class="input">
                                <input type="text" class="disable" placeholder="" :value="startDt | yyyyMMdd">
                            </div>
                            <span>~</span>
                            <div class="input">
                                <input type="text" class="disable" placeholder="" :value="endDt | yyyyMMdd">
                            </div>
                        </div>
                        <ul class="set-pop__box durtin__box--wrap">
                            <li class="durtin__box--item">
                                <input type="radio" name="durtin" id="durtin01" :checked="anlsPeriod == 7 ? true : false">
                                <label for="durtin01" class="set-pop--btn durtin--btn--item" @click="anlsPeriod = 7;  calsNextDate(7); calsBddprNextDate(7);">7일</label>
                            </li>
                            <li class="durtin__box--item">
                                <input type="radio" name="durtin" id="durtin02" :checked="anlsPeriod == 15 ? true : false">
                                <label for="durtin02" class="set-pop--btn durtin--btn--item" @click="anlsPeriod  = 15;  calsNextDate(15); calsBddprNextDate(15);">15일</label>
                            </li>
                            <li class="durtin__box--item">

                                <input type="radio" name="durtin" id="durtin03" :checked="anlsPeriod == 30 ? true : false">
                                <label for="durtin03" class="set-pop--btn durtin--btn--item" @click="anlsPeriod = 30; calsNextDate(30);  calsBddprNextDate(30);">30일</label>
                            </li>
                            <li class="durtin__box--item" v-if="$parent.loginInfo.subStatusCd == 1 && $parent.loginInfo.subDday > 0">

                                <input type="radio" name="durtin" id="durtin04" :checked="anlsPeriod == $parent.loginInfo.subDday ? true : false">
                                <label for="durtin04" class="set-pop--btn durtin--btn--item" @click="anlsPeriod = $parent.loginInfo.subDday; calsNextDate(Number($parent.loginInfo.subDday)+1);  calsBddprNextDate(Number($parent.loginInfo.subDday)+1);">멤버십 종료일</label>
                            </li>
                        </ul>
                        <!--                            <p class="durtin&#45;&#45;input&#45;&#45;txt comment-btn">투찰마감일 기준: {{bddprStartDt | yyyyMMdd}} ~ {{bddprEndDt | yyyyMMdd}}</p>-->
                    </div>
                    <div class="set-pop__unit analist__unit">
                        <h3 class="h3">② 분석가</h3>
                        <div class="set-pop__box analist__box">
                            <label  v-for="anlst in notiAnlstList" v-bind:key="anlst.id" :for="'check_'+anlst.id"
                                    class="analist--btn analist--btn--label" :class="(anlst.anlstType=='AI' ? 'ai' : '') + (selAnlstList.find((e) => e == anlst.id) ? ' active':'')"
                                    @click.prevent="clickAnlstList(anlst.id)"  >
                                <div class="input--check analist--btn--check">
                                    <input type="checkbox" :id="'check_'+anlst.id" name="analist" value="suri" :checked="(selAnlstList.find((e) => e == anlst.id) ? ' checked':'')">
                                    <label :for="'check_'+anlst.id" ></label>
                                </div>
                                <img :src="anlst.iconImg" alt="분석가 수리" class="analist--btn--img suri">
                                <span class="analist--btn--txt">{{anlst.anlstNickNm}}</span>
                            </label>
                        </div>
                        <p class="txt--announce">
                            * 성공보수료<span class="deco"> -  </span><span class="line-break">AI분석가 : 낙찰가의 {{aiFee}}% (부가세 포함)</span><span class="deco"> / </span><span class="line-break">분석기업 : 낙찰가의 {{coFee}}% (부가세 포함)</span><br/>
                            * AI멤버십 이용 성공보수료<span class="deco"> -  </span><span class="line-break">AI분석가 : 무료</span><span class="deco"> / </span><span class="line-break">분석기업: 낙찰가의 2% (부가세포함)</span>
                        </p>
                    </div>
                    <div class="set-pop__unit exception__unit">
                        <h3 class="h3">③ 자동 분석 제외</h3>
                        <div class="analist__box">
                            <div class="analist--check class">
                                <input type="checkbox" id="check21" @click="selfacilityYn = !selfacilityYn" :checked="selfacilityYn">
                                <label for="check21">용역, 물품 제외</label>
                            </div>
                            <div class="analist--check local">
                                <input type="checkbox" id="check20" @click="selwnctyYn = !selwnctyYn" :checked="selwnctyYn">
                                <label for="check20">전국 제외</label>
                            </div>
                        </div>
                        <div class="exception__box">

                        </div>
                    </div>

                </div>
            </div>
            <div class="popup__button--wrap pair">
                <a href="javascript:void(0)" class="btn-basic btn-line-cb  popup__close" @click="$parent.isPopSetAutoAnls = false">취소</a>
                <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" :class="isProcessing ? 'loading-box' : ''" @click="insertAutoAnlsConfig(true)"><LoadingSpinner v-show="isProcessing"></LoadingSpinner>{{isProcessing ? 'ㅤ' : '저장'}}</a>
            </div>
        </div>

    <!-- /자동 분석 설정 변경 -->

</template>
<script>
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';
    import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
    import * as _ from 'lodash';

    export default {
        components: {
            LoadingSpinner,
        },
        data(){
            return{
                loginInfo: {},
                isAutoAnls: false,  //자동분석 여부
                startDt: this.$moment().format('YYYY-MM-DD'),    //시작일
                endDt: this.$moment().format('YYYY-MM-DD'),
                anlsPeriod: 7, //분석기간
                selfacilityYn: true,  //자동분석 용역,물품 제외 여부
                selwnctyYn: true, //자동분석 전국 제외 여부

                notiAnlstList: [],  //분석가목록
                selAnlstList: [], //분석가리스트 선택
                holidayMngmtList: [],   //공휴일 목록
                isProcessing: false, //클릭여부

                aiFee: 1.8, // 내 AI 수수료 정보
                coFee: 2, // 내 협력사 수수료 정보

                subDday: '', //AI멤버십 DDay
                isSelAISub: false // AI멤버십 종료일 선택
            }
        },
        filters: {
            bsnPerdText:  function(value) {

                if (value == '0') {
                    return '1년미만';
                } else if (value == '1') {
                    return '1년~3년';
                } else if (value == '2') {
                    return '3년~5년';
                }  else if (value == '3') {
                    return '5년이상';
                }
                return '';
            },
            yyyyMMdd : function(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if(value == '') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if(month < 10){
                    month = '0' + month;
                }

                if(day < 10){
                    day = '0' + day;
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            }
        },
        watch: {
            isPopSetAutoAnls(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopOffAutoAnal(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
        },

        methods: {

            //자동분석 정보
            getAutoAnlsConfigList(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                }
                this.$post('/api/autoAnlsConfig/list',param)
                    .then(res => {
                        this.autoAnlsConfigList = res.autoAnlsConfigList;

                        if(this.autoAnlsConfigList.length>0) {
                            this.isAutoAnls = true;
                            this.selfacilityYn =  this.autoAnlsConfigList[0].facilityYn == 'N' ? false : true;
                            this.selwnctyYn =  this.autoAnlsConfigList[0].wnctyYn == 'Y' ? true : false;
                            this.startDt = this.autoAnlsConfigList[0].anlsStartDt;
                            this.endDt = this.autoAnlsConfigList[0].anlsEndDt;
                            if(this.autoAnlsConfigList[0].anlsPeriod != '0'){
                                this.anlsPeriod = this.autoAnlsConfigList[0].anlsPeriod;
                            }

                            for (var i in this.autoAnlsConfigList) {
                                this.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                            }

                        } else {
                            this.calsNextDate(7);
                            this.calsBddprNextDate(7);

                            if (this.$parent.loginInfo.subStatusCd == 1 && this.$parent.loginInfo.subDday > 0) {
                                this.calsNextDate(Number(this.$parent.loginInfo.subDday)+1);
                                this.calsBddprNextDate(Number(this.$parent.loginInfo.subDday)+1);
                                this.anlsPeriod = this.$parent.loginInfo.subDday;
                                this.selAnlstList = [3,2,1];
                            }
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            calcBddprDt(dt){
                var targetDt = this.$moment(dt);
                var bddprDate = 3;

                while(bddprDate != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    bddprDate--;
                }
                return targetDt.format('YYYY-MM-DD');
            },
            //공휴일 정보
            getHolidayMngmtList(){

                var param = {
                    "startDt" : this.$moment().format('YYYY-MM-DD'),
                    "endDt" : this.$moment().add(90, 'days') .format('YYYY-MM-DD'),
                }
                this.$post('/api/holidayMngmt/list',param)
                    .then(res => {
                        this.holidayMngmtList = res.holidayMngmtList;
                    })
                    .catch(error => {console.log(error);}
                    );

            },

            //날짜 계산
            calsNextDate(date){
                date --;
                var targetDt = this.$moment();
                this.startDt = this.$moment().format('YYYY-MM-DD');

                while(date != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    date--;
                }
                this.endDt = targetDt.format('YYYY-MM-DD');
                //holidayMngmtList
            },
            //날짜 계산
            calsBddprNextDate(date){
                date --;
                var targetDt = this.$moment();
                var bddprDate = 3;

                while(bddprDate != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    bddprDate--;
                }

                this.bddprStartDt = targetDt.format('YYYY-MM-DD');

                while(date != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    date--;
                }
                this.bddprEndDt = targetDt.format('YYYY-MM-DD');
                //holidayMngmtList
            },
            isHolidayCheck(targetDt){
                if (this.$parent.loginInfo.subStatusCd == 1 && this.$parent.loginInfo.subDday > 0) { // 멤버십이 아닐 경우만 주말 및 공휴일 체크
                    return false;
                } else {
                    var index = this.holidayMngmtList.findIndex(item => item.holiday ===  targetDt.format('YYYY-MM-DD'));

                    var dayOfWeek = targetDt.weekday();

                    if(index != -1 || dayOfWeek == 0 || dayOfWeek == 6) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            //자동분석 저장
            insertAutoAnlsConfig(autoAnls) {
                if(autoAnls && this.selAnlstList.length == 0 ){
                    this.$toast.error('한 개 이상의 분석가 선택이 필요합니다.');
                    return;
                }
                if(this.isProcessing){
                    return;
                }

                this.isProcessing = true;


                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "anlsPeriod": this.anlsPeriod.toString(),
                    "facilityYn" : this.selfacilityYn ? 'Y' : 'N',
                    "wnctyYn" : this.selwnctyYn ? 'Y' : 'N',
                    "anlsStartDt": this.startDt,
                    "anlsEndDt": this.endDt,
                    "autoAnlsStatus" : autoAnls ? 'Y' : 'N',
                    "notiAnlstList": this.selAnlstList,
                };


                this.$post('/api/autoAnlsConfig/insert', param)
                    .then(res => {
                        this.isProcessing = false;
                        if(res.code == 'OK'){
                            if(autoAnls && !this.isAutoAnls){
                                //자동분석 수정
                                this.isAutoAnls = true;
                                this.$toast('자동분석이 켜졌습니다.\n분석실에서 분석제시가를 확인해 주세요.');
                            }else if (autoAnls && this.isAutoAnls){
                                this.isAutoAnls = true;
                                this.$toast('자동분석 설정이 변경되었습니다');
                            }else{
                                this.isAutoAnls = false
                                this.$toast('자동분석이 꺼졌습니다.\n지금부터 자동 분석 요청이 중단됩니다.');
                            }
                            //자동분석 설정 리셋
                            this.selAnlstList = [];

                            this.$parent.isPopSetAutoAnls = false;
                            //this.getAutoAnlsConfigList();
                            //this.reloadNotiSuggestList();

                            this.getCustInfo2();
                        } else {
                            this.$toast(res.msg);
                        }
                    })
                    .catch(error => {
                            console.log(error);
                            this.isProcessing = false;
                        }
                    );

            },
            //내 수수료 정보
            getMyAnlsFee(){
                this.$get('/api/anlsFee/myAnlsFee')
                    .then(res => {
                        this.myAnlsFee = res.myAnlsFee;
                        if(this.myAnlsFee.length > 0){
                            this.aiFee = res.myAnlsFee.find((e) => e.anlstType == 'AI').anlsFee;
                            this.coFee = res.myAnlsFee.find((e) => e.anlstType == 'CO').anlsFee;
                        }
                    })
                    .catch(error => console.log(error));
            },
            //분석가목록 선택
            clickAnlstList(id){
                var idx = this.selAnlstList.findIndex((e)=> e == id);

                if (idx == -1) {
                    //못찾았으면
                    this.selAnlstList.push(id);
                } else {
                    this.selAnlstList.splice(idx,1);
                }
            },
            //내 정보 갱신
            getCustInfo2(){
                this.$get('/api/cust/custInfo/select')
                    .then(res => {
                        localStorage.setItem("custInfo",JSON.stringify(res.data));
                        this.$router.go(0);
                    })
                    .catch(error => {console.log(error);}
                    );
            },
            //분석가 목록
            getNotiAnlst() {
                this.notiAnlstList = [];

                this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                        this.notiAnlstList = _.filter(notiAnlstList, function(o) {
                            return o.id == '1' || o.id == '2' || o.id == '3' || o.id == '4' || o.id == '5' || o.id == '6' ||
                                    o.id == '7' || o.id == '8' || o.id == '9';
                        });
                    })
                    .catch(error => console.log(error));
            },

        },

        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            //쿠키
            var facilityYnCookie = this.$cookies.get("facilityYn");
            var wnctyYnCookie = this.$cookies.get("wnctyYn");
            var accordionActiveCookie = this.$cookies.get("accordionActive");

            if(facilityYnCookie != null){
                this.facilityYn = facilityYnCookie == 'true' ? true: false;
            }
            if(wnctyYnCookie != null){
                this.wnctyYn = wnctyYnCookie == 'true' ? true: false;
            }
            if(accordionActiveCookie != null) {
                this.accordionActive = accordionActiveCookie == 'true' ? true: false;
            }


            if(this.loginInfo != null) {
                //자동분석 정보
                this.getAutoAnlsConfigList();

                //수수료 정보
                this.getMyAnlsFee();
            }

            this.getNotiAnlst();

            //공휴일 정보
            this.getHolidayMngmtList();

        }
    }
</script>