<template>
    <div class="clause clause__app wrap">
        <!-- 제목 -->
        <div class="clause__txt--box">
            <h2 class="menu__title clause__txt--title">이용약관</h2>
            <h2 class="clause__txt--sub">비드톡톡 정보 관련 제반 서비스의 이용과 관련하여 필요한 사항을 규정합니다.</h2>
        </div>
        <!-- 상단 테이블 -->
        <div class="clause__group clause__index">
            <!-- 이용약관 -->
            <!-- 제1조 ~ 제12조 -->
            <ul class="clause__list--wrap clause__index--list">
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list1')" class="clause__list--title clause__index--title">제 1 조</a>
                    <a href="javascript:void(0)" @click="move('list1')" class="clause__list--sub clause__index--sub">(목적)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list2')" class="clause__list--title clause__index--title">제 2 조</a>
                    <a href="javascript:void(0)" @click="move('list2')" class="clause__list--sub clause__index--sub">(약관의 명시와 개정)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list3')" class="clause__list--title clause__index--title">제 3 조</a>
                    <a href="javascript:void(0)" @click="move('list3')" class="clause__list--sub clause__index--sub">(약관 이외의 준칙)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list4')" class="clause__list--title clause__index--title">제 4 조</a>
                    <a href="javascript:void(0)" @click="move('list4')" class="clause__list--sub clause__index--sub">(용어의 정의)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list5')" class="clause__list--title clause__index--title">제 5 조</a>
                    <a href="javascript:void(0)" @click="move('list5')" class="clause__list--sub clause__index--sub">(이용계약의 성립)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list6')" class="clause__list--title clause__index--title">제 6 조</a>
                    <a href="javascript:void(0)" @click="move('list6')" class="clause__list--sub clause__index--sub">(이용자 번호(ID) 부여 및 변경 등)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list7')" class="clause__list--title clause__index--title">제 7 조</a>
                    <a href="javascript:void(0)" @click="move('list7')" class="clause__list--sub clause__index--sub">(이용자 번호(ID) 및 비밀번호(PW)에 대한 의무)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list8')" class="clause__list--title clause__index--title">제 8 조</a>
                    <a href="javascript:void(0)" @click="move('list8')" class="clause__list--sub clause__index--sub">(이용자 정보의 변경)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list9')" class="clause__list--title clause__index--title">제 9 조</a>
                    <a href="javascript:void(0)" @click="move('list9')" class="clause__list--sub clause__index--sub">(서비스 이용 / 이용대금)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list10')" class="clause__list--title clause__index--title">제 10 조</a>
                    <a href="javascript:void(0)" @click="move('list10')" class="clause__list--sub clause__index--sub">(분석서비스 이용 / 성공보수료)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list11')" class="clause__list--title clause__index--title">제 11 조</a>
                    <a href="javascript:void(0)" @click="move('list11')" class="clause__list--sub clause__index--sub">(회원의 등급 및 톡포인트의 제공)</a>
                </li>
            </ul>
            <!-- //제1조 ~ 제12조 -->

            <!-- 제9조 ~ 부칙 -->
            <ul class="clause__list--wrap clause__index--list">
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list12')" class="clause__list--title clause__index--title">제 12 조</a>
                    <a href="javascript:void(0)" @click="move('list12')" class="clause__list--sub clause__index--sub">(대금결제방법)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list13')" class="clause__list--title clause__index--title">제 13 조</a>
                    <a href="javascript:void(0)" @click="move('list13')" class="clause__list--sub clause__index--sub">(제시가격의 이행여부 인정기준)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list14')" class="clause__list--title clause__index--title">제 14 조</a>
                    <a href="javascript:void(0)" @click="move('list14')" class="clause__list--sub clause__index--sub">(이용자 정보의 보호)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list15')" class="clause__list--title clause__index--title">제 15 조</a>
                    <a href="javascript:void(0)" @click="move('list15')" class="clause__list--sub clause__index--sub">(회원의 의무)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list16')" class="clause__list--title clause__index--title">제 16 조</a>
                    <a href="javascript:void(0)" @click="move('list16')" class="clause__list--sub clause__index--sub">(게시물의 관리)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list17')" class="clause__list--title clause__index--title">제 17 조</a>
                    <a href="javascript:void(0)" @click="move('list17')" class="clause__list--sub clause__index--sub">(제공 서비스)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list18')" class="clause__list--title clause__index--title">제 18 조</a>
                    <a href="javascript:void(0)" @click="move('list18')" class="clause__list--sub clause__index--sub">(서비스의 중지 및 공지)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list19')" class="clause__list--title clause__index--title">제 19 조</a>
                    <a href="javascript:void(0)" @click="move('list19')" class="clause__list--sub clause__index--sub">(회원의 탈퇴 및 자격상실)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list20')" class="clause__list--title clause__index--title">제 20 조</a>
                    <a href="javascript:void(0)" @click="move('list20')" class="clause__list--sub clause__index--sub">(면책)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list21')" class="clause__list--title clause__index--title">제 21 조</a>
                    <a href="javascript:void(0)" @click="move('list21')" class="clause__list--sub clause__index--sub">(분쟁의 해결)</a>
                </li>
                <li class="clause__list--item">
                    <a href="javascript:void(0)" @click="move('list22')" class="clause__list--title clause__index--title">부&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;칙</a>
                    <a href="javascript:void(0)" @click="move('list22')" class="clause__list--sub clause__index--sub">(부칙)</a>
                </li>
            </ul>
            <!-- //제13조 ~ 부칙 -->
        </div>
        <!-- //상단 테이블 -->

        <!-- 제 1 조 (목 적) -->
        <div class="clause__group" id="list1" ref="list1">
            <h2 class="clause__group--tit">제 1 조 (목 적)</h2>
            <p class="clause__group--info"><span>본 약관은 (주)비드톡톡(이하 “당사”)이 제공하는 입찰정보 서비스 (한글명 : {{siteNm}}, 도메인 주소 :
                {{siteUrl}}, 어플리에이션 : 비드톡톡 이하 '당 사이트')의 이용조건 및 가입절차, 이용자와 당 사이트의 권리, 의무, 책임사항과 기타 필요한 사항 등을 규정함을
                목적으로 합니다.</span></p>
        </div>
        <!-- 제 1 조 (목 적) -->

        <!-- 제 2 조 (약관의 명시와 개정) -->
        <div class="clause__group" id="list2" ref="list2">
            <h2 class="clause__group--tit">제 2 조 (약관의 명시와 개정)</h2>
            <p class="clause__group--info">
                <span>①  당 사이트는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 초기화면 내 또는 별도의 연결화면에 게시하거나 팝업화면 등으로 제공합니다.</span><br />
                <span> ② 당 사이트는 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 및 정보보호이용등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련 법령에 저촉되지
                않는 범위 내에서 본 약관을 개정할 수 있습니다.</span><br />
                <span>③ 본 약관의 개정은 적용일자 및 개정사유를 명시하여 당 사이트의 초기화면에 그 적용일자 이전에 상당기간을 공지함으로써 그 효력이 발생합니다.<br />
                단, 약관의 유료 계약조건에 관한 기회원의 권리가 회원에게 불리한 경우는 그러하지 않습니다.</span><br />
                <span>④ 당 사이트는 이용자가 본 약관 내용에 동의하는 것을 조건으로 서비스를 제공하며, 귀하가 본 약관의 내용에 동의하는 경우, 당 사이트의 서비스 제공 행위 및 이용자의
                    서비스 사용 행위는 본 약관이 우선적으로 적용됩니다.</span><br />
                <span>⑤ 이 약관에 동의하는 것은 정기적으로 웹사이트 또는 어플리케이션을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 당 사이트에서 책임지지 않습니다.</span><br />
                <span>⑥ 회원은 변경된 약관에 동의하지 않을 경우 회원은 탈퇴(해지)를 요청할 수 있습니다.<br />다만, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 의제됩니다.</span>
            </p>
        </div>
        <!-- //제 2 조 (약관의 명시와 개정) -->

        <!-- 제 3 조 (약관 이외의 준칙) -->
        <div class="clause__group" id="list3" ref="list3">
            <h2 class="clause__group--tit">제 3 조 (약관 이외의 준칙)</h2>
            <p class="clause__group--info">
                <span>① 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신윤리위원회 심의규정, 정보통신 윤리강령, 프로그램 보호법 및 기타 관련 법령에 의합니다.</span>
            </p>
        </div>
        <!-- //제 3 조 (약관 이외의 준칙) -->

        <!-- 제 4 조 (용어의 정의) -->
        <div class="clause__group" id="list4" ref="list4">
            <h2 class="clause__group--tit">제 4 조 (용어의 정의)</h2>
            <span class="clause__group--info">
                <span>① 본 약관에서 사용하는 용어는 다음과 같습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. ‘이용자’ 라 함은 회원제 서비스를 이용하는 이용자를 의미합니다.</span>
                    <span>2. ‘이용계약’ 이라 함은 서비스 이용과 관련하여 당 사이트와 이용자 간에 체결하는 계약을 의미합니다.</span>
                    <span>3. ‘가입’ 이라 함은 당 사이트가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위를 의미합니다.</span>
                    <span>4.  ‘회원’ 이라 함은 당 사이트에 회원가입에 필요한 개인 정보를 제공하여 회원 등록을 한 자로서, 당 사이트의 정보 및 서비스를 이용할 수 있는 자를 말합니다.</span>
                    <span>5. ‘이용자 번호 (ID)' 라 함은 이용자를 구별할 수 있는 사업자 번호를 의미합니다.</span>
                    <span>6. ‘비밀번호 (PW)' 라 함은 이용자가 등록회원과 동일인인지 신원을 확인하고, 통신상의 개인정보보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합을
                        의미합니다.</span>
                    <span> 7. ‘탈퇴 또는 해지’ : 회원이 이용계약을 종료시키는 행위를 의미합니다.</span>
                </span>
                <span>  ② 본 약관에서 정의하지 않은 용어 및 본 약관의 해석은 전자거래소비자보호지침 및 약관의규제에관한법률 등의 관련법규 및 상관습에 의합니다.</span>
            </span>
        </div>
        <!-- //제 4 조 (용어의 정의) -->

        <!-- 제 5 조 (이용계약의 성립) -->
        <div class="clause__group" id="list5" ref="list5">
            <h2 class="clause__group--tit">제 5 조 (이용계약의 성립)</h2>
            <p class="clause__group--info">
                <span>① 이용계약은 이용신청자의 청약(이용신청)과 당사의 승낙(이용승낙)으로 성립합니다.</span><br />
                <span>② 이용신청자의 청약은 본 약관의 ‘동의’ 버튼을 표시함으로 갈음합니다.</span><br />
                <span>③ 당사는 이용신청자의 청약에 대해 이용자 번호(ID)를 선정하여 주는 것으로 승낙의 의사표시를 갈음합니다.</span><br />
                <span>④ 당사는 각 호에 해당하는 경우에는 승낙 제한 사유가 해소될 때까지 제3항의 승낙을 유보할 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 서비스 관련 제반 용량이 부족한 경우</span>
                    <span>2. 기술상 장애 사유가 있는 경우</span>
                    <span>3. 기타 회사가 필요하다고 인정되는 경우</span>
               </span>
                <span>⑤ 당사는 각 호에 해당하는 경우에는 제3항의 승낙을 아니 하거나, 승낙의 의사표시를 취소, 철회할 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 다른 사람의 명의를 사용하여 신청한 경우</span>
                    <span>2. 이용신청 시 이용자 정보를 허위로 기재하여 신청한 경우</span>
                    <span>3. 사회의 안녕질서 혹은 미풍양속을 저해할 목적으로 신청한 경우</span>
                    <span>4. 타 회원의 당 사이트 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 한 경우</span>
                    <span>5. 당 사이트의 정보를 도용할 목적으로 신청하거나 신청하였을 경우</span>
                    <span>6. 본 약관에 의해 이전에 회원 자격을 상실한 적이 있는 경우</span>
                    <span>7. 기타 당 사이트가 정한 이용신청요건을 충족하지 못한 경우 ⑥ 분석서비스 계약은 별도의 낙찰수수료 및 서비스 요금을 산정한 "전자입찰 분석정보 제공계약서"를 통해 낙찰분석서비스를 의뢰함으로써 성립합니다.</span>
               </span>
            </p>
        </div>
        <!-- //제 5 조 (이용계약의 성립) -->

        <!-- 제 6 조 (이용자 번호(ID) 부여 및 변경 등) -->
        <div class="clause__group" id="list6" ref="list6">
            <h2 class="clause__group--tit">제 6 조 (이용자 번호(ID) 부여 및 변경 등)</h2>
            <p class="clause__group--info">
                <span>① 당사는 전 조에 따른 이용 신청자에 대해 약관이 정하는 방에 따라 이용자 번호(ID)를 부여합니다.</span><br />
                <span>② 당사는 이용자 번호(ID)에 의하여 고객계좌 입금 등 모든 제반 회원 관리업무를 수행하므로, 한번 가입한 이용자 번호(ID)는 변경되지 않습니다. 다만, 각 호에 해당하는 경우에는 회원 또는 당사의 요청으로 변경할 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 이용자 번호(ID)가 이용자의 전화번호 등으로 등록되어 사생활침해가 우려되는 경우</span>
                    <span>2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</span>
                    <span>3. 기타 합리적인 사유가 있는 경우</span>
                </span>
            </p>
        </div>
        <!-- //제 6 조 (이용자 번호(ID) 부여 및 변경 등) -->

        <!-- 제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무) -->
        <div class="clause__group" id="list7" ref="list7">
            <h2 class="clause__group--tit">제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무)</h2>
            <p class="clause__group--info">
                <span>① 이용자 번호(ID) 및 비밀번호(PW)에 관한 관리책임은 회원에게 있습니다.</span>
                <span>② 회원은 이용자 번호(ID) 및 비밀번호(PW)를 제3자에게 알려 주거나 이용하게 하여서는 안됩니다.</span>
                <span>③ 회원은 이용자 번호(ID) 및 비밀번호(PW)를 도용당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 당사에 연락하여 조치를 취할 수 있도록 하여야 합니다.</span>
            </p>
        </div>
        <!-- //제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무) -->
        <!-- //제 8 조 (이용자 정보의 변경) -->
        <div class="clause__group" id="list8" ref="list8">
            <h2 class="clause__group--tit">제 8 조 (이용자 정보의 변경)</h2>
            <p class="clause__group--info">
                <span>① 이용자는 이용신청시 기재한 이용자의 정보가 변경되었을 경우에는 온라인으로 수정하거나 당사에 통보하여 수정하도록 하여야 하며, 미변경으로 인하여 발생되는 문제의 책임은 이용자가 부담합니다.</span>
            </p>
        </div>
        <!-- 제 9 조 (서비스의 요금) -->
        <div class="clause__group" id="list9" ref="list9">
            <h2 class="clause__group--tit">제 9 조 (서비스 이용 / 이용대금)</h2>
            <p class="clause__group--info">
                <span>① 이용자가 서비스를 이용하기 위해서는 회사가 공지사항에 게시하거나 해당서비스 이용안내에서 제시하는 절차를 준수하여야 하며, 해당 서비스의 내용 및 책임한계 또한 공지사항에 게시된 내용 및 해당 서비스 이용안내에 따릅니다.</span>
                <span>② 서비스 이용대금은 회사가 공지사항에 게시하거나 해당 서비스 이용안내에서 제시하는 바에 따릅니다. 회사가 제공하는 서비스는 그 이용대금을 별도 게시하거나 제시하지 않는 한 무료로 합니다.</span>
                <span>③ 분석서비스(유료서비스)는 회사가 공지사항에 게시하거나 본 약관 제9조의 2의 이용안내에서 제시하는 바에 따라 성립됩니다.</span>
                <span>④ AI멤버십(월 결제 유료 서비스)의 지불한 “계약 금액”은 환불이 불가능합니다. </span>
            </p>
        </div>
        <!-- //제 9 조 (이용자 정보의 보호) -->

        <!-- 제 10조 (분석서비스 이용 / 성공보수료)-->
        <div class="clause__group clause__charge" id="list10" ref="list10">
            <h2 class="clause__group--tit">제 10조 (분석서비스 이용 / 성공보수료)</h2>
            <span class="clause__list--title clause__charge--subject vat">&#60;표1&#62;</span>
            <ul class="clause__list--wrap clause__charge--list" v-for="(items, index) in anlsFeeGroup"  v-bind:key="items.id">
                <li class="clause__list--item" v-for="item in items"  v-bind:key="item.id" :class="index == 0 ? 'clause__charge--border' : ''">
                    <span class="clause__list--title clause__charge--title">{{item.anlstNickNm}}</span>
                    <span class="clause__list--sub clause__charge--sub">낙찰가의 {{item.anlsFee.toFixed(1)}}%</span>
                </li>
            </ul>
            <span class="clause__list--title clause__charge--subject">&#60;표2&#62;</span>
            <ul class="clause__list--wrap clause__index--list clause__contract--list clause__contract--border">
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">계약명</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">전자입찰 분석정보 제공 계약</span>
                </li>
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">성공보수료</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">&#60;표1&#62;에서 제시하는 바에 따름</span>
                </li>
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">계약 기간</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">본 약관 동의 후 1년</span>
                </li>
            </ul>
            <ul class="clause__list--wrap clause__index--list clause__contract--list">
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">계약 금액</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">금 <strong> &nbsp;&nbsp;0&nbsp;&nbsp; </strong> 원(VAT포함)</span>
                </li>
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">성공보수료 지급조건</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">*낙찰일로부터 <strong>&nbsp;&nbsp;14일&nbsp;&nbsp;</strong> 이내</span>
                </li>
                <li class="clause__list--item">
                    <span class="clause__list--title clause__charge--title clause__contract--title">기타</span>
                    <span class="clause__list--sub clause__charge--sub clause__contract--sub">*계약 기간 경과 후 상호 이의가 없을 경우 자동 연장됩니다.</span>
                </li>
            </ul>
            <p class="clause__group--info"><br /><br /><br />
                <span>① 이용자가 회사로부터 분석기업에게 분석요청하여 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(이용자가 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이 확정된 경우, 이용자보다 선 순위의 입찰 참가자가 있음에도 불구하고 이용자가 최종 낙찰이 된 경우 등을 포함) 이용자는 회사에게 &#60;표2&#62;에서 정한 “성공 보수료”를 지급합니다. 단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급합니다.</span><br />
                <span>② 공동 도급을 진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 &#60;표2&#62;에서 정한 성공 보수료를 지급합니다.</span><br />
                <span>③ 이용자는 회사의 웹페이지 및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 이용자가 해당 공고건의 입찰에 참여한 경우 회사로부터 제시 받은 가격으로 입찰에 참여한 것으로 보아 ①항을 적용하기로 합니다</span><br />
                <span>④ 분석기업으로 분석요청한 공고의 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex 만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급합니다. (이용약관 제12조 참조)</span><br />
                <span>⑤ 이용자는 &#60;표2&#62;에서 정한 “성공 보수료”를 회사의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 회사는 이용자에 대한 정보 제공을 중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 이용자는 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, 회사는 이용자의 지불 능력 기타 제반 사정을 참작하여 지급 유예를 승인할 수 있습니다.(단,지급 유예 기간은 최장 60일 이내로 합니다.)</span><br />
                <span>⑥ 분석제시 가격을 타인에게 양도 하는 등 부정사용이 적발된 경우 부정사용에 대한 모든 민형사상 책임은 본인(법인포함) 또는 부정 사용한 자에게 귀속됩니다.</span>
            </p>
        </div>
        <!-- //제 10 조 (이용자 정보의 보호) -->

        <!-- 제 11 조 (회원의 의무) -->
        <div class="clause__group" id="list11" ref="list11">
            <h2 class="clause__group--tit">제 11 조 (회원의 등급 및 톡포인트의 제공)</h2>
            <p class="clause__group--info">
                <span>① 회사는 서비스를 이용하는 회원에게 할인 혜택이 제공되는 등급을 이용 여부에 따라 부여할 수 있으며, 일부 서비스의 결제 수단으로 사용할 수 있는 톡포인트를 지급 할 수 있습니다.</span><br />
                <span>② 톡포인트는 비드톡톡 서비스 내에서만 사용 가능하며, 어떠한 경우에도 현금으로 보상되지 않습니다.</span><br />
                <span>③ 회원은 지급받은 등급 또는 톡포인트에 대해 회원 본인만 사용할 수 있는 권한을 가지며, 어떠한 경우에도 이를 타인에게 매매 또는 양도할 수 없으며, 부정적 목적이나 용도로 사용할 수 없습니다. 만약 이를 어긴 경우, 사유를 미리(미리 통지할 수 없는 경우에는 사후 통지함) 해당 회원에게 통지하고, 지급된 등급 또는 톡포인트를 회수 할 수 있습니다.</span><br />
                <span>④ 톡포인트는 회사가 지정한 서비스 내에서만 사용 가능합니다.</span><br />
                <span>⑤ 결제 취소나 환불 등 톡포인트 발행의 원인이 된 사건이 무효화 경우, 해당 톡포인트의 사용이 불가능 할 수 있습니다.</span><br />
                <span>⑥ 등급 또는 톡포인트의 유효기간, 사용조건 및 제한에 관한 사항을 서비스 화면에 별도로 게시하거나 통지하며, 해당 사항은 회사 정책에 따라 달라 질 수 있습니다.</span><br />
                <span>⑦ 등급 또는 톡포인트의 유효기간이 만료 되거나 이용 계약이 취소, 종료되면 자동으로 소멸 됩니다.</span><br />
                <span>⑧ 등급 또는 톡포인트는 회원에게 지급된 시점부터 사용 가능 합니다. 단. 회사의 사정에 의해 사용 가능 시간이 지연 될 수 있습니다.</span>
            </p>
        </div>
        <!-- //제 11 조 (회원의 의무) -->

        <!-- 제 12 조 (게시물의 관리) -->
        <div class="clause__group" id="list12" ref="list12">
            <h2 class="clause__group--tit">제 12 조 (대금결제방법)</h2>
            <p class="clause__group--info">
                <span>① 분석서비스를 이용하여 최종 낙찰자로 선정되어 성공보수료 등에 대한 대금지급 방법은 다음 각 호의 하나로 할 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 온라인 무통장 입금, 폰 뱅킹, 인터넷 뱅킹 등의 각종 계좌 이체</span>
                    <span>2. 선불카드, 직불카드, 신용카드 등 각종 카드 결제</span>
                    <span>3. 전자 화폐에 의한 결제</span>
                    <span>4. 마일리지 등 비드톡톡이 제공한 포인트에 의한 결제</span>
                    <span>5. 기타 전자적 지급 방법에 의한 대금 지급 등</span>
                </span>
                <span>② 회사가 제공하는 분석서비스(유료서비스)에 대한 이용 요금의 결제방법은 회사가 정하는 바에 따르며, 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다.</span>
            </p>
        </div>
        <!-- //제 12 조 (게시물의 관리) -->

        <!-- 제 13 조 (제시가격의 이행여부 인정기준) -->
        <div class="clause__group standard" id="list13" ref="list13">
            <h2 class="clause__group--tit">제 13 조 (제시가격의 이행여부 인정기준)</h2>
            <p class="clause__spanst--item clause__standard--subject">
                <span class="clause__list--title clause__standard--title">ex) 비드톡톡 제시가 1,234,567,891 원</span>
            </p>
            <div class="clause__standard--group">
                <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                    <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">만 단위 (10,000원)</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,234,5<strong>7</strong>7,891</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,234,<strong>5</strong>57,891</span>
                    </li>
                </ul>
                <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                    <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">십만 단위 (100,000원)</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,234,<strong>6</strong>67,891</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,234,<strong>4</strong>67,891</span>
                    </li>
                </ul>
                <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                    <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">백만 단위 (1,000,000원)</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,23<strong>5</strong>,567,891</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,23<strong>3</strong>,567,891</span>
                    </li>
                </ul>
                <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                    <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">천만 단위 (10,000,000원)</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,2<strong>4</strong>4,567,891</span>
                    </li>
                    <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,2<strong>2</strong>4,567,891</span>
                    </li>
                </ul>
            </div>
            <p class="clause__group--info"><br />
                <span>위와 같이 비드톡톡 제시가를 기준으로 단위의 숫자가 동일한 가운데 한가지 또는 두가지 숫자가 변경된 경우 비드톡톡 제시가로 이행여부의 범위를 인정한다.</span>
            </p>
        </div>
        <!-- //제 13 조 (제시가격의 이행여부 인정기준) -->

        <!-- 제 14 조 (이용자 정보의 보호) -->
        <div div class="clause__group" id="list14" ref="list14">
            <h2 class="clause__group--tit">제 14 조 (이용자 정보의 보호)</h2>
            <p class="clause__group--info">
                <span>① 이용자의 개인 정보에 대해서는 당 사이트의 개인정보 보호정책이 적용됩니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 당사자의 개인정보 보호정책은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고 , 회사는 그 변경사항에 대하여 지체 없이 사용자에게 통보합니다.</span>
                </span>
                <span>② 이용자 정보의 관리에 관한 내용은 당 사이트의 개인정보 보호정책에 위임합니다. 단, 다음과 같은 경우에는 합리적인 이유로 이용자 정보를 공개할 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 서비스 이용계약의 이행 및 요금정산을 위하여 필요한 경우</span>
                    <span>2. 배송업무가 필요한 경우(배송업무에 필요한 최소한의 범위로 한정)</span>
                    <span>3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우(특정개인을 식별할 수 없는 형태로 제공)</span>
                    <span>4. 기타 관련 법률에서 정한 적법절차에 의한 요청이 있는 경우</span>
                </span>
            </p>
        </div>
        <!-- //제 14 조 (이용자 정보의 보호) -->

        <!-- 제 15 조 (회원의 의무) -->
        <div class="clause__group" id="list15" ref="list15">
            <h2 class="clause__group--tit">제 15 조 (회원의 의무)</h2>
            <p class="clause__group--info">
                <span>① 회원의 서비스 이용시 다음 각 호의 행위는 금지 되어 집니다.</span>
                <span class="clause__group--info indent">
                      <span>1. 다른 회원의 이용자 번호(ID)를 부정하게 사용하는 행위</span>
                      <span>2. 서비스에서 얻은 정보를 회사의 사전승낙 없이 이용고객의 이용 이외의 목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게 제공하는 행위</span>
                      <span>3. 당사의 저작권 또는 타인의 저작권 등 기타 권리를 침해하는 행위</span>
                      <span>4. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위</span>
                      <span>5. 범죄와 결부된다고 객관적으로 판단되는 행위</span>
                      <span>6. 관계 법령에 위배되는 행위</span>
                      <span>7. 기타 당사가 객관적으로 부적절하다고 판단되는 행위</span>
                </span>
                <span>② 회원은 관계 법령 및 당 약관에서 규정하는 사항, 서비스 이용안내 및 주의 사항을 준수하여야 합니다.</span><br />
                <span>③ 회원은 내용별로 당사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.</span><br />
                <span>④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</span>
            </p>
        </div>
        <!-- //제 15 조 (회원의 의무) -->
        <!-- 제 16 조 (게시물의 관리) -->
        <div class="clause__group" id="list16" ref="list16">
            <h2 class="clause__group--tit">제 16 조 (게시물의 관리)</h2>
            <p class="clause__group--info">
                <span>① 당사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.</span>
                <span class="clause__group--info indent">
                      <span>1. 다른 회원 또는 제3자에게 모욕을 주거나 명예를 훼손하는 내용인 경우</span>
                      <span>2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</span>
                      <span>3. 불법복제 또는 해킹을 조장하는 경우</span>
                      <span>4. 영리를 목적으로 하는 광고일 경우</span>
                      <span>5. 범죄와 결부된다고 객관적으로 인정되는 경우</span>
                      <span>6. 다른 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</span>
                      <span>7. 당사에서 규정한 게시물 원칙에 반하거나, 게시판 성격에 부합하지 않는 경우</span>
                      <span>8. 기타 관련 법령에 위반된다고 판단되는 경우</span>
                </span>
            </p>
        </div>
        <!-- //제 16 조 (게시물의 관리) -->
        <!--제 17 조 (제공 서비스)  -->
        <div class="clause__group" id="list17" ref="list17">
            <h2 class="clause__group--tit">제 17 조 (제공 서비스)</h2>
            <p class="clause__group--info">
                <span>① 당 사이트는 당사와 회원의 계약의 종류에 따라 다음과 같은 내용을 서비스 합니다.</span>
                <span class="clause__group--info indent">
                      <span>1. 입찰공고의 제공</span>
                      <span>2. 낙찰공고의 제공</span>
                      <span>3. 분석서비스 회원에 한하여 낙찰 예상가격의 제시</span>
                      <span>4. 기타 입찰 관련 서비스</span>
                      <span>※ 위와 같은 내용을 고객이 요청시에는 문자나 카카오톡으로도 서비스합니다.</span>
                </span>
            </p>
        </div>
        <!--//제 17 조 (제공 서비스) -->
        <!-- 제 18 조 (서비스의 중지 및 공지) -->
        <div class="clause__group" id="list18" ref="list18">
            <h2 class="clause__group--tit">제 18 조 (서비스의 중지 및 공지)</h2>
            <p class="clause__group--info">
                <span>① 당사는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간 통신사업자가 전기통신 서비스를 중지하는 등 기타 불가항력적 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</span><br />
                <span>② 당사는 전 항의 규정에 의하여 서비스의 이용을 제한하거나 중지하는 경우에는 지체 없이 회원에게 공지하여야 합니다. 단, 공지의 방법은 당 사이트의 초기화면에 게재한 것으로 갈음할 수 있습니다.</span><br />
                <span>③ 당사의 귀책 있는 사유로 다음과 같은 서비스의 장애가 발생하는 경우에는 회원은 각 호와 같은 보상을 요구할 수 있습니다. 단, 장애시간은 회원의 통지시점으로 기산합니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 장애 시간이 연속하여 72시간 또는 1개월 누적시간이 총 72시간을 초과하는 경우에는 회원은 해지를 요구할 수 있으며 그 효과는 본 약관에 따릅니다. 단, 위약금에 관한 규정은 적용하지 않습니다.</span>
                    <span>2. 장애 시간이 연속하여 4시간을 초과하는 경우에는 장애시간의 2배를 연장합니다.</span>
              </span>
            </p>
        </div>
        <!-- //제 18 조 (서비스의 중지 및 공지) -->
        <!-- 제 19 조 (회원의 탈퇴 및 자격상실) -->
        <div class="clause__group" id="list19" ref="list19">
            <h2 class="clause__group--tit">제 19 조 (회원의 탈퇴 및 자격상실)</h2>
            <p class="clause__group--info">
                <span>① 회원이 해지하고자 하는 경우에는 회원 본인이 당 사이트 내에서 언제든지 할 수 있습니다. 단, 회원과 당사 간의 계약내용이 유료인 경우에는 당 사이트 운영자에게 회원이 직접 해지통보를 하여야 합니다.</span><br />
                <span>② 당사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 서비스 이용에 제한을 둘 수 있습니다.</span>
                <span class="clause__group--info indent">
                      <span>1. 타인의 서비스 ID 및 비밀번호를 도용한 경우</span>
                      <span>2. 서비스 운영을 고의로 방해한 경우</span>
                      <span>3. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</span>
                      <span>4. 이용고객이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행 하는 경우</span>
                      <span>5. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</span>
                      <span>6. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송 하는 경우</span>
                      <span>7. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</span>
                      <span>8. 회사, 다른 이용고객 또는 타인의 지적재산권을 침해하는 경우</span>
                      <span>9. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거 관리 위원회의 유권해석을 받은 경우</span>
                      <span>10. 타인의 개인정보, 이용자 ID 및 비밀번호를 부정하게 사용하는 경우</span>
                      <span>11. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통 시키거나 상업적으로 이용하는 경우</span>
                      <span>12. 이용고객이 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우</span>
                      <span>13. 본 약관을 포함하여 기타 회사가 정한 이용조건 및 관계 법령에 위반한 경우</span>
                      <span>14. 월별 ID 당 트래픽( 전송량 )이 300M 를 초과하는 경우( 해당월은 사용할 수 없습니다. )</span>
                </span>
                <span>③ 당사는 다음 각 호에 행위를 하였을 경우 사전통지 후 개선되지 않을시 강제탈퇴 시킬 수 있습니다.</span>
                <span class="clause__group--info indent">
                    <span>1. 기계적인 정보수집 , 매크로 사용 , 정보수집기를 이용하여 정보를 취득하는 경우</span>
                    <span>2. 당사의 어떠한 정보라도 취득하여 상업적인 용도로 사용하는 경우</span>
              </span>
            </p>
        </div>
        <!--//제 19 조 (회원의 탈퇴 및 자격상실)  -->
        <!--제 20 조 (면책) -->
        <div class="clause__group" id="list20" ref="list20">
            <h2 class="clause__group--tit">제 20 조 (면책)</h2>
            <p class="clause__group--info">
                <span>① 회사는 이용고객이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.</span><br />
                <span>② 회사는 이용고객의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</span><br />
                <span>③ 회사는 이용자가 게시 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.</span><br />
                <span>④ 회사는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에는 책임이 면제됩니다</span><br />
                <span>⑤ 회사는 모든 서비스의 자료 보관 및 전송에 관한 책임이 없으며 자료의 손실이 있는 경우 에도 책임이 면제됩니다.</span><br />
                <span>⑥ 회사는 천재지변 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없을 경우에는 서비스 제공 중지에 관한 책임을 면합니다.</span><br />
                <span>⑦ 회사는 회사가 제공하는 서비스망을 통해 제공하는 정보의 신뢰도나 정확성에 대하여는 책임을 면합니다.</span><br />
                <span>⑧ 회사는 입찰공고 누락 건에 대하여 책임을 면합니다.</span><br />
                <span>⑨ 회사는 서비스를 통하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</span>
            </p>
        </div>
        <!-- //제 20 조 (면책)-->
        <!-- 제 21 조 (분쟁의 해결)-->
        <div class="clause__group" id="list21" ref="list21">
            <h2 class="clause__group--tit">제 21 조 (분쟁의 해결)</h2>
            <p class="clause__group--info">
                <span>① 본 약관은 서비스 이용과 관련하여 회사와 이용자간에 발생한 분쟁에 대해서는 수원지방법원 성남 지원을 제1심 관할법원으로 하여 해결한다.</span>
            </p>
        </div>
        <!-- //제 21 조 (분쟁의 해결) -->
        <!-- 부칙 -->
        <div class="clause__group" id="list22" ref="list22">
            <h2 class="clause__group--tit">부칙</h2>
            <h2 class="clause__group--tit">제 1조</h2>
            <p class="clause__group--info">
                <span>① 본 약관은 2023.09.20부터 적용됩니다.</span><br />
                <span>② 기존의 약관은 본 약관으로 대체합니다.</span>
            </p>
        </div>

        <!-- //부칙 -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                siteNm: '비드톡톡',
                siteUrl: 'https://www.bidtok.co.kr',
                anlsFeeGroup: [],
                anlsFeeList: [],
            }
        },
        created() {
            //수수료 가져오기
            this.getAnlsFee();
            window.addEventListener('scroll', this.checkBottom);
        },
        methods: {
            checkBottom () {
                let isBottom = (document.documentElement.scrollTop + window.innerHeight + 200 > document.documentElement.offsetHeight);
                if(isBottom && window.innerWidth <= 800){
                    // eslint-disable-next-line no-undef
                    JavaScriptChannel.postMessage("Y");
                }
            },
            move(message) {
                //페이지 이동
                document.getElementById(message).scrollIntoView({behavior:'smooth'});
                this.$refs[message].scrollIntoView({behavior:'smooth'});
            },
            //수수료 가져오기
            getAnlsFee() {
                this.$get('/api/anlsFee/list')
                    .then(res => {
                        this.anlsFeeList = res.anlsFeeList;
                        this.refactAnlsFeeList();

                    })
                    .catch(error => console.log(error));
            },

            refactAnlsFeeList() {
                this.anlsFeeGroup = [];
                var groupIndex = 0;
                for (var i in this.anlsFeeList) {
                    if (i % 3 == 0 && i != 0) {
                        groupIndex++;
                    }

                    if (!this.anlsFeeGroup[groupIndex]) {
                        this.anlsFeeGroup[groupIndex] = []; // Initialize an array for the category
                    }
                    this.anlsFeeGroup[groupIndex].push(this.anlsFeeList[i]);

                }
            },
        }
    }
</script>
<style src="./EtcUseAgree.scss" lang="scss" scoped />