<template>
  <div class="report rep-ent">
    <!-- 로그인 -->
    <div class="rep-ent__container aft-loin aft-loin__container wrap">

      <!-- 상단 타이틀 부분 -->
      <div class="rep-ent__head aft-loin__head">
        <span class="rep-ent__head--title aft-loin__head--title"></span>
        <p class="rep-ent__head--sub aft-loin__head--sub">
          고객님의 지난 투찰 데이터를 분석, 진단하여<span class="line-break">효과적인 결과가 나올 수 있도록 제안해 드립니다.</span><span class="line-break">비드톡톡의 톡톡리포트를 보고 싶다면, 조회 버튼을 클릭하세요.</span>
        </p>
      </div>
      <!-- /상단 타이틀 부분 -->

      <!-- 조회 버튼 박스  -->
      <div class="rep-ent__box inquiry glass">
        <div class="inquiry--input input">
          <span class="inquiry--input--title">사업자 번호</span>
          <input type="text" v-model="bizNo" ref="bizNoRef">
          <a href="javascript:void(0)" class="inquiry--btn btn-basic btn-line-cb" @click="doSearch()">
            <i class="inquiry--btn--img"></i>조회
          </a>
        </div>
        <p class="inquiry--noti">조회기준일 : <span>{{today}} 기준</span></p>
      </div>
      <!-- /조회 버튼 박스  -->
    </div>
    <!-- /로그인 -->

  </div>
</template>

<script>
    // import * as _ from 'lodash'
    // import $ from 'jquery';

    export default {
        name: "TokReportMain",
        components: {
        },
        data() {
            return {
                bizNo: '',
                loginInfo: null,
                today: this.$moment().format('LL'), //오늘날짜
            }
        },
        mounted() {
            setTimeout(() => {
                this.$refs.bizNoRef.focus();
            }, 0)

            //this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            // if (this.loginInfo != null) {
            //     this.bizNo = this.loginInfo.bizNo;
            // }
        },
        methods: {
            doSearch() {
                if (this.$moment().format('YYYY-MM-DD') >= '2024-06-14' && this.$moment().format('YYYY-MM-DD') <= '2024-06-30') {
                    if (this.bizNo != "") {
                        var encryptedText = this.$CryptoJS.AES.encrypt(this.bizNo, "T@kREportPdf?.?.?").toString();

                        encryptedText = encryptedText.toString().replace(/\+/gi, '.').replace(/\//gi, '_').replace(/=/gi, '-');

                        // this.$goMenu('tokReport');
                        this.$router.push('/trp/'+encryptedText);

                      var tracking = {
                        "trackingCd": "6",
                        "trackingAction" : "CLICK",
                        "trackingNm": "톡톡리포트 조회",
                        "trackingDesc" : this.bizNo,
                      };

                      this.$insertTracking(tracking);

                    } else {
                        this.$toast.error('접근권한이 없습니다.');
                    }
                } else {
                    this.$toast.error('접근권한이 없습니다.');
                }
            },
        }
    }
</script>
<!--<style src="@/assets/scss/common/report_pc.scss" lang="scss" scoped />-->