<template>
    <div class="fee-guide">
        <!-- ai 멤버십 배너 -->
        <a href="javascript:void(0);" class="ai-mbr--banner" @click="scroll()">
            <p class="txt">AI멤버십으로 가볍게 시작하세요!
                <span class="pc">AI멤버십으로 AI분석요청 할 경우 성공보수료 0원!</span>
                <span class="mobile"></span>
            </p>
            <span class="btn btn-cta">AI멤버십 신청하기</span>
        </a>
        <!-- /ai 멤버십 배너 -->

        <!-- 요금 비교 -->
        <div class="fee-guide__area show-fee wrap">
            <ul class="show-fee--slide">
                <li class="show-fee--item basic pc">
                    <p class="show-fee--sub bt-f16 pc">분석가에 따라 낙찰 수수료 부과</p>
                    <p class="show-fee--title bt-f24 bt-ebold">비드톡톡 <span class="empha">수수료형</span></p>
                    <p class="show-fee--sub bt-f17 mobile">분석가에 따라 낙찰 수수료 부과형</p>
                    <ul class="fee-list">
                        <li class="fee-list--item">
                            <p class="fee-list--sub bt-f17"><i class="chk"></i>분석기업 분석 요청</p>
                            <p class="fee-list--tit">
                                <span class="tail bt-f14">낙찰 수수료</span>
                                <span class="bt-f25 bt-ebold">2.0%</span>
                            </p>
                        </li>
                        <li class="fee-list--item">
                            <p class="fee-list--sub bt-f17"><i class="chk"></i>AI 분석가 분석 요청</p>
                            <p class="fee-list--tit">
                                <span class="tail bt-f14">낙찰 수수료</span>
                                <span class="bt-f25 bt-ebold">1.8%</span>
                            </p>
                        </li>
                        <li class="fee-list--item vat"></li>
                    </ul>
                    <div class="vat-info">
                        <p class="vat-info--sub bt-f16">분석가에 따라</p>
                        <p class="vat-info--tit bt-f22">낙찰 수수료 <span class="bt-f28 bt-ebold empha">1.8% ~ 2.0%</span> 발생
                        </p>
                    </div>
                </li>
                <li class="show-fee--item ai">
                    <p class="show-fee--sub bt-f16 pc">AI분석가로 낙찰시 수수료 0원!</p>
                    <p class="show-fee--title bt-f24 bt-ebold">비드톡톡 <span class="empha">AI멤버십형</span></p>
                    <p class="show-fee--sub bt-f17 mobile">AI 분석가에게 분석 요청하면 낙찰 수수료 0원!</p>
                    <ul class="fee-list">
                        <li class="fee-list--item">
                            <p class="fee-list--sub bt-f17"><i class="chk"></i>분석기업 분석 요청</p>
                            <p class="fee-list--tit">
                                <span class="tail bt-f14">낙찰 수수료</span>
                                <span class="bt-f25 bt-ebold">2.0%</span>
                            </p>
                        </li>
                        <li class="fee-list--item empha">
                            <p class="fee-list--sub bt-f17"><i class="chk"></i>AI 분석가 분석 요청</p>
                            <p class="fee-list--tit">
                                <span class="tail bt-f14">낙찰 수수료</span>
                                <span class="bt-f25 bt-ebold empha">0<i class="de-empha">원</i></span>
                            </p>
                        </li>
                        <li class="fee-list--item vat"></li>
                    </ul>
                    <ul class="vat-info">
                        <li  class="vat-info--list">
                            <p class="vat-info--sub bt-f17 bt-ebold">3<span class="bt-f16 bt-regular">개월</span>
                            </p>
                            <p>
                                <span class="vat-info--tit bt-f24 bt-ebold">
                                    <i class="de-empha">₩</i>399,000원
                                </span>
                            </p>

                        </li>
                        <li  class="vat-info--list">
                            <p class="vat-info--sub bt-f17 bt-ebold">6<span class="bt-f16 bt-regular">개월</span>
                            </p>
                            <p>
<!--                                <span class="vat-info&#45;&#45;tail line-th bt-f19">798,000원</span>-->
                                <span class="vat-info--tit bt-f24 bt-ebold">
                                    <i class="de-empha">₩</i>599,000원
                                </span>
                            </p>
                        </li>
                        <li  class="vat-info--list">
                            <p class="vat-info--sub bt-f17 bt-ebold">12<span class="bt-f16 bt-regular">개월</span>
                            </p>
                            <p>
<!--                                <span class="vat-info&#45;&#45;tail line-th bt-f19">1,596,000원</span>-->
                                <span class="vat-info--tit bt-f24 bt-ebold">
                                    <i class="de-empha">₩</i>990,000원
                                </span>
                            </p>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- /요금 비교 -->

        <a class="show-fee--btn wrap bt-f24 bt-bold">
            <span>
                AI멤버십 신청은 여기를 눌러주세요
<!--                상세한 요금은 여기를 눌러주세요-->
                <img src="@/assets/images/main/ic_view-more_service-introduce.png" alt="화살표 모양 아이콘">
            </span>
        </a>
        <!-- 서비스 혜택 -->
        <div class="fee-guide__area benefit">
            <div class="wrap">
                <ul class="benefit__group">
                    <li class="benefit--title bt-f35">서비스 혜택</li>
                    <li class="benefit--item">
                        <div class="benefit--item--chk">
                            <span class="chk"></span>
                            <div class="sqe-bodr-ani">
                                <div class="sqe-bodr-ani--inner"></div>
                            </div>
                        </div>
                        <span class="benefit--item--txt">언제 어디서든지 공고 확인 가능</span>
                    </li>
                    <li class="benefit--item">
                        <div class="benefit--item--chk">
                            <span class="chk"></span>
                            <div class="sqe-bodr-ani">
                                <div class="sqe-bodr-ani--inner"></div>
                            </div>
                        </div>
                        <span class="benefit--item--txt">웹과 모바일 실시간 연동으로 편리한 사용 가능</span>
                    </li>
                    <li class="benefit--item">
                        <div class="benefit--item--chk">
                            <span class="chk"></span>
                            <div class="sqe-bodr-ani">
                                <div class="sqe-bodr-ani--inner"></div>
                            </div>
                        </div>
                        <span class="benefit--item--txt">내 정보에 딱 맞는 맞춤공고 서비스 제공</span>
                    </li>
                    <li class="benefit--item">
                        <div class="benefit--item--chk">
                            <span class="chk"></span>
                            <div class="sqe-bodr-ani">
                                <div class="sqe-bodr-ani--inner"></div>
                            </div>
                        </div>
                        <span class="benefit--item--txt">원하는 공고를 선택해서 분석 요청 가능</span>
                    </li>
                    <li class="benefit--item">
                        <div class="benefit--item--chk">
                            <span class="chk"></span>
                            <div class="sqe-bodr-ani">
                                <div class="sqe-bodr-ani--inner"></div>
                            </div>
                        </div>
                        <span class="benefit--item--txt">내 입찰 성향을 알 수 있는 톡톡리포트 제공</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /서비스 혜택 -->

        <!-- ai 멤버십 배너 -->
        <div class="top-banner ai-promo" ref="aiPromoBanner" id="monthFee">
            <div class="ai-promo--img"></div>
        </div>
        <!-- /ai 멤버십 배너 -->

        <!-- 멤버십 요금 안내 -->
        <div class="fee-guide__area month-fee wrap">
            <h2 class="month-fee--title bt-f35">AI멤버십 요금 안내</h2>
            <ul>
                <li class="month-fee--item">
                    <input type="radio" name="month-fee" id="fee1" v-model="selectedPlan" value="2">
                    <label for="fee1" class="month-fee--radio">
                        <p class="month-fee--top month-fee--title bt-f30 bt-ebold">
                            3<span class="bt-f24 de-empha">개월</span>
                        </p>
                        <ul class="month-fee--money">
                            <li class="before-fee"><span class="line-th bt-f17"></span></li>
                            <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>399,000원</li>
                            <li class="fee-list--item vat"></li>
                        </ul>
                    </label>
                </li>
                <li class="month-fee--item">
                    <input type="radio" name="month-fee" id="fee2" v-model="selectedPlan" value="3">
                    <label for="fee2" class="month-fee--radio">
                        <p class="month-fee--top month-fee--title bt-f30 bt-ebold">
                            6<span class="bt-f24 de-empha">개월</span>
                        </p>
                        <ul class="month-fee--money">
                            <li class="before-fee"><span class="line-th bt-f17">798,000원</span></li>
                            <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>599,000원</li>
                            <li class="fee-list--item vat"></li>
                        </ul>
                    </label>
                </li>
                <li class="month-fee--item">
                    <input type="radio" name="month-fee" id="fee3" v-model="selectedPlan" value="5">
                    <label for="fee3" class="month-fee--radio">
                        <p class="month-fee--top month-fee--title bt-f30 bt-ebold">
                            12<span class="bt-f24 de-empha">개월</span>
                        </p>
                        <ul class="month-fee--money">
                            <li class="before-fee"><span class="line-th bt-f17">1,596,000원</span></li>
                            <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>990,000원</li>
                            <li class="fee-list--item vat"></li>
                        </ul>
                    </label>
                </li>
<!--                <li class="month-fee&#45;&#45;item" @click.prevent="clkPlans('2')" :class="selectedPlan == '2' ? 'active' : ''">-->
<!--                    <p class="month-fee&#45;&#45;top month-fee&#45;&#45;tit bt-f30 bt-ebold">3<span class="bt-f24 de-empha">개월</span>-->
<!--                    </p>-->
<!--                    <ul class="month-fee&#45;&#45;money">-->
<!--                        <li class="before-fee bt-f24">-->
<!--                        </li>-->
<!--                        <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>399,000원</li>-->
<!--                        <li class="amonth-fee bt-f19">부가세 포함</li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li class="month-fee&#45;&#45;item" @click.prevent="clkPlans('3')" :class="selectedPlan == '3' ? 'active' : ''">-->
<!--                    <p class="month-fee&#45;&#45;top month-fee&#45;&#45;tit bt-f30 bt-ebold">6<span class="bt-f24 de-empha">개월</span>-->
<!--                    </p>-->
<!--                    <ul class="month-fee&#45;&#45;money">-->
<!--                        <li class="before-fee">-->
<!--                            <span class="line-th bt-f17">798,000원</span>-->
<!--                        </li>-->
<!--                        <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>599,000원</li>-->
<!--                        <li class="amonth-fee bt-f19">부가세 포함</li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li class="month-fee&#45;&#45;item" @click.prevent="clkPlans('5')" :class="selectedPlan == '5' ? 'active' : ''">-->
<!--                    <p class="month-fee&#45;&#45;top month-fee&#45;&#45;tit bt-f30 bt-ebold">12<span class="bt-f24 de-empha">개월</span>-->
<!--                    </p>-->
<!--                    <ul class="month-fee&#45;&#45;money">-->
<!--                        <li class="before-fee">-->
<!--                            <span class="line-th bt-f17">1,596,000원</span>-->
<!--                        </li>-->
<!--                        <li class="present-fee bt-f32 bt-ebold"><span class="de-empha">&#8361;</span>990,000원</li>-->
<!--                        <li class="amonth-fee bt-f19">부가세 포함</li>-->
<!--                    </ul>-->
<!--                </li>-->
            </ul>
            <a href="javascript:void(0);" class="month-fee&#45;&#45;btn btn-basic btn-cta bt-f25 bt-bold" @click="isPopNotice = true;">신청하기</a>
<!--            <a href="javascript:void(0);" class="month-fee&#45;&#45;btn btn-basic btn-cta bt-f25 bt-bold" @click="$parent.chkCustAISubComn(selectedPlan)" v-if="Object.keys($parent.custCntrct).length == 0">신청하기</a>-->
<!--            <a href="javascript:void(0);" class="month-fee&#45;&#45;btn btn-basic btn-cta bt-f25 bt-bold" @click="$parent.chkCustAISubComn(selectedPlan)" v-if="Object.keys($parent.custCntrct).length != 0 && $parent.loginInfo.subStatusCd == null">신청하기</a>-->
<!--            <a href="javascript:void(0);" class="month-fee&#45;&#45;btn btn-basic btn-cta bt-f25 bt-bold" @click="$parent.chkCustAISubComn(selectedPlan)" v-if="Object.keys($parent.custCntrct).length != 0 && $parent.loginInfo.subStatusCd == '0'">결제하기</a>-->
        </div>
        <!-- /멤버십 요금 안내 -->

        <!-- 안내사항 -->
        <div class="fee-guide__area precaution">
            <div class="wrap">
                <h2 class="precaution--title bt-f25">AI멤버십 안내사항</h2>
                <p class="precaution--cont">
                    AI멤버십이란 AI분석가에 한해서만 성공보수료 0원이 적용되며  분석기업의 경우 기본 성공보수료(낙찰가의 2.0%/부가세포함)가 적용됩니다.<br>
                    AI멤버십 이용 기간 1개월이라 함은 30일을 말합니다.<br>
                    AI멤버십 계약 기간의 시작은 <span class="empha unline">입금 확인 후부터 계약이 시작되어</span> 사용하실 수 있습니다.<br>
                    AI분석요청 및 분석기업 분석요청은 회원가입 당시 등록된 지역, 면허 기준으로만 분석요청 가능합니다.<br>
                    AI멤버십 계약 기간이 종료된 이후의 AI분석 요청건은 기본 수수료 (낙찰가의 1.8%/부가세포함) 적용됩니다.<br>
                    AI멤버십 이용기간동안 분석기업(진비드, 인비드, 비드인, 청비드, 솔비드, 비드플러스)로 분석 요청 후 낙찰한 경우
                    기본 수수료 (낙찰가의 2%/부가세포함) 적용됩니다.<br>
                    이하 분석기업에 분석 요청 서비스에 대한 내용은 [전자입찰 분석정보 제공계약서]와 동일합니다.<br>
                    AI멤버십 환불 규정은 [AI멤버십 서비스 계약서]에서 확인 가능합니다.<br>
                    본 계약에 따라 서비스 이용 금액은 자동 결제되지 않으며, 고객은 수동으로 결제해야합니다.<br>
                    비드톡톡은 모든 거래에 대한 책임과 취소, 환불, 민원등의 처리는 비드톡톡에서 진행합니다.<br>
                    민원 담당자: 이은샘 / 연락처: 1670-0508
                </p>
            </div>
        </div>

        <!-- AI멤버십 선택 팝업 -->
        <div class="popup__wrap off-auto-anal" :class="isPopNotice ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                        <h3 class="txt__title">AI멤버십 이용 동의</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">AI멤버십 신청은<br/>비드톡톡 홈페이지에서<br/>로그인 후 신청 가능합니다.<br/><br/>불편을 드려 죄송합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="isPopNotice = false;">닫기</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /AI멤버십 선택 팝업 -->

    </div>
</template>

<script>
    import $ from 'jquery';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';

    export default {
        name: "TokStory",
        metaInfo: {
            title: '[비드톡톡 AI멤버십] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                boardList : [],
                currentPage: 1,
                itemsPerPage: 10,
                loginInfo: {},
                selectedPlan: 0,
                isScroll: 0,
                isPopNotice: false
            }
        },

        watch: {
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.boardList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {
            // 결제팝업창
            payPopup( uri, width, height ) {
                if( this.windowRef != null ){
                    this.closeWinPop();
                }

                let left = (screen.width) ? (screen.width - width) / 2 : 0;
                let top = (screen.height) ? (screen.height - height) / 2 : 0;

                let attr = 'top=' + top + ', left=' + left  + ', width=' + width + ', height=' + height + ', resizable=no,status=no';

                // 1. 윈도우 팝업 띄우기
                this.windowRef = window.open(uri, "", attr);
                if( this.windowRef != null ) {
                    this.windowRef.addEventListener('beforeunload', this.evtClose);
                }else{
                    alert( "window.open fail!!!" );
                }

                // 2.  새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
                window.addEventListener("message", this.recvEvtFromChild, false);
            },
            // 요금제 선택
            clkPlans(planId) {
                this.selectedPlan = planId;
            },
            //AI신청배너로 스크롤 이동
            scroll() {
              this.$refs.aiPromoBanner.scrollIntoView({behavior: "smooth"});
            },
            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },
            async activeScrollEvent(){
                if(!this.active){

                    this.active = true;

                    // /* 구독형 스크롤 */
                    const subscriptionScroll = document.getElementById('subscriptionScroll');

                    subscriptionScroll.addEventListener('scroll', function(){
                        let popScrollHeight = subscriptionScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = subscriptionScroll.scrollTop; // 스크롤 현재 높이
                        if (subscriptionScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const subscriptionBtn = document.getElementById('subscriptionBtn');
                            // subscriptionBtn.disabled = false;
                            subscriptionBtn.classList.remove('btn__non-active');
                            subscriptionBtn.classList.add('btn__active');
                        }
                    });
                }
            },
            initializeSlick() {

                if ($(window).width() <= 800) {
                    if (!$('.show-fee--slide').hasClass('slick-initialized')) {
                        $('.show-fee--slide').slick({

                            dots: true,
                            arrows: false,
                            infinite: false,
                            slidesToShow: 1,
                            initialSlide: 2, //시작 슬라이드 지정
                            dotsClass: 'show-fee--dots',
                            customPaging: function (slider, i, ment) {
                                //var thumb = $(slider.$slides[i]).data();
                                if (i == '0') {
                                    ment = '<p>수수료형' + '</p>';

                                } else if (i == '1') {
                                    ment = '<p>AI멤버십형' + '</p>';

                                }
                                return ment;
                            },
                        });
                        $('.show-fee--slide li:nth-child(2)').addClass('slick-active');
                    }
                } else {
                    if ($('.show-fee--slide').hasClass('slick-initialized')) {
                        $('.show-fee--slide').slick('unslick');
                    }
                }

                window.addEventListener('resize', this.initializeSlick);
            }
        },
        created() {

        },
        mounted() {

            this.isScroll = this.$route.params.s;
            if (this.isScroll == 1){
                this.scroll();
            }

            // 회원정보 갱신
            // var self = this;
            // this.$parent.getCustInfoComn(function() {
            //     self.loginInfo = self.$parent.loginInfo;
            //
            //     self.$parent.getCustCntrctListComn(function(oList) {
            //         oList;
            //     });
            // });

            // 서비스 혜택 애니메이션
            const benefitItems = document.querySelectorAll('.benefit--item');
            let index = 0;

            function addActiveClass() {
                benefitItems[index].classList.add('active');
                index++;

                if (index >= benefitItems.length) {

                    setTimeout(() => {
                        benefitItems.forEach(item => item.classList.remove('active'));
                        index = 0;
                        startAddingActive();
                    }, 4000);
                } else {
                    setTimeout(addActiveClass, 2500);
                }
            }

            function startAddingActive() {
                setTimeout(addActiveClass, 1000);
            }
            startAddingActive();
            // /서비스 혜택 애니메이션

            let goFee = document.querySelector(".show-fee--btn");
            let goFee2 = document.querySelector(".ai-mbr--banner");

            if (goFee || goFee2) {
                goFee.addEventListener("click", function () {
                    var targetElement = document.getElementById("monthFee");
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                });
                goFee2.addEventListener("click", function () {
                    var targetElement = document.getElementById("monthFee");
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            }

            // 초기 슬라이드 설정
            this.initializeSlick();
        }
    }

</script>
