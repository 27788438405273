<template>
    <!-- 경영/면허 정보 수정 팝업-->
    <div class="popup__wrap recom-announce__pop--wrap edit-pop__wrap recom-announce" :class="isPopBefLicnsPop  ? 'active' : ''">
        <div class="popup__item--container recom-announce__pop--container">
            <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                <div class="popup__item--head">
                    <p class="txt__title">경영/면허 정보 수정</p>
                </div>
                <div class="popup__item--body recom-announce__pop--body set-pop__wrap">
                    <div class="fist-set--help"><p class=txt--title>2022년 경영상태 정보를 입력해주세요. 2022년 적용시 최대 참여 가능한 금액을 계산해드릴게요!</p></div>
                    <div class="edit-pop__unit manage tooltip">
                        <div class="tooltip--manage">
                            <h3 class="h3">① 경영 정보상태</h3>
                            <!--              <div class="tooltip__group">-->
                            <!--                <span class="tooltip__box">?</span>-->
                            <!--                <div class="tooltip&#45;&#45;txt">{{tooltipTxt1}}</div>-->
                            <!--              </div>-->
                        </div>
                        <h4 class="h4">부채비율</h4>
                        <div class="input edit-pop--input manage--input">
                            <input id="debtRate" type="text" placeholder="부채비율을 입력해 주세요." v-model="debtRateComputed">
                        </div>
                        <h4 class="h4">유동비율</h4>
                        <div class="input edit-pop--input manage--input currnt-ratio">
                            <input id="crasRate" type="text" placeholder="유동비율을 입력해 주세요." v-model="crasRateComputed">
                        </div>
                        <div class="tooltip--credit">
                            <h4 class="h4">신용 평가</h4>
                            <div class="tooltip__group">
                                <span class="tooltip__box">?</span>
                                <div class="tooltip--txt">공공기관 입찰용으로 발급받은 신용평가등급확인서로만 사용할 수 있습니다.</div>
                            </div>
                        </div>
                        <div class="sel-box edit-pop--select">
                            <select name="grade" v-model="selGrd">
                                <option value="none" disabled>등급을 선택해 주세요.</option>
                                <option value="">해당사항 없음</option>
                                <option v-for="cdlt in cdltStList" v-bind:key="cdlt.id" :value="cdlt.id">
                                    <li>{{cdlt.cdltGrd}}</li>
                                </option>
                            </select>
                        </div>
                        <div class="sel-box edit-pop--select" :class="selGrd == '' ? 'disable' : ''">
                            <select name="agency" v-model="selOrgnz" :disabled="selGrd == '' ? true : false">
                                <option value="" disabled>발급기관을 선택해 주세요.</option>
                                <option v-for="orgnz in cdltStOrgList" v-bind:key="orgnz.id" :value="orgnz.comnCdNm">{{orgnz.comnCdNm}}</option>
                            </select>
                        </div>
                        <v-date-picker v-model="cdltStDe">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="!selGrd == ''"
                                     @click="togglePopover">
                                    <input
                                            class="input edit-pop--input manage--input"
                                            placeholder="만료일을 선택해 주세요."
                                            :value="inputValue"
                                    />
                                </div>
                                <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="selGrd == ''">
                                    <input type="text" class="disable" placeholder="만료일을 선택해주세요.">
                                </div>
                            </template>
                        </v-date-picker>
                    </div>
                    <div class="edit-pop__unit license">
                        <h3 class="h3">② 면허 정보<span class="txt--sub"> *면허별로 정보를 입력해주세요.</span></h3>
                        <div class="tok-slide__wrap user-info__slide">
                            <div class="tok-slide__container">
                                <ul class="tok-slide--list user-info__slide--list">
                                    <li class="tok-slide__item user-info__slide--item" :class="modifySelectLicns == licns ? 'active' : '' " v-for="licns in modifyCustLicnsList" v-bind:key="licns.id">
                                        <a href="javascript:void(0)" @click="clickLicns(licns)">{{licns.licnsNm}}</a>
                                    </li>
                                </ul>
                            </div>
                            <ul class="tok-slide__arrow">
                                <li class="tok-slide__arrow--item pre user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="previos">previos</li>
                                <li class="tok-slide__arrow--item next user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="next">next</li>
                            </ul>
                        </div>
                        <div class="txt--title txt--sel-license">
                            <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd == null && modifySelectLicns.licnsNm != null && modifySelectLicns.licnsNm.indexOf('(대)') != -1">
                                <!-- 주력 main / 대업종 big-->
                                <i class="bdg-lic--ic big">대업종</i>
                            </div>
                            <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd != null">
                                <!-- 주력 main / 대업종 big-->
                                <i class="bdg-lic--ic main" >주력</i>
                            </div>
                            <span>{{modifySelectLicns.licnsNm}}</span>
                        </div>
                        <div class="tooltip--3year">
                            <h4 class="h4">3년 실적</h4>
                            <!--              <div class="tooltip__group">-->
                            <!--                <span class="tooltip__box">?</span>-->
                            <!--                <div class="tooltip&#45;&#45;txt">{{tooltipTxt2}}</div>-->
                            <!--              </div>-->
                        </div>
                        <div class="input edit-pop--input license--input">
                            <input id="y3AcrsltAmt" type="text" placeholder="3년 실적을 입력해 주세요." v-model="y3AcrsltAmtComputed">
                        </div>
                        <div class="tooltip--5year">
                            <h4 class="h4">5년 실적</h4>
                            <!--              <div class="tooltip__group">-->
                            <!--                <span class="tooltip__box">?</span>-->
                            <!--                <div class="tooltip&#45;&#45;txt">{{tooltipTxt3}}</div>-->
                            <!--              </div>-->
                        </div>
                        <div class="input edit-pop--input license--input">
                            <input id="y5AcrsltAmt" type="text" placeholder="5년 실적을 입력해 주세요." v-model="y5AcrsltAmtComputed">
                        </div>
                        <div class="tooltip--perform">
                            <h4 class="h4">추가 실적</h4>
                            <div class="tooltip__group">
                                <span class="tooltip__box">?</span>
                                <div class="tooltip--txt">협회에 등록되지는 않았지만 최종 준공이 완료된 공사 실적 합계액입니다.</div>
                            </div>
                        </div>
                        <div class="input edit-pop--input license--input">
                            <input id="lcettSuplmtAcrsltAmt" type="text" placeholder="추가 실적을 입력해 주세요." v-model="lcettSuplmtAcrsltAmtComputed">
                        </div>
                        <div class="tooltip--capa">
                            <h4 class="h4">시공능력평가액</h4>
                            <!--              <div class="tooltip__group">-->
                            <!--                <span class="tooltip__box">?</span>-->
                            <!--                <div class="tooltip&#45;&#45;txt">{{tooltipTxt4}}</div>-->
                            <!--              </div>-->
                        </div>
                        <div class="input edit-pop--input license--input">
                            <input id="cswkCapaEvltnAmt" type="text" placeholder="시공능력평가액을 입력해 주세요." v-model="cswkCapaEvltnAmtComputed">
                        </div>
                        <div class="tooltip--dura">
                            <h4 class="h4">영업기간</h4>
                            <!--              <div class="tooltip__group">-->
                            <!--                <span class="tooltip__box">?</span>-->
                            <!--                <div class="tooltip&#45;&#45;txt">{{tooltipTxt5}}</div>-->
                            <!--              </div>-->
                        </div>
                        <v-date-picker v-model="bsnRegstDe">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="input edit-pop--input manage--input calendar-btn expi-date"
                                     @click="togglePopover">
                                    <input
                                            class="input edit-pop--input manage--input"
                                            placeholder="영업기간을 선택해 주세요."
                                            :value="inputValue"
                                    />
                                </div>
                            </template>
                        </v-date-picker>
                    </div>
                </div>
            </div>
            <div class="popup__button--wrap pair">
                <a href="javascript:void(0)" class="btn-basic btn-line-cb  popup__close" @click="isPopBefLicnsPop  = false;">취소</a>
                <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="checkLastLicns()">{{this.modifySelectLicns == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1] ? '완료': '다음'}}</a>
            </div>
        </div>
    </div>
    <!-- /경영/면허 정보 수정 팝업 -->
</template>
<script>
    import * as _ from 'lodash'

    export default {
        data() {
            return {
                loginInfo: {},
                custLicnsList: [],     //고객 면허 목록
                cdltStList: [], //신용평가 목록
                cdltStOrgList: [], //발급기관 목록
                modifyCustLicnsList: [],     //수정 고객 면허 목록

                debtRate: '',   //부채비율
                crasRate: '',   //유동비율
                y3AcrsltAmt: '',    //3년실적
                y5AcrsltAmt: '',    //5년 실적
                lcettSuplmtAcrsltAmt: '',   //추가실적
                cswkCapaEvltnAmt: '',   //시공능력평가금액
                cdltStDe: '',   //신용평가 만료일
                bsnRegstDe: '',     //영업기간


                selGrd: '', //선택등급
                selOrgnz: '', // 선택 발급 기관

                orgnzText: '발급기관을 선택해 주세요.', // 선택 발급 기관
                cdltText: '등급을 선택해 주세요.',   //선택 등급

                selectLicns: {},  //선택 라이센스
                modifySelectLicns: {},  //선택 라이센스

                isPopBefLicnsPop: false,
            }
        },
        computed: {
            debtRateComputed: {
                get() {
                    try {
                        return this.debtRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } catch {

                        return '';
                    }
                },
                set(value) {
                    if (value == '') {
                        this.debtRate = '';
                    } else {
                        this.debtRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            crasRateComputed: {
                get() {
                    try {
                        return this.crasRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } catch {
                        return '';
                    }
                },
                set(value) {
                    if (value == '') {
                        this.crasRate = '';
                    } else {
                        this.crasRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y3AcrsltAmtComputed: {
                get() {

                    return this.y3AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.y3AcrsltAmt = 0;
                    } else {
                        this.y3AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y5AcrsltAmtComputed: {
                get() {
                    return this.y5AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.y5AcrsltAmt = 0;
                    } else {
                        this.y5AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            cswkCapaEvltnAmtComputed: {
                get() {
                    return this.cswkCapaEvltnAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.cswkCapaEvltnAmt = 0;
                    } else {
                        this.cswkCapaEvltnAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            lcettSuplmtAcrsltAmtComputed: {
                get() {
                    return this.lcettSuplmtAcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.lcettSuplmtAcrsltAmt = 0;
                    } else {
                        this.lcettSuplmtAcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
        },
        watch: {
            selGrd(newValue) {
                if (newValue == '') {
                    this.selOrgnz = '';
                    this.cdltStDe = '';
                }
            },
            isPopBefLicnsPop(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
        },
        methods: {
            //마지막 면허가 아니면 다음 면허로 이동
            checkLastLicns() {

                if(this.modifySelectLicns.id == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1].id) {
                    this.insertCustLicns();
                } else {
                    var index = this.modifyCustLicnsList.findIndex((element) => element.id == this.modifySelectLicns.id);

                    this.licnsChange(this.modifyCustLicnsList[index+1]);
                }
            },
            //면허 저장
            async insertCustLicns() {
                if (!await this.validateCustBiz()) {
                    return;
                }

                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe) != '' ? this.dateToString(this.bsnRegstDe) : '';
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay, 'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt == '' ? 0 : this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt == '' ? 0 : this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt = this.cswkCapaEvltnAmt == '' ? 0 : this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt == '' ? 0 : this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;
                this.modifyCustLicnsList[index].crasRate = this.crasRate;
                this.modifyCustLicnsList[index].debtRate = this.debtRate;
                this.modifyCustLicnsList[index].cdltStId = this.selGrd;

                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "list": this.modifyCustLicnsList
                };

                await this.$post('/api/cust/saveBefCustLicns', param)
                    .then(res => {
                        if (res.code == 'OK') {
                            this.$toast.success('내 경영/면허정보가 수정되었습니다. ');
                            this.isPopBefLicnsPop = false;
                            this.$parent.reloadCustInfo();
                        } else {
                            this.$toast.error('내 경영/면허정보 업데이트에 실패했습니다.');
                            this.isPopBefLicnsPop = false;
                        }

                    })
                    .catch(error => {
                            console.log(error);
                            this.isPopBefLicnsPop = false;
                        }
                    );

            },
            validateCustBiz() {
                if (this.selGrd != '') {
                    if (this.selOrgnz == '' || this.cdltStDe == '' || this.dateToString(this.cdltStDe) == '') {
                        this.$toast.error('신용평가 항목을 전부 선택해 주세요.');
                        return false;
                    }
                } else if (this.debtRate == null || this.debtRate.toString() == '') {
                    this.$toast.error('부채비율을 입력해 주세요.');
                    return false;
                } else if (this.crasRate == null || this.crasRate.toString() == '') {
                    this.$toast.error('유동비율을 입력해 주세요.');
                    return false;
                }
                return true;
            },
            clickLicns(licns) {

                this.licnsChange(licns);
            },
            previos() {
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.currentPosition < 40) {
                    this.currentPosition += 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count += 1;
                }
            },
            licnsChange(licns) {
                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe);
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay, 'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt == '' ? 0 : this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt == '' ? 0 : this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt = this.cswkCapaEvltnAmt == '' ? 0 : this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt == '' ? 0 : this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;

                this.modifySelectLicns = licns;
                this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                this.bsnRegstDe = this.modifySelectLicns.bsnRegstDe;
            },
            dateToString(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if (value == '') return '';
                if (value == 'NaN-NaN-NaN') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                if (year == '1970' && month == '01' && day == '01') {
                    return '';
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            },
            next() {
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.count > 0) {
                    this.currentPosition -= 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count -= 1;
                } else {
                    return;
                }

            },
            //신용평가 목록
            getCdltSdList() {
                this.cdltStList = [];

                this.$http.get('/api/comnCode/cdltSt')
                    .then(res => {
                        this.cdltStList = res.data.cdltStList;
                        this.cdltStList = this.cdltStList.filter((e) => e.cdltStCd == '0');

                    })
                    .catch(error => console.log(error));
            },
            //발급기관 목록
            getCdltStOrgnz() {
                this.cdltStOrgList = [];

                var param = {"comnCdGroupId": "CDLT_ST_ISS_ORGNZ"};

                this.$http.post('/api/comnCode/list', param)
                    .then(res => {
                        this.cdltStOrgList = res.data.comnCodeList;
                    })
                    .catch(error => console.log(error));
            },
            //내 면허 정보
            async getCustLicnsList() {


                await this.$get('/api/custBiz/' + this.loginInfo.custBizList[0].id + '/custLicns')
                    .then(res => {
                        if (res.code == 'OK') {
                            this.custLicnsList = res.data;
                            this.modifyCustLicnsList = _.cloneDeep(res.data);

                            for (var index in this.modifyCustLicnsList) {
                                this.modifyCustLicnsList[index].y3AcrsltAmt = 0;
                                this.modifyCustLicnsList[index].y5AcrsltAmt = 0;
                                this.modifyCustLicnsList[index].cswkCapaEvltnAmt = 0;
                                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = 0;
                                this.modifyCustLicnsList[index].bsnPerdCd = '';
                                this.modifyCustLicnsList[index].bsnRegstDe = '';
                                this.modifyCustLicnsList[index].bsnPerdDeText = '';
                            }
                            this.selectLicns = this.custLicnsList[0];
                            this.$moment(this.strtDate).format('YYYY-MM-DD');

                            if (this.modifyCustLicnsList[0] != undefined) {
                                this.modifySelectLicns = this.modifyCustLicnsList[0];
                            }

                            this.bsnRegstDe = this.custLicnsList[0].bsnRegstDe;
                            this.count = this.custLicnsList.length - 1;
                            this.currentPosition = 40;

                            this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                            this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                            this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                            this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                            this.bsnRegstDe = this.modifySelectLicns.bsnRegstDe;
                        }

                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
        },
        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            this.getCdltSdList();

            this.getCdltStOrgnz();

            if(this.loginInfo != null) {
                this.getCustLicnsList();
            }
        }
    };
</script>