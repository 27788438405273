<template>
    <div class="info-center">
<!--        <div v-for="bList in blogList" :key="bList.id" @click="goBlogDetail(bList.id)">-->
<!--            {{bList.boardTitle}} //  {{bList.boardContent}} // {{bList.firstInputDt}}-->
<!--            &lt;!&ndash;            <img :src="bList.filePathNm+bList.serverFileNm">&ndash;&gt;-->
<!--            <img :src="$parent.BASE_URL+`${bList.filePathNm}`+`${bList.serverFileNm}`">-->
<!--        </div>        -->
        <div class="info-center--top">
            <h1 class="h1 bt-f30">전자입찰과 관련된 소식을 비드톡톡이 생생하게 전해드려요</h1>
            <h2 class="h2 bt-f22">#전자입찰 #AI분석 #맞춤공고 #나라장터 #조달청</h2>
        </div>
        <div class="info-center--bottom wrap analysis">
            <div class="bt--filters bt--filter--rp info-center--filters">
                <a href="javascript:void(0);" class="bt--filters__item active" @click="$router.push('/tokBlog')">톡블로그</a>
                <a href="javascript:void(0);" class="bt--filters__item" @click="$router.push('/tokG2BNotice')">발주처 공지사항</a>
                <a href="javascript:void(0);" class="bt--filters__item" @click="$router.push('/tokG2BCfmtUndwrt')">적격심사</a>
            </div>
            <div class="search-banner info-center--srch-bnr">
                <div class="search-banner__box">
                    <p class="inquiry__total txt__title analysis__total">총<span>{{blogList.length}}</span>건</p>
                </div>
            </div>

            <!-- 블로그 -->
            <ul class="blog blog__list">
                <li class="bt--item-group bt--flex-sb blog__list--item" v-for="bList in blogList" :key="bList.id">
                    <div class="blog--cnts">
                        <h1 class="bt-bold h1" @click="goBlogDetail(bList.id)">{{bList.boardTitle}}</h1>
                        <h3 class="h3" @click="goBlogDetail(bList.id)">{{bList.boardContent}}</h3>
                        <p class="txt">{{bList.firstInputDt}}</p>
                    </div>
                    <img :src="$parent.BASE_URL+`${bList.filePathNm}`+`${bList.serverFileNm}`" :alt="`${bList.boardTitle}`" class="blog--thum" @click="goBlogDetail(bList.id)">
                </li>
            </ul>
            <!-- /블로그 -->

            <!-- 발주처 공지사항 -->
<!--            <div class="bt&#45;&#45;grid bt&#45;&#45;grid&#45;&#45;grey client-anno client-anno&#45;&#45;grid">-->
<!--                <ul class="bt&#45;&#45;grid-colgroup">-->
<!--                    <li class="col-1"></li>-->
<!--                    <li class="col-2"></li>-->
<!--                    <li class="col-3"></li>-->
<!--                    <li class="col-4"></li>-->
<!--                    <li class="col-5"></li>-->
<!--                </ul>-->
<!--                <div class="bt&#45;&#45;grid-ghead">-->
<!--                    <ul class="gr">-->
<!--                        <li class="gh">-->
<!--                            <p>번호</p>-->
<!--                        </li>-->
<!--                        <li class="gh">-->
<!--                            <p>분류</p>-->
<!--                        </li>-->
<!--                        <li class="gh">-->
<!--                            <p>제목</p>-->
<!--                        </li>-->
<!--                        <li class="gh">-->
<!--                            <p>첨부파일</p>-->
<!--                        </li>-->
<!--                        <li class="gh">-->
<!--                            <p>날짜</p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="bt&#45;&#45;grid-gbody">-->
<!--                    <ul class="gr">-->
<!--                        <li class="gd">1</li>-->
<!--                        <li class="gd">조달청</li>-->
<!--                        <li class="gd">[철도시설공단][영동선 유곡천제1,2교 외 2건 개량 기타공사 감독 권한대행 등 건설사업 동해물과 백두산이 마르고 닳도록 하느님이 보우하사-->
<!--                        </li>-->
<!--                        <li class="gd">-->
<!--                            <i class="ic_hwp"></i>-->
<!--                            <i class="ic_pdf"></i>-->
<!--                            <i class="ic_zip"></i>-->
<!--                        </li>-->
<!--                        <li class="gd">2024-08-29</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
            <!-- /발주처 공지사항 -->

        </div>
    </div>
</template>

<script>
 //   import * as _ from 'lodash';

    export default {
        name: "TokBlog",
        metaInfo: {
            title: '[비드톡톡 톡블로그] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/tokBlog' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡의 블로그를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 블로그] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡의 블로그를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                blogList : [],
                currentPage: 1,
                itemsPerPage: 10,
            }
        },

        watch: {
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.blogList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {
            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },

            fnSearchBoard(){

                var params = {
                    pageUnit: 9999,
                    pageIndex: this.currentPage,
                    strtRecordIntex: (this.currentPage - 1) * this.itemsPerPage,
                    boardCd: 3, //블로그
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.blogList = res.data.boardList;

                        this.blogList.forEach(e => {
                            e.boardContent = e.boardContent.replace(/<[^>]*>?/g, '').replaceAll('&nbsp;', '').replace('&lt;','<').replace('&gt;','>').replace('&quot;','"').replace('&quot;','"'); // html 제거
                            e.firstInputDt = this.$moment(e.firstInputDt).format('YYYY-MM-DD');
                        });
                        //
                        // for (var i=0 ; i<this.blogList.length ; i++) {
                        //
                        // }

                        console.log(this.blogList);
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            goBlogDetail(id) {
                this.$router.push('/tokBlogDetail/'+id);
            }
        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });

            const headerNoticeElement = document.getElementById("tokStory");
            if (headerNoticeElement) {
                headerNoticeElement.classList.add("active");
            }
        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->