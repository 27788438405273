<template>
    <div>
        <div class="howto">
            <div class="howto--content wrap">
                <p class="howto--badge">사용가이드</p>
                <p class="txt--title">비드톡톡의 <span class="empha">생생한 사용법</span>을<br>알려드릴게요!</p>
                <p class="txt--sub">비드톡톡에서 제공하는 서비스에 대한 가이드를 소개하며,<br>영상을 통해 앱 사용하는 방법을 실시간으로 확인하실 수 있습니다.</p>
                <div class="howto__group">
                    <a href="javascript:void(0)" class="howto--btn pdf btn-basic btn-line-cw" @click="$goMenu('howtoDetail#0')">
                        <i class="ic"></i> 서비스 가이드 <i class="arrow"></i>
                    </a>
                    <a href="javascript:void(0)" class="howto--btn youtube btn-basic btn-line-cw" @click="$goMenu('howtoDetail#1')">
                        <i class="ic"></i> 앱 사용가이드 영상 <i class="arrow"></i>
                    </a>
                </div>
            </div>
            <div class="howto--bg">
                <img src="@/assets/images/tokstroy/bg_howto_pc.png" alt="사용법 바로 배너">
            </div>
        </div>
    <div class="tokstory wrap">
        <div class="layout__half--wrap item-align-right cd-lyot-stnd">
            <div class="layout__half--container vertical--top layout__half--small">
                <a href="javascript:void(0)" class="layout__item blog" @click="$goMenu('tokBlog')">
                    <p class="menu-badge info"></p>
                    <div class="btn__view-more"></div>
                    <div class="layout__item--bg-img"></div>
                </a>
                <a href="javascript:void(0)" class="layout__item hotissue" @click="$goMenu('tokStoryView/43')">
                    <p class="menu-badge promo"></p>
                    <div class="btn__view-more"></div>
                    <h3 class="txt--title">서울경제 TV SEN <span class="line-break">조영구의 트랜드 핫 이슈 출연!</span></h3>
                    <div class="layout__item--bg-img"></div>
                </a>
<!--                <a href="javascript:void(0)" class="layout__item" @click="$goMenu('tokStoryView/43')">-->
<!--                    <p class="menu-badge app"></p>-->
<!--                    <div class="btn__view-more"></div>-->
<!--                    <div class="layout__item&#45;&#45;headline">-->
<!--                        <h3 class="txt&#45;&#45;title">'<span>비드톡톡</span>' 서울경제 TV SEN<br>조영구의 트랜드 핫 이슈 출연</h3>-->
<!--                        <ul class="hashtag">-->
<!--                            <li class="hashtag&#45;&#45;list">#모바일 전자입찰 플랫폼</li>-->
<!--                            <li class="hashtag&#45;&#45;list">#AI분석가</li>-->
<!--                            <li class="hashtag&#45;&#45;list">#분석기업</li>-->
<!--                            <li class="hashtag&#45;&#45;list">#나라장터</li>-->
<!--                            <li class="hashtag&#45;&#45;list">#조달청</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                    <div class="layout__item&#45;&#45;cover layout__item&#45;&#45;bg-img"></div>-->
<!--                </a>-->
            </div>
            <div class="layout__half--container vertical--top layout__half--small">
                <a href="javascript:void(0)" class="layout__item company-intduce" @click="$goMenu('tokStoryView/44')">
                    <p class="menu-badge company"></p>
                    <div class="btn__view-more"></div>
                    <h3 class="txt--title">비드톡톡 회사를 소개합니다!</h3>
                    <p class="txt--sub">개방적인 구조와 넓은 공간을 갖춘 비드톡톡, 그 안에서 창의적인 아이디어를 자유롭게 표현하며 더 나은 입찰 플랫폼을 만들고 있습니다.</p>
                    <div class="layout__item--bg-img"></div>
                </a>
                <a href="javascript:void(0)" class="layout__item ai-intduce" @click="$goMenu('tokStoryView/45')">
                    <p class="menu-badge app"></p>
                    <div class="btn__view-more"></div>
                    <h3 class="txt--title">AI 자동분석 삼총사!</h3>
                    <p class="txt--sub">자랑스러운 AI 분석가 도그, 펭, 수리를 소개합니다. 24시간 분석 요청이 가능한 AI 자동 분석 삼총사를 만나보세요.</p>
                    <div class="layout__item--bg-img"></div>
                </a>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "TokStory",
        metaInfo: {
            title: '[비드톡톡 톡스토리] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                boardList : [],
                currentPage: 1,
                itemsPerPage: 10,
            }
        },

        watch: {
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.boardList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {
            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },

            fnSearchBoard(){

                var params = {
                    pageUnit: 9999,
                    pageIndex: this.currentPage,
                    strtRecordIntex: (this.currentPage - 1) * this.itemsPerPage,
                    boardCd: 2,
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.boardList = res.data.boardList;
                    })
                    .catch(error => {console.log(error);}
                    );

            },

        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });
        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->