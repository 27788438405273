<template>
    <div class="bid-helper help-login">
        <div class="help-login__wrap">
            <h1 class="logo"></h1>
            <div class="login__tabcont--group help-login__group">
                <!-- 인풋박스 -->
                <div class="help-login__unit">
                    <div class="input login__input">
                        <input type="text" placeholder="사업자번호를 입력하세요" v-mask="['###-##-#####']" v-model="bizNo">
                    </div>
                    <div class="input login__input">
                        <input type="password" placeholder="비밀번호를 입력하세요" v-model="pwNo" v-on:keyup.enter="doLogin">
                    </div>
                </div>
                <!-- /인풋박스 -->

                <!-- 인풋 경고 메시지 -->
                <p class="alert-text"></p>
                <!-- /인풋 경고 메시지 -->

                <!-- toggle 버튼 -->
                <div class="toggle login__option--wrap option__group">
                    <div class="login__option--group option__unit">
                        <div class="login__option--toggle">
                            <input type="checkbox" id="toggle" hidden>
                            <label for="toggle" class="toggle__switch">
                                <span class="toggle__btn"></span>
                            </label>
                            <span class="txt__title">로그인 유지</span>
                        </div>
                        <div class="login__option--move pc">
<!--                            <a href="/joinForm" class="go_join" ><span class="txt__title">회원가입</span></a>-->
                            <a href="javascript:void(0)" class="go_find" @click="$router.push('/findId')"><span class="txt__title">아이디 찾기/비밀번호 재설정</span></a>
                        </div>
                    </div>
                </div>
                <!-- toggle 버튼 -->

                <!-- 로그인 버튼 -->
                <div class="login__btn help-login__btn">
                    <button type="submit"  class="btn-basic btn-cta" v-bind:disabled="bizNo.length == 0 || pwNo.length == 0" @click="doLogin"><span>로그인</span></button>
                    <a href="javascript:void(0)" @click="goHomepage" class="btn-basic btn-line-cb" >비드톡톡 홈페이지 바로 가기</a>
                </div>
                <!-- 로그인 버튼 -->

                <!-- 회원가입 / 비밀번호 재설정 -->
                <div class="login__option--move mobile">
<!--                    <a href="/joinForm"  class="go_join" ><span class="txt__title">회원가입</span></a>-->
                    <a href="javascript:void(0)" class="go_find" @click="$router.push('/findId')"><span class="txt__title">아이디 찾기/비밀번호 재설정</span></a>
                </div>
                <!-- /회원가입 / 비밀번호 재설정 -->
            </div>
        </div>
    </div>
</template>


<script>
    import {mapActions} from 'vuex'

    export default {
        name: "login",
        metaInfo: {
            title: '[비드톡톡 로그인] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 로그인하시면, 더욱더 많은 비드톡톡의 기능을 사용하실 수 있습니다. 휴대폰에서든, 홈페이지에서든 로그인을 통해 더욱더 많은 비드톡톡의 기능을 이용해 보세요. 실시간 공고 확인, 정확하고 빠른 AI 분석, 다년간의 노하우를 갖춘 분석기업의 분석, 나라장터 투찰까지 비드톡톡에서 모두 가능합니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 로그인] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 로그인하시면, 더욱더 많은 비드톡톡의 기능을 사용하실 수 있습니다. 휴대폰에서든, 홈페이지에서든 로그인을 통해 더욱더 많은 비드톡톡의 기능을 이용해 보세요. 실시간 공고 확인, 정확하고 빠른 AI 분석, 다년간의 노하우를 갖춘 분석기업의 분석, 나라장터 투찰까지 비드톡톡에서 모두 가능합니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return {
                bizNo: '',
                pwNo: '',
                custInfo: {},
            }
        },
        methods: {
            ...mapActions([ 'TOGGLE_DRAWER' ]),
            init() {
                localStorage.removeItem("authToken");
                this.existToken = false;
                this.bizNo = "";
                this.pwNo = "";
                this.$router.push('/home');
                document.querySelector('.login-bar--wrap').classList.toggle('on');
            },
            doLogin() {
                if (this.bizNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "사업자번호를 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                    return false;
                } else if (this.pwNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "비밀번호를 입력해 주세요." ;
                    document.querySelector('.alert-text').classList.add('on');
                } else {
                    var params = {
                        "custIdNm": this.bizNo.replaceAll('-',''),
                        "custPw": this.pwNo,
                        "loginDstnctCd": "0"
                        //"fbToken": params["fbToken"].toString() // firebase token
                    };

                    this.$http.post('/api/login',params)
                        .then(res => {
                            localStorage.setItem("authToken",res.data.authToken);
                            localStorage.setItem("refreshToken",res.data.refreshToken);
                            localStorage.setItem("custInfo",JSON.stringify(res.data.cust));
                            //this.saveStateToStorage(res.data.authToken);
                            this.existToken = true;
                            this.custInfo = JSON.parse(localStorage.getItem("custInfo"));

                            this.$router.replace('/bidHelper/bidHelperAnalysis');
                        })
                        .catch(error => {
                                console.log(error);
                                document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                                document.querySelector('.alert-text').classList.add('on');
                            }
                        );
                    this.insTracking('/bidHelper/bidHelperAnalysis');

                }
            },
            doLogout() {
                this.init();
            },
            goHomepage() {
                window.open("/home", "popup", "width=1280, height=900");
            },
            async insTracking(path) {

                var tracking = {
                    "trackingCd": "3",
                    "trackingAction" : "ACCESS",
                    "trackingNm": "페이지 이동",
                    "trackingDesc" : path,
                    "trackingType" : 'BIDHELPER',
                };

                this.$insertTracking(tracking);


            },

        },
        created() {

        },

        mounted() {
            //body 색상 변경
            if (document.querySelector('.login__wrap')) {
                document.querySelector('body').style.backgroundColor = '#f5f6fa';
            }


            // input 이벤트
            const inputs = document.querySelectorAll('input');
            inputs.forEach((element) => {

                element.addEventListener('keyup', () => {
                    const word =  element.value.length;
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.add('input-valid');
                    }
                });
                element.addEventListener('focus', () => {
                    //블라블라
                });
                element.addEventListener('blur', () => {
                    const word =  element.value.length;
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.remove('input-valid');
                    }
                });
            });

        }
    }


</script>
<style src="@/assets/scss/common/bid-helper.scss" lang="scss" scoped></style>
