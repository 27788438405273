<template>
    <div class="app-story">
        <div class="inner">
            <h2 class="menu__title">앱 이야기</h2>
            <div class="slide card">슬라이드 부분</div>
            <ul class="layout__half list">
                <li class="card shadow list__item"><img src="@/assets/survey/poster.jpg" alt="이미지"><p class="txt__title">비드톡톡 앱 출시!</p></li>
                <li class="card shadow list__item"><img src="@/assets/survey/poster.jpg" alt="이미지"><p class="txt__title">AI 자동 분석은 현재 진행형</p></li>
                <li class="card shadow list__item"><img src="@/assets/survey/poster.jpg" alt="이미지"><p class="txt__title">비드톡톡의 캐릭터 탄생 스토리...</p></li>
                <li class="card shadow list__item"><img src="@/assets/survey/poster.jpg" alt="이미지"><p class="txt__title">비드톡톡 앱은 알잘딱깔센..?</p></li>
            </ul>
            <a href="javascript:void(0)" class="btn__moveTop" @click="moveTop();">
                <img src="@/assets/ic_arrow_up.svg" alt="위로가기 버튼 아이콘">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppStory",
        methods: {
            //페이지 최상단 이동
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            }
        },
        created() {

        },

        mounted() {

        }
    }


</script>

<style src="./AppStory.scss" lang="scss" scoped />