<template>
    <!-- 업체 정보 -->
    <div class="edit__area cmpy-info">
        <div class="edit__area--head">
            <div>
                <p class="edit__area--title">업체 정보</p>
                <a href="javascript:void(0)" class="btn-basic btn-line-cw edit--btn" v-show="!isEditBizInfo" @click="editCustBiz()">수정</a>
                <a href="javascript:void(0)" class="btn-basic btn-cta edit--btn" v-show="isEditBizInfo" @click="saveCustBiz()">저장</a>
            </div>
        </div>
        <div class="grid__container edit__list cmpy-info__list">
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">사업자등록번호</p>
                <p class="grid__cell txt--sub">{{mock.custBiz.bizNo | bizNo}}</p>
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">업체명</p>
                <p class="grid__cell txt--sub">{{mock.custBiz.cmpNm}}</p>
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">대표자명</p>
                <p class="grid__cell txt--sub">{{mock.custBiz.rpsntvNm}}</p>
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">지역</p>
                <p class="grid__cell txt--sub">{{mock.custBiz.prvnclNm}} {{mock.custBiz.areaNm}}</p>
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">주소</p>
                <p class="grid__cell txt--sub" v-show="!isEditBizInfo">{{mock.custBiz.adrs}}</p>

                <!-- 주소 수정 -->
                <div class="input edit--input grid__cell" v-show="isEditBizInfo">
                    <input type="text" tabindex="1" placeholder="주소를 입력해주세요." v-model="custAddr" @click="openPostcode" @focusout="checkInput('custAddr',custAddr.length == 0 )" readonly>
                </div>
                <!-- /주소 수정 -->
            </div>
            <div class="grid__group edit__list--item" >
                <p class="grid__cell txt--title">상세주소</p>
                <p class="grid__cell txt--sub"  v-show="!isEditBizInfo">{{mock.custBiz.dtlAdrs}}</p>

                <!-- 상세 주소 수정 -->
                <div class="input edit--input"  v-show="isEditBizInfo">
                    <input type="text" tabindex="2" placeholder="상세 주소를 입력해주세요." v-model="custDtlAdrs">
                </div>
                <!-- /상세 주소 수정 -->
            </div>
        </div>
    </div>
    <!-- /업체 정보 -->
</template>
<script>
    import mock from '../mock';

    export default {
        data() {
            return {
                mock,
                loginInfo: {},
                isEditBizInfo : false, //업체정보 수정 활성화
                custAddr: '',   //주소
                custDtlAdrs: '',    //상세주소

            }
        },
        mounted() {
        },
        methods: {
            //다음 주소 검색
            openPostcode() {
                new window.daum.Postcode({
                    oncomplete: (data) => {
                        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분
                        // this.zonecode = data.zonecode;
                        this.custAddr = data.roadAddress;
                    },
                }).open();
            },
            //인풋 경고창 처리
            checkInput(target,value){
                const html = document.getElementById(target);

                if(value){
                    html.style.display = 'block';
                } else {
                    html.style.display = 'none';
                }
            },
            //업체정보 수정 모드로 변경
            editCustBiz() {
                this.isEditBizInfo = true;
                this.custAddr = mock.custBiz.adrs;
                this.custDtlAdrs = mock.custBiz.dtlAdrs;
            },
            //업체정보 저장
            saveCustBiz() {
                var param= {
                    "id": mock.custBiz.id,
                    "custId": mock.custBiz.custId,
                    "cmpNm": mock.custBiz.cmpNm,
                    "bizNo": mock.custBiz.bizNo.replaceAll('-', ''),
                    "custStatDstnctCd": "0",
                    "rpsntvNm":mock.custBiz.rpsntvNm,
                    "adrs": this.custAddr,
                    "dtlAdrs": this.custDtlAdrs,
                    "areaCd": mock.custBiz.areaCd,
                    "femaleCoYn": "N"
                };

                this.$post('/api/cust/saveCustBiz',param)
                    .then(res => {
                        if(res.code == 'OK'){
                            this.$toast.success("업체정보가 수정되었습니다.");
                            this.$parent.getCustInfo();
                        } else {
                            this.$toast.error("네트워크 상태를 체크해주시거나 고객센터(042-485-0123)로 연락 바랍니다.");
                        }
                        this.isEditBizInfo = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isEditBizInfo = false;
                    }
                    );
            }
        },

    }


</script>