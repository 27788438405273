<template>
    <div class="landingV2">
        <section id="section01" class="background">
            <div class="title ani01-2">
                <img src="@/assets/images/img_title_01-tablet.png" alt="타이틀">
                <div class="button button__gogglePlay">
                    <a href="https://play.google.com/store/apps/details?id=kr.co.newjini.bidtoktok&hl=ko" target="_blank" class="button__gogglePlay--mobile">
                        <img src="@/assets/bg_google-play.svg" alt="구글 플레이 바로가기" class="android">
                    </a>
                </div>
                <div class="button button__ios">
                    <a href="https://itunes.apple.com/app/6450491526" target="_blank" class="button__ios--mobile">
                        <img src="@/assets/bg_app-store.svg" alt="앱스토어 바로가기" class="ios">
                    </a>
                </div>
<!--                <a href="javascript:void(0)" class="button__container&#45;&#45;link"  @click="openPop()"><span>앱 다운로드 링크</span></a>-->
            </div>
            <div class="img">
                <img src="@/assets/bg_main-01.svg" alt="pc 배경 이미지" class="img__single">
                <img src="@/assets/bg_main-01-800px.png" alt="pc 배경 이미지" class="img__single display">
            </div>
            <div class="content-wrapper wrap">
            <div class="title ani01-1">
                <img src="@/assets/img_title_01-pc.png" alt="타이틀">
                <div class="button__container">
                    <div class="button  button__gogglePlay pc">
                        <img src="@/assets/images/img_qr_andro.png" alt="안드로이드 다운로드 연결 qr">
                        <p class="button__gogglePlay--title">안드로이드</p>
                    </div>
                    <div class="button  button__ios pc">
                        <img src="@/assets/images/img_qr_ios.png" alt="ios 다운로드 연결 qr">
                        <p class="button__ios--title">ios</p>
                    </div>
                    <a href="javascript:void(0)" class="button__container--link"  @click="openPop()"><span>앱 다운로드 링크</span></a>
                </div>
                <div class="button  button__gogglePlay mobile">
                    <a href="https://play.google.com/store/apps/details?id=kr.co.newjini.bidtoktok&hl=ko" target="_blank">
                        <img src="@/assets/bg_google-play.svg" alt="구글 플레이 바로가기" class="android">
                    </a>
                </div>
                <div class="button  button__ios mobile">
                    <a href="https://itunes.apple.com/app/6450491526" target="_blank">
                        <img src="@/assets/bg_app-store.svg" alt="앱스토어 바로가기" class="ios">
                    </a>
                </div>
            </div>
        </div>
        </section>
        <section id="section02" class="background">
            <div class="content-wrapper wrap ">
                <div class="video-wrap">
                    <youtube
                            id="youtubePlayer"
                            :video-id="videoId"
                            :player-vars="playerVars"
                            width="100%"
                            height="100%"
                            @autoplay="autoplay"
                            @ready="ready"
                            @playing="playing"
                            @ended="ended"
                            @error="error"
                            ref="youtube" />
<!--                    <iframe ref="youtube" id="landingVideo" src="https://www.youtube.com/embed/smoCsnUV7xk?autoplay=1&mute=1" title="NO.1 전자입찰 플랫폼 비드톡톡! 내 손안의 똑똑한 비드톡톡!" frameborder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>-->
                </div>
            </div>
        </section>
        <section id="section03" class="background">
            <div class="content-wrapper wrap">
                <div class="title ani02">
                    <img src="@/assets/img_title_02-pc.png" alt="타이틀">
                </div>
                <div class="float-icon">
                    <div>
                        <img src="@/assets/bg_analy_item01.svg" alt="공고 원문 아이콘 그림" class="float-icon__item">
                        <img src="@/assets/bg_analy_item02.svg" alt="첨부파일 아이콘 그림" class="float-icon__item">
                        <img src="@/assets/bg_analy_item03.svg" alt="내 순위 보기 아이콘 그림" class="float-icon__item">
                    </div>
                </div>
                <div class="img">
                    <div class="img__phone">
                        <div class="img__phone--ani-warp">
                            <div class="img__animation">
                                <div class="img__animation--bg">
                                    <img src="@/assets/img_list02_00.svg" alt="타이틀">
                                </div>
                                <div class="img__animation--img">
                                    <img src="@/assets/img_list02_01.svg" alt="타이틀">
                                    <img src="@/assets/img_list02_02.svg" alt="타이틀">
                                    <img src="@/assets/img_list02_03.svg" alt="타이틀">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="section04" class="background">
            <div class="content-wrapper wrap">
                <div class="title ani03">
                    <img src="@/assets/img_title_03-pc.png" alt="타이틀">
                </div>
                <div class="img">
                    <div class="img__phone">
                        <div class="img__phone--ani-warp">
                            <div class="img__animation">
                                <div class="img__animation--bg">
                                    <div class="img__animation--badge">
                                        <img src="@/assets/img_03_badge01.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge02.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge03.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge04.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge05.svg" alt="타이틀">
                                    </div>
                                    <div class="img__animation--badge-tablet">
                                        <img src="@/assets/img_03_badge01.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge02.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge03.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge04.svg" alt="타이틀">
                                        <img src="@/assets/img_03_badge05.svg" alt="타이틀">
                                    </div>
                                    <img src="@/assets/img_list03_00.svg" alt="타이틀">
                                </div>
                                <div class="img__animation--img">
                                    <img src="@/assets/img_list03_01.svg" alt="타이틀">
                                    <img src="@/assets/img_list03_02.svg" alt="타이틀">
                                    <img src="@/assets/img_list03_03.svg" alt="타이틀">
                                    <img src="@/assets/img_list03_04.svg" alt="타이틀">
                                    <img src="@/assets/img_list03_05.svg" alt="타이틀">
                                </div>
                                <div class="img__animation--shadow">
                                    <img src="@/assets/img_list03_00--.svg" alt="타이틀">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="section05" class="background">
            <div class="content-wrapper wrap">
                <div class="title ani04">
                    <img src="@/assets/img_title_04-pc.png" alt="타이틀">
                </div>
                <div class="img">
                    <div class="img__character">
                         <ul class="img__character--container">
                             <li class="img__character--dog">   <img src="@/assets/img_list04_02.svg" alt="캐릭터 도그" class="dog"></li>
                             <li class="img__character--peng">  <img src="@/assets/img_list04_03.svg" alt="캐릭터 펭" class="peng"></li>
                              <li class="img__character--suri"> <img src="@/assets/img_list04_01.svg" alt="캐릭터 수리" class="suri"></li>

                        </ul>



                        <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080 376.5">-->
                        <!--                            <path id="path01"  d="M-1395.109,1376.478v-.5a266.921,266.921,0,0,1,2.785-38.393,271.878,271.878,0,0,1,8.175-37.284,287.091,287.091,0,0,1,13.294-35.986,311.37,311.37,0,0,1,18.142-34.5,343.9,343.9,0,0,1,22.718-32.824,383.242,383.242,0,0,1,27.024-30.96,450.551,450.551,0,0,1,65.878-55.573,549.07,549.07,0,0,1,79.844-45.85,642.4,642.4,0,0,1,91.64-34.621,718.638,718.638,0,0,1,101.27-21.88c17.608-2.508,35.631-4.422,53.567-5.69,18.2-1.286,36.763-1.939,55.163-1.939s36.963.652,55.161,1.939c17.936,1.268,35.959,3.182,53.567,5.69a718.518,718.518,0,0,1,101.272,21.88,642.3,642.3,0,0,1,91.64,34.621,549.125,549.125,0,0,1,79.844,45.852,450.514,450.514,0,0,1,65.878,55.573,383.06,383.06,0,0,1,27.023,30.96,343.69,343.69,0,0,1,22.718,32.824,311.014,311.014,0,0,1,18.142,34.5A286.776,286.776,0,0,1-327.07,1300.3a271.581,271.581,0,0,1,8.175,37.284,266.753,266.753,0,0,1,2.786,38.391v.5" transform="translate(1220 -1200) scale(0.8)" fill="none" stroke="#707070" stroke-width="1"/>-->
                        <!--                            <path id="path02" d="M1266.006,280.246c0,48.375-116.029,90.389-286.4,111.6-75.292,9.371-161.192,14.685-252.384,14.685s-177.092-5.314-252.374-14.685c-170.383-21.213-286.413-63.228-286.413-111.6s116.029-90.389,286.413-111.6c75.282-9.371,161.182-14.685,252.374-14.685s177.092,5.314,252.384,14.685C1149.977,189.857,1266.006,231.872,1266.006,280.246Z" transform="translate(-40 -300) scale(0.8)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1" stroke-dasharray="1 0"/>-->
                        <!--                        </svg>-->
                    </div>
                    <img src="@/assets/bg_main-04.svg" alt="pc 배경 이미지" class="img__single">
                    <img src="@/assets/bg_main-04-1000px.png" alt="pc 배경 이미지" class="img__single display">
                </div>
            </div>
        </section>
        <section id="section06" class="background">
            <div class="content-wrapper wrap">
                <div class="left">
                    <div class="title  ani05">
                        <img src="@/assets/img_title_05-pc.png" alt="타이틀">
                    </div>
                    <div class="icon-item icon-left">
                        <div>
                            <img src="@/assets/img_item01_05.png" alt="눈 모양 그림">
                            <p>최근에 봤던 공고도 확인하고,</p>
                        </div>
                    </div>
                    <div class="img">
                        <div class="img__phone">
                            <div class="img__phone--ani-warp">
                                <div class="img__animation">
                                    <div class="img__animation--bg">
                                        <img src="@/assets/img_list05_01------.svg" alt="타이틀">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="img">
                        <div class="img__phone">
                            <div class="img__phone--ani-warp">
                                <div class="img__animation">
                                    <div class="img__animation--bg">
                                        <img src="@/assets/img_list05_02------.svg" alt="타이틀">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="icon-item icon-right">
                        <div>
                            <div>
                                <img src="@/assets/img_item02_05.png" alt="위치 모양 그림">
                                <img src="@/assets/img_item03_05.png" alt="면허 모양 그림">
                            </div>
                            <p>내 지역, 면허에 맞는 모든 공고도 추천받아 보세요.</p>
                        </div>
                    </div>
                </div>
                <div class="tablet">
                    <div class="title">
                        <img src="@/assets/img_title_05-tablet.png" alt="타이틀">
                    </div>
                    <div class="icon-item icon-center">
                        <div>
                            <img src="@/assets/img_item01_05.png" alt="눈 모양 그림">
                            <p>최근에 봤던 공고도 확인하고,</p>
                        </div>
                        <div>
                            <img src="@/assets/img_item02_05.png" alt="위치 모양 그림">
                            <img src="@/assets/img_item03_05.png" alt="면허 모양 그림">
                            <div>
                                <p>내 지역, 면허에 맞는</p>
                                <p>모든 공고도 추천받아 보세요.</p>
                            </div>
                        </div>
                    </div>
                    <div class="img">
                        <div class="img__phone">
                            <div class="img__phone--ani-warp">
                                <div class="img__animation">
                                    <div class="img__animation--img">
                                        <img src="@/assets/img_list05_01---.svg" alt="타이틀">
                                        <img src="@/assets/img_list05_02---.svg" alt="타이틀">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <transition name="fade">
            <section id="section07" class="background" ref="section06" v-show="section06View">
                <div class="wrap">
                    <div>
                        <img src="@/assets/ic_logo_reverse.svg" alt="비드톡톡 로고">
                        <p>내 손안의 모바일 입찰 플랫폼</p>
                        <p>비드톡톡</p>
                    </div>
                    <div>
                        <div class="button  button__gogglePlay">
                            <a href="https://play.google.com/store/apps/details?id=kr.co.newjini.bidtoktok&hl=ko" target="_blank">
                                <img src="@/assets/bg_google-play.svg" alt="구글 플레이 바로가기" class="android">
                            </a>
                        </div>
                        <div class="button  button__ios">
                            <a href="https://itunes.apple.com/app/6450491526" target="_blank">
                                <img src="@/assets/bg_app-store.svg" alt="앱스토어 바로가기" class="ios">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="footer__bottom">
                    <ul class="wrap footer__bottom--list">
                        <li class="footer__bottom--item work-time">고객문의: 1670-0508 평일 08:00 ~ 17:00<p>(점심시간: 11:30 ~ 13:00 / 주말 및 공휴일 휴무)</p></li>
                        <li class="footer__bottom--item company-info">(주) 지니 ㅣ 사업자 등록 번호: 314-86-08166 <p>E-mail. webmaster@bidtok.co.kr</p> <p>주소: 대전광역시 서구 계룡로 553번길 18, 조이빌딩 9F (우 35262)</p></li>
                        <li class="footer__bottom--item rights"> © JINI Corp. All Rights Reserved</li>
                    </ul>
                </div>
            </section>
        </transition>
        <div class="landingV2__header" style="background:rgba(255,255,255,0.9);border-bottom-style:solid; border-bottom-color:#efefef; border-bottom-width:1px;">
           <div class="wrap">
               <a href="/home" class="logo">
                   <img src="@/assets/ic_logo_main.svg" alt="비드톡톡 로고">
               </a>
               <a id="myLink" class="link-home" style="visibility: hidden;">
                   비드톡톡 시작하기
               </a>
           </div>
        </div>
        <ul id="section08" class="landingV2__nav">
            <li class="active" @click="move(0)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(1)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(2)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(3)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(4)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(5)"><a href="javascript:void(0)"><span></span></a></li>
            <li @click="move(6)"><a href="javascript:void(0)"><span></span></a></li>
        </ul>

        <div class="popup__wrap download__wrap" id="popupWrap">
            <div class="popup__item--container">
                <div class="popup__item--group download__area">
                    <div class="popup__item--head download__head">
                        <!-- 타이틀이 있을경우 -->
                         <h3 class="txt__title">비드톡톡 앱 다운로드</h3>
                    </div>
                    <div class="popup__item--body download__body">
                        <div class="download__body--group phone ">
                            <p class="txt__sub">앱 다운로드 링크를 받으실<br>휴대폰번호를 입력해 주세요. </p>
                            <div class="input">
                                <input type="text"  v-model="phoneNum" placeholder="휴대폰번호를 입력해 주세요." maxlength="11">
                            </div>
                        </div>
                        <div class="download__body--group agree">
                            <div class="download__body--chekbox">
                                <div class="check">
                                    <input type="checkbox" id="check18" v-model="indvdlinfoAuthz">
                                    <label for="check18"></label>
                                </div>
                                <a href="javascript:void(0)" @click="indvInAuYn()">(필수) 개인정보 수집 및 이용 동의</a>
                            </div>
                            <p class="txt__sub">앱 다운로드 링크 전송을 위해 전화번호를<br >수집하며, 수집일로부터 3개월간 보관됩니다.</p>
                        </div>
                    </div>
                </div>
                <!-- 버튼이 하나일 때 popup__button--wrap 클래스 뒤에 single 추가,
                     버튼이 두개일 때 popup__button--wrap 클래스 뒤에 pair 추가-->
                <!-- <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta"><span>분석실로 이동</span></a>
                </div> -->
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb"  @click="closePop()">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta"  @click="sendSms()">문자 발송</a>
                </div>
            </div>
        </div>
        <!--     // popup-->


<!--                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="_closeFnc()">닫기</a>-->
<!--                    <a href="javascript:void(0)" class="btn-basic btn-cta close-btn" @click="_closeFnc()">문자발송</a>-->

    </div>
</template>

<script>
    import gsap from 'gsap';
    import { ScrollToPlugin , ScrollTrigger, MotionPathPlugin} from 'gsap/all'
    import Vue from 'vue'
    import VueYoutube from "vue-youtube";
    import { loadScript } from "vue-plugin-load-script";

    window.channelPluginSettings = {
        pluginKey: '7ede9fbd-cfd2-4584-acaf-4c49eab81a12',
        hideDefaultLauncher: true,
    }

    Vue.use(VueYoutube);

    gsap.registerPlugin(MotionPathPlugin);
    gsap.registerPlugin(ScrollToPlugin);
    gsap.registerPlugin(ScrollTrigger);
    export default {
        data() {
            return {
                ticking: false,
                isFirefox: /Firefox/i.test(navigator.userAgent),
                isIe: /MSIE/i.test(navigator.userAgent),
                scrollSensitivitySetting: 30,
                slideDurationSetting: 600,
                currentSlideNumber: 0,
                slides: [],
                section06View: false,
                autoScrollNum: 0,
                autoScroll: null,
                touch: { sx: null, sy: null, st: null, ex: null, ey: null, et: null },
                loginInfo: {},

                videoId: 'smoCsnUV7xk',
                autoplay: 0,
                playerVars: {
                    autoplay: 0,
                    rel:0,
                    // controls: 0,
                    mute: 1,
                    // fs: 0,
                    cc_lang_pref: "ko",
                    cc_load_policy: 1,
                },

                phoneNum : "",
                indvdlinfoAuthz : false,
                indvdlinfoAuthzYn : 'N',

            };
        },
        mounted() {
            this.slides = this.$el.querySelectorAll('.background');
            // window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
            window.addEventListener('wheel', this.parallaxScroll, false);
            window.addEventListener('touchstart', this.touchstart, false);
            window.addEventListener('touchmove', this.touchmove, false);
            window.addEventListener('touchend', this.touchend, false);

            // this.autoScroll = setInterval(() => {
            //     this.move(this.autoScrollNum++, true);
            //     if (this.autoScrollNum >= 6) {
            //         this.autoScrollNum = 0;
            //     }
            // }, 3500);

            loadScript(
                'https://cdn.channel.io/plugin/ch-plugin-web.js',
                'ChannelIO'
            ).then(value => {
                if (value) {
                    var ch = function() {
                        ch.c(arguments)
                    }
                    ch.q = []
                    ch.c = function(args) {
                        ch.q.push(args)
                    }
                    window.ChannelIO = ch;
                    window.ChannelIO('boot', {
                        pluginKey: window.channelPluginSettings.pluginKey
                    }, function onBoot(error, user) {
                        if (error) {
                            console.error(error);
                        } else {
                            console.log('boot success', user);
                        }
                    });
                    window.ChannelIO('onBoot', () => {
                        this.active = true
                    });
                    window.ChannelIO('onChangeBadge', unreadCount => {
                        this.unreadCount = unreadCount
                    })
                }
            })
            this.loginInfo =JSON.parse(localStorage.getItem("custInfo"));
            if(this.loginInfo!= null){
                this.$socket.emit('login',{
                    id: this.loginInfo.id,
                    alias: this.loginInfo.cmpNm

                });
            }

            if (this.loginInfo == null) {
                document.getElementById('myLink').href = '/home';
            } else {
                document.getElementById('myLink').href = '/analysis';
            }


            // mobile - 주소창 높이를 뺀 브라우저 높이
            window.addEventListener('load',function(){
                let vh = 0;
                vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('$vh', `${vh}px`);
            },false);

            // mobile - 확대 축소 막기
            document.body.addEventListener('touchstart', function(e) {
                if ( (e.touches.length > 1) || e.targetTouches.length > 1) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                }
            }, {passive: false});

            // 맥, 윈도우 구분
            // checkPlatform();
            // function checkPlatform() {
            //     var varUA = navigator.userAgent.toLowerCase(); // User-Agent 값 얻기
            //     let ios = document.querySelectorAll('.button__gogglePlay img.ios');
            //     let android = document.querySelectorAll('.button__gogglePlay img.android');
            //
            //     if (varUA.indexOf('mac') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('iphone') > -1) {
            //         // 맥 또는 iOS 기기
            //         for (let i = 0; i < android.length; i++) {
            //             android[i].style.display = 'none';
            //         }
            //     } else if (varUA.indexOf('win') > -1) {
            //         // 윈도우 기기
            //         for (let i = 0; i < ios.length; i++) {
            //             ios[i].style.display = 'none';
            //         }
            //     } else {
            //         // 맥 또는 윈도우 외의 기기
            //         for (let i = 0; i < ios.length; i++) {
            //             ios[i].style.display = 'none';
            //         }
            //     }
            // }

        },
        computed: {
            player() {
                return this.$refs.youtube.player;
            }
        },
        methods: {
            launcher() {
                console.log('show messenger');
                window.ChannelIO('show');
            },
            ready(){console.log('ready')},
            playing(){console.log('playing')},
            ended(){console.log('ended')},
            error(){console.log('error')},
            touchstart(e) {
                this.settouchpos(e, "start");
            },
            touchmove(e) {
                this.settouchpos(e, "move");
            },
            touchend(e) {
                this.settouchpos(e, "end");
                // let dx = this.touch.sx - this.touch.ex;
                let dy = this.touch.sy - this.touch.ey;
                // let dt = this.touch.et - this.touch.st;
                // let vmove = Math.abs(dx) / Math.abs(dy) < Math.sqrt(4);

                // console.log(dx);
                // console.log(dy);
                // console.log(dt);
                // console.log(vmove);

                if (dy >= this.scrollSensitivitySetting) {
                    //Down scroll
                    this.ticking = true;
                    if (this.currentSlideNumber !== this.slides.length - 1) {
                        this.currentSlideNumber++;
                        console.log('thicking' + this.currentSlideNumber);
                        if (this.currentSlideNumber == 1) {
                            this.player.playVideo();

                        } else {
                            this.player.stopVideo();
                        }
                        if (this.currentSlideNumber == 6) {
                            setTimeout(() => {
                                this.section06View = true;
                            }, 200);
                        } else {
                            this.section06View = false;
                        }
                        this.nextItem();
                    }
                    this.slideDurationTimeout(this.slideDurationSetting);
                }
                if (dy <= -this.scrollSensitivitySetting) {
                    //Up scroll
                    this.ticking = true;
                    if (this.currentSlideNumber !== 0) {
                        this.currentSlideNumber--;
                    }
                    this.previousItem();
                    this.slideDurationTimeout(this.slideDurationSetting);
                    console.log('갹' + this.currentSlideNumber);
                    if (this.currentSlideNumber == 1) {
                        this.player.playVideo();

                    } else {
                        this.player.stopVideo();
                    }
                    if (this.currentSlideNumber != 6) {
                        this.section06View = false;
                    }
                }


                // ntab = ntab >= 0 && ntab < this.items.length ? ntab : null;
                // if (Math.abs(dx) > 10 && ntab !== null && !vmove && dt < 300)
                //     console.log('페이지변경');
                // this.switchtab(ntab);
            },
            settouchpos (e, event) {
                let ev = e.changedTouches ? e.changedTouches[0] : e
                if (event === 'start') {
                    this.touch.sx = ev.clientX
                    this.touch.sy = ev.clientY
                    this.touch.st = Date.now()
                } else {
                    this.touch.ex = ev.clientX
                    this.touch.ey = ev.clientY
                    this.touch.et = Date.now()
                }
            },
            parallaxScroll(evt) {
                let delta;
                if (this.isFirefox) {
                    delta = evt.detail * -120;
                } else if (this.isIe) {
                    delta = -evt.deltaY;
                } else {
                    delta = evt.wheelDelta;
                }

                if (!this.ticking) {
                    if (delta <= -this.scrollSensitivitySetting) {
                        //Down scroll
                        this.ticking = true;
                        if (this.currentSlideNumber !== this.slides.length - 1) {
                            this.currentSlideNumber++;
                            console.log('page 내려감' + this.currentSlideNumber);
                            document.querySelector('.landingV2__header').classList.add('color');

                            try{
                                if (this.currentSlideNumber == 1) {
                                    this.player.playVideo();

                                } else {
                                    this.player.stopVideo();
                                }
                            }catch (e) {
                                console.log(e.toString())
                            }

                            if (this.currentSlideNumber == 6) {
                                setTimeout(() => {
                                    this.section06View = true;
                                }, 200);
                            } else {
                                this.section06View = false;
                            }
                            this.nextItem();
                        }
                        this.slideDurationTimeout(this.slideDurationSetting);
                    }
                    if (delta >= this.scrollSensitivitySetting) {
                        //Up scroll
                        this.ticking = true;
                        if (this.currentSlideNumber !== 0) {
                            this.currentSlideNumber--;
                            console.log('올라감');
                        }
                        this.previousItem();
                        this.slideDurationTimeout(this.slideDurationSetting);
                        console.log('page' + this.currentSlideNumber);
                        try{
                            if (this.currentSlideNumber == 1) {
                                this.player.playVideo();

                            } else {
                                this.player.stopVideo();
                            }
                        }catch (e) {
                            console.log(e.toString())
                        }

                        if (this.currentSlideNumber != 6) {
                            this.section06View = false;
                        }
                        if (this.currentSlideNumber == 0) {
                            document.querySelector('.landingV2__header').classList.remove('color');
                        }
                        if (this.currentSlideNumber == 1) {
                             document.getElementById('landingVideo').contentWindow.location.reload();
                        }
                    }
                }

                // // 800px 이하 해상도에서 두번째 페이지 부터 header에 백그라운드 컬러 추가
                // let screenWidth = window.innerWidth;
                // if (screenWidth < 801) {
                //     if (this.currentSlideNumber > 0) {
                //         document.querySelector('.landingV2__header').style.background = "rgba(255,255,255,0.5)";
                //     } else if(this.currentSlideNumber == 0) {
                //         document.querySelector('.landingV2__header').style.background = "transparent";
                //     }
                // }

            },

            //클라이언트 IP 확인
            async getPublicIP() {
                try {
                    const response = await fetch('https://api64.ipify.org?format=json');
                    if (response.ok) {
                        const data = await response.json();
                        return data.ip;
                    } else {
                        throw new Error('Failed to fetch IP address');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    return null;
                }
            },

            //문자전송팝업 오픈
            async openPop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "block";

                var tracking = {
                    "trackingCd": "1",
                    "trackingAction" : "CLICK",
                    "trackingNm": "앱링크 다운로드",
                    "trackingDesc" : "",
                };

                await this.$insertTracking(tracking);
            },

            //문자전송팝업 닫기
            closePop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "none";
                this.phoneNum = "";
                this.indvdlinfoAuthz = false;
            },

            indvInAuYn() {
                if(this.indvdlinfoAuthz == false){
                    this.indvdlinfoAuthz = true;
                } else if(this.indvdlinfoAuthz == true){
                    this.indvdlinfoAuthz = false;
                }
            },

            //문자전송
            async sendSms() {
                if(this.phoneNum == ""){
                    this.$toast.success("휴대폰번호를 입력해 주세요.", {
                        position:'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return ;
                }
                if(this.indvdlinfoAuthz == false){
                    this.$toast.success("개인정보 수집에 동의해 주세요.", {
                        position:'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return ;
                }

                var popupWrap = document.getElementById("popupWrap");

                if(this.indvdlinfoAuthz == true){
                    this.indvdlinfoAuthzYn = 'Y';
                }

                var clintIP = await this.getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthzYn
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.", {
                            position:'top-center',
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            transitionDuration: 750,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true
                        });
                        popupWrap.style.display = "none";
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                    })

                    .catch(error => {console.log(error);}
                    );

            },

            slideDurationTimeout(slideDuration) {
                setTimeout(scrollTo, 0, 0, 1);
                setTimeout(() => {
                    this.ticking = false;
                }, slideDuration);
            },
            nextItem() {
                // if (!auto)
                //     clearInterval(this.autoScroll);
                const $previousSlide = this.slides[this.currentSlideNumber - 1];
                if (this.currentSlideNumber != 6) {
                    $previousSlide.classList.remove('up-scroll');
                    $previousSlide.classList.add('down-scroll');
                }
                this.parallNavi()
                this.moveAni()
            },
            previousItem() {
                // if (!auto)
                //     clearInterval(this.autoScroll);
                const $currentSlide = this.slides[this.currentSlideNumber];
                $currentSlide.classList.remove('down-scroll');
                $currentSlide.classList.add('up-scroll');
                this.parallNavi()
            },
            //네비게이션
            parallNavi() {
                //네비게이션 스타일
                var activeItems = document.querySelectorAll('.active');
                activeItems.forEach(item => {
                    item.classList.remove('active')
                });
                document.querySelectorAll('.landingV2__nav > li')[this.currentSlideNumber].className += 'active';
            },
            move(num) {
                //네비게이션 클릭 이동
                if (num == 1) {
                    this.player.playVideo();

                } else {
                    this.player.stopVideo();
                }
                //클릭한 네비게이션과 실제 페이지 차이 계산하기
                const slideDiff = num - this.currentSlideNumber;
                // if (slideDiff > 0) {
                //     this.currentSlideNumber = num;
                //     this.nextItem();
                //     this.slideDurationTimeout(this.slideDurationSetting);
                //
                // } else if (slideDiff < 0) {
                //     this.currentSlideNumber = num;
                //     this.previousItem();
                //     this.slideDurationTimeout(this.slideDurationSetting);
                // }
                if (slideDiff > 0) {
                    for(var i = this.currentSlideNumber ; i < num ; i++){
                        this.currentSlideNumber = i+1;
                        if (slideDiff == 6 && this.currentSlideNumber == 6) {
                            setTimeout(() => {
                                this.section06View = true;
                            }, 1000);
                        } else if (slideDiff == 5 && this.currentSlideNumber == 5) {
                            setTimeout(() => {
                                this.section06View = true;
                            }, 1000);
                        } else if(slideDiff == 4 && this.currentSlideNumber == 5){
                            setTimeout(() => {
                                this.section06View = true;
                            }, 800);
                        } else if(slideDiff == 3 && this.currentSlideNumber == 5){
                            setTimeout(() => {
                                this.section06View = true;
                            }, 600);
                        } else if(slideDiff == 2 && this.currentSlideNumber == 5){
                            setTimeout(() => {
                                this.section06View = true;
                            }, 400);
                        } else if(slideDiff == 1 && this.currentSlideNumber == 5){
                            setTimeout(() => {
                                this.section06View = true;
                            }, 200);
                        } else {
                            this.section06View = false;
                        }
                        this.nextItem();
                        this.slideDurationTimeout(this.slideDurationSetting);
                    }

                } else if (slideDiff < 0) {
                    for(var j = this.currentSlideNumber ; j > num ; j--){
                        this.currentSlideNumber = j-1;
                        this.previousItem();
                        this.slideDurationTimeout(this.slideDurationSetting);
                        if (this.currentSlideNumber != 6) {
                            this.section06View = false;
                        }
                    }
                }
            },
            moveAni() {


                let tl = gsap.timeline();

                if(this.currentSlideNumber == 0){
                    tl.fromTo(".ani01-1",  { autoAlpha: 0,  opacity: 0, x: 0, y: 100, duration: 2, delay: 0},
                        { autoAlpha: 1,  opacity: 1, x: 0, y: 0, duration: 2, delay: 0.7, ease: "expo.out" });
                }
                else if(this.currentSlideNumber == 1){
                    gsap.fromTo(".ani02", {opacity: 0, duration: 2, y: 100, delay: 0}, {opacity: 1, duration: 2, y: 0, delay: 0.5, ease: "expo.out" });
                    gsap.fromTo(".float-icon", {duration: 2, y: 100, opacity:0, delay: 0}, {duration: 2, y: '3vh', opacity: 1, delay: 1, ease: "expo.out" });
                }
                else if(this.currentSlideNumber == 2){
                    tl.fromTo(".ani03",  { autoAlpha: 0,  opacity: 0, x: 0, y: 100, duration: 2, delay: 0},
                        { autoAlpha: 1,  opacity: 1, x: 0, y: 0, duration: 2, delay: 0.7, ease: "expo.out" });

                }
                else if(this.currentSlideNumber == 3){
                    gsap.fromTo(".ani04", {opacity: 0, duration: 2, y: 100, delay: 0}, {opacity: 1, duration: 2, y: 0, delay: 0.5, ease: "expo.out" });
                    gsap.fromTo(".dog", {duration: 2, y: 100, opacity:0, delay: 0}, {duration: 2, y: 0, opacity: 1, delay: 0.6, ease: "expo.out" });
                    gsap.fromTo(".suri", {duration: 2, x: 100, y: 100, opacity:0, delay: 0}, {duration: 2, x: 0, y: 0, opacity: 1, delay: 0.8, ease: "expo.out" });
                    gsap.fromTo(".peng", {duration: 2, x: -100, y: 0, opacity:0, delay: 0}, {duration: 2, x: 0, y: 0, opacity: 1, delay: 1, ease: "expo.out" });

                }
                else if(this.currentSlideNumber == 4){
                    gsap.fromTo(".ani05", {opacity: 0, duration: 2, y: 100, delay: 0}, {opacity: 1, duration: 2, y: 0, delay: 0.5, ease: "expo.out" });
                    gsap.fromTo(".icon-left", {duration: 2, y: 100, opacity:0, delay: 0}, {duration: 2, y: 0, opacity: 1, delay: 0.7, ease: "expo.out" });
                    gsap.fromTo(".icon-right", {duration: 2, y: 100, opacity:0, delay: 0}, {duration: 2, y: 0, opacity: 1, delay: 1, ease: "expo.out" });
                    gsap.fromTo(".icon-center", {duration: 2, y: 100, opacity:0, delay: 0}, {duration: 2, y: 0, opacity: 1, delay: 0.7, ease: "expo.out" });
                }

            },
            // pathAnimation() {
            //     gsap.to(".suri", {
            //         duration: 5,
            //         repeat: 12,
            //         repeatDelay: 2,
            //         yoyo: true,
            //         ease: "power1.inOut",
            //         motionPath: {
            //             path: "#path01",
            //             align: "#path01",
            //             autoRotate: false,
            //             alignOrigin: [0.5, 0.2]
            //         }
            //     });
            //
            // }
        }
    };
</script>
<style src="./Landing.scss" lang="scss" scoped/>