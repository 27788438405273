<template>
    <!-- 사용자 정보 -->
    <div class="edit__area user-info">
        <div class="edit__area--head">
            <div>
                <p class="edit__area--title">사용자 정보</p>
                <a href="javascript:void(0)" class="btn-basic btn-line-cw edit--btn" v-show="!isEditCustInfo" @click="isPopConfPw = true;currentPw=''">수정</a>
                <a href="javascript:void(0)" class="btn-basic btn-cta edit--btn" v-show="isEditCustInfo" @click="updateCustInfo()">저장</a>
            </div>
        </div>
        <div class="grid__container edit__list user-info__list">
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">사업자등록번호(아이디)</p>
                <p class="grid__cell txt--sub">{{mock.custInfo.bizNo | bizNo}}</p>
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">비밀번호</p>
                <div class="grid__cell user-info__element">
                    <span class="">-</span>
                    <a href="javascript:void(0)" class="btn-basic user-info--btn" @click="isPopResetPw = true" v-show="isEditCustInfo">변경</a>
                </div>
            </div>
            <div class="grid__group edit__list--item cell-phone">
                <p class="grid__cell txt--title">휴대폰 번호</p>
                <div class="grid__cell user-info__element">
                    <span class="" v-show="!isEditCustInfo">{{mock.custInfo.custTelno}}</span>
                    <span class="" v-show="isEditCustInfo">{{modifyTelno}}</span>
                    <a href="javascript:void(0)" class="btn-basic user-info--btn" @click="isPopTelno = true" v-show="isEditCustInfo">변경</a>
                </div>
            </div>
            <div class="grid__group edit__list--item phone">
                <p class="grid__cell txt--title">일반전화 번호</p>
                <div class="grid__cell user-info__element">
                    <span class="txt--sub" v-show="!isEditCustInfo">{{mock.custInfo.custLandlineno}}</span>
                    <!-- 전화번호 수정 -->
                    <div class="phone--input input edit--input grid__cell " v-show="isEditCustInfo">
                        <input type="text" placeholder="일반전화를 입력해주세요." v-model="modifyLandlineno">
                    </div>
                    <!-- /전화번호 수정 -->
                    <!-- <a href="javascript:void(0)" class="btn-basic user-info--btn">변경</a> -->
                </div>
            </div>
            <div class="grid__group edit__list--item email">
                <p class="grid__cell txt--title">세금계산서 이메일</p>
                <p class="grid__cell txt--sub" v-show="!isEditCustInfo">{{mock.custInfo.custEmail}}</p>

                <!-- 이메일 수정 -->
               <div class="input edit--input grid__cell email" v-show="isEditCustInfo">
                    <input type="text" class="edit-email--adress" v-model="email">
                    <span class="at">@</span>
                    <div class="edit-email__element">
                        <input type="text" class="input-self edit-email--self" placeholder="" v-model="domain" :disabled="isCustomMail!='Y'">
                        <div class="select-box edit-email--select">
                            <button class="select-box__sel" >선택해 주세요.</button>
                            <ul class="select-box__list">
                                <li><button type="button" value="">선택해 주세요.</button></li>
                                <li><button type="button" value="N">naver.com</button></li>
                                <li><button type="button" value="N">daum.net</button></li>
                                <li><button type="button" value="N">gmain.com</button></li>
                                <li><button type="button" value="N">nate.com</button></li>
                                <li><button type="button" value="N">kakao.com</button></li>
                                <li><button type="button" value="Y">직접 입력</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /이메일 수정 -->
            </div>
        </div>
        <!-- 현재 비밀번호 확인 팝업 -->
        <div class="popup__wrap edit-pswd edit-pswd--step01" :class="isPopConfPw? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group edit-pswd--group">
                    <div class="popup__item--head">
                        <p class="txt__title">현재 비밀번호 확인</p>
                    </div>
                    <div class="popup__item--body edit-pswd--body">
                        <p class="txt__sub">정보를 안전하게 보호하기 위해<br>현재 비밀번호를 입력해 주세요.
                        </p>
                        <div class="input input__combo password">
                            <input :type="showCurrentPw ? 'text': 'password'" class="mb12" placeholder="비밀번호를 입력해주세요" v-model="currentPw">
                            <button>
                                <i class="input__combo--hide-password" title="문자 숨기기" v-show="!showCurrentPw" @click="fnShowCurrentPassword(true)"></i>
                                <i class="input__combo--show-password" title="문자 보이기" v-show="showCurrentPw" @click="fnShowCurrentPassword(false)"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="isPopConfPw = false; currentPw=''">취소</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="checkPw()">확인</a>
                </div>
            </div>
        </div>
        <!-- /현재 비밀번호 확인 팝업 -->
        <!-- 비밀번호 변경 팝업 -->
        <div class="popup__wrap edit-pswd edit-pswd--step02" :class="isPopResetPw ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group edit-pswd--group">
                    <div class="popup__item--head">
                        <p class="txt__title">비밀번호 변경</p>
                    </div>
                    <div class="popup__item--body edit-pswd--body">
                        <p class="edit-pswd--title">변경할 비밀번호</p>
                        <div class="input input__combo password">
                            <input :type="showPassword ? 'text': 'password'" class="mb12" placeholder="비밀번호를 입력해주세요" v-model="password"
                                   @input="checkInput('passwordTrue',checkPassword(password));checkInput('passwordFalse',!checkPassword(password))">
                            <button>
                                <i class="input__combo--hide-password" title="문자 숨기기" v-show="!showPassword" @click="fnShowPassword(true)"></i>
                                <i class="input__combo--show-password" title="문자 보이기" v-show="showPassword" @click="fnShowPassword(false)"></i>
                            </button>
                            <p id='passwordFalse' class="input-noti incorr">영문소문자, 숫자 포함 6~15자로 입력해 주세요.</p>
                            <p id='passwordTrue' class="input-noti corr" style="display:none">영문소문자, 숫자 포함 6~15자로 입력해 주세요.</p>
                        </div>
                        <p class="edit-pswd--title">변경할 비밀번호</p>
                        <div class="input input__combo password">
                            <input :type="showPasswordConfirm ? 'text': 'password'" class="mb12" placeholder="비밀번호를 입력해주세요" v-model="passwordConfirm">
                            <button>
                                <i class="input__combo--hide-password" title="문자 숨기기" v-show="!showPasswordConfirm" @click="fnShowPasswordConfirm(true)"></i>
                                <i class="input__combo--show-password" title="문자 보이기" v-show="showPasswordConfirm" @click="fnShowPasswordConfirm(false)"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="isPopResetPw = false; password  = ''; passwordConfirm = ''">취소</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="updatePassword()">변경</a>
                </div>
            </div>
        </div>
        <!-- /비밀번호 변경 팝업 -->
        <!-- 휴대폰 번호 변경 팝업 -->
        <div class="popup__wrap edit-phone" :class="isPopTelno ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group edit-phone--group">
                    <div class="popup__item--head">
                        <p class="txt__title">휴대폰번호 변경</p>
                    </div>
                    <div class="popup__item--body edit-phone--body">
                        <p class="txt__sub">변경할 휴대폰번호를 입력하시고,<br>인증을 진행해 주세요.</p>

                        <div class="">
                            <p class="edit-phone--title">변경할 휴대폰번호</p>
                            <div class="input join-form__wrap--input pair phone">
                                <input type="text" placeholder="예 01012345678" v-model="phoneNo"  v-mask="['###-###-####','###-####-####']" @input="checkPhoneNo();">
                                <!-- 버튼 비활성화시 btn__non-active-->
                                <!-- 버튼 활성화시 btn__active-->
                                <button class="btn-basic" type="button" :class="!phoneValidate ? 'btn__non-active':'btn__active'"  @click="sendcertNo">인증번호발송</button>
                            </div>
                            <div class="input join-form__wrap--input single phone-confirm">
                                <input type="text" placeholder="인증번호를 입력해 주세요." v-model="certNo"
                                       @input="checkSms();">
                                <span class="certi-time" v-show="Timer!=null && TimeCounter != 0">{{TimerStr}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="closePopTelno">취소</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="setModifyTelno() ">변경</a>
                </div>
            </div>
        </div>
        <!-- /휴대폰 번호 변경 팝업 -->
    </div>
    <!-- /사용자 정보 -->
</template>
<script>
    import mock from '../mock';

    export default {
        data() {
            return {
                mock,
                isEditCustInfo: false,  //수정모드여부

                showCurrentPw: false,   //현재 비밀번호 텍스트/비밀번호 표기
                showPassword: false,    //변경 비밀번호 텍스트/비밀번호 표기
                showPasswordConfirm: false, //변경 비밀번호 확인 텍스트/비밀번호 표기
                isPopConfPw: false, //비밀번호 확인 팝업
                isPopResetPw: false,    //비밀번호 변경 팝업
                isPopTelno: false,      //전화번호 변경 팝업

                currentPw: '',      //현재비밀번호 model
                password: '',       //변경비밀번호 model
                passwordConfirm: '',    //변경비밀번호확인 model

                modifyTelno: '',    //전화번호 변경 model
                modifyLandlineno: '', //일반전화번호 변경 model
                modifyEmail: '',
                phoneNo: "",        //휴대폰번호 입력창
                certNo: '',         //인증번호 입력창
                Timer: null,
                TimeCounter: 180,
                TimerStr: "03:00",
                phoneValidate: false,   //휴대폰번호 유효성검증
                sendCryalTelno: '',     //인증보낸 휴대폰번호
                smsValidate: false,     //인증여부

                email: '',      //이메일 앞부분
                domain: '',     //이메일 도메인부분
                isCustomMail: 'N',  //이메일 직접입력 여부
            }
        },
        watch: {
            isPopConfPw(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopResetPw(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopTelno(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
        },
        mounted() {
            /* 셀렉트박스 */
            const selectBox = document.querySelector('.select-box__sel');
            if (selectBox) {
                selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                document.addEventListener('click', (event) => {
                    const target = event.target;
                    if (!selectBox.contains(target)) {
                        selectBox.classList.remove('on');
                    }
                });
                /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
            }

            const selectBoxItem = document.querySelector('.select-box__list');
            if (selectBoxItem) {
                selectBoxItem.addEventListener('click', (event) => {
                    selectBox.innerText = event.target.innerText;
                    if( selectBox.innerText != '직접 입력'){
                        this.domain = selectBox.innerText;
                    } else {
                        this.domain = '';
                    }
                    this.isCustomMail = event.target.value;
                    selectBox.classList.remove('on');
                });
            }
        },
        methods: {
            /** 핸드폰 번호 인증 팝업 닫기*/
            closePopTelno(){
                this.isPopTelno = false;
                this.phoneNo = '';
                this.certNo = '';
                this.TimerStr = "03:00";
                this.TimeCounter = 180;
                this.Timer = null;
                this.phoneValidate = false;
            },
            /** 사용자 정보 수정모드 켜기*/
            editCustInfo() {
                this.isEditCustInfo = true;

                this.modifyTelno = mock.custInfo.custTelno;
                this.modifyLandlineno = mock.custInfo.custLandlineno;
                this.modifyEmail = mock.custInfo.custEmail;
                //세금계산서 파싱
                var temp = mock.custInfo.custEmail.split('@');

                if(temp.length==2){
                    this.email = temp[0];
                    this.domain = temp[1];
                }

            },
            timerStart: function() {
                // 1초에 한번씩 start 호출
                this.TimeCounter = 180;
                var interval = setInterval(() => {
                    this.TimeCounter--; //1초씩 감소
                    this.TimerStr = this.prettyTime();
                    if (this.TimeCounter <= 0) this.timerStop(interval);
                }, 1000);
                return interval;
            },
            timerStop: function(Timer) {
                clearInterval(Timer);
                this.TimeCounter = 0;
            },
            prettyTime: function() {
                // 시간 형식으로 변환 리턴
                let time = this.TimeCounter / 60;
                let minutes = parseInt(time);
                let secondes = Math.round((time - minutes) * 60);
                return (
                    minutes.toString().padStart(2, "0") +
                    ":" +
                    secondes.toString().padStart(2, "0")
                );
            },
            /** 사용자 정보 업데이트*/
            updateCustInfo() {


                var param = {
                    "userNm": mock.custInfo.userNm,
                    "custTelno": this.modifyTelno,
                    "custLandlineno": this.modifyLandlineno,
                    "custEmail": this.email + '@' + this.domain,
                    "custTelnoList": [
                        {
                            "id": mock.custInfo.custTelnoList.length > 0 ? mock.custInfo.custTelnoList[0].id : '', // 고객전화번호 아이디
                            "custTelnoDstnctCd": '1', // 대표자
                            "recvr": mock.custInfo.userNm, // 대표자명
                            "custTelno": this.modifyTelno, // 대표자 연락처
                        },
                    ],
                };

                this.$post('/api/cust/custInfo/update',param)
                    .then(res => {
                        if(res.code == 'OK' ){
                            this.$toast.success('사용자 정보가 수정 되었습니다.');
                            this.isEditCustInfo = false;
                            this.$parent.getCustInfo();
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            /** 휴대폰 번호 변경*/
            setModifyTelno(){
                if (this.phoneNo.length == 0) {
                    this.$toast.error("변경할 휴대폰번호를 입력해 주세요.");
                    return false;
                }
                if (this.phoneNo.length!=0 && !this.checkPhoneNo()) {
                    this.$toast.error("변경할 휴대폰번호 양식이 일치하지 않습니다.");
                    return false;
                }
                if(this.certNo.length == 0 ){
                    this.$toast.error("변경할 휴대폰 번호의 인증번호를 입력해 주세요.");
                    return false;
                }

                if (this.sendCryalTelno != this.phoneNo.replaceAll('-', '')) {
                    this.$toast.error("인증번호를 인증해 주세요.");
                    return false;
                }
                if (this.smsValidate == false) {
                    this.$toast.error("인증번호가 일치하지 않습니다. 다시 입력해 주세요.");
                    return false;
                }

                if(this.sendCryalTelno == this.phoneNo.replaceAll('-', '')){

                    this.modifyTelno = this.phoneNo;
                    this.closePopTelno();
                }
            },
            /** 인증번호 발송*/
            sendcertNo(){
                if(this.checkPhoneNo()){
                    var param = {"cryalTelno": this.phoneNo.replaceAll('-', '')};

                    this.sendCryalTelno = this.phoneNo.replaceAll('-', '');

                    this.$http.post('/api/launcher/extrnl/sendSms',param)
                        .then(res => {
                            if(res['status'] == 'OK'){
                                this.$toast.success("인증번호가 발송되었습니다.\n인증번호를 입력해 주세요.");
                            }
                            //문자발송성공시 호출
                            if(this.Timer != null){
                                this.timerStop(this.Timer);
                                this.Timer = null;
                            }
                            this.Timer = this.timerStart();
                        })
                        .catch(error => console.log(error));
                }

            },
            /** 인증번호 확인*/
            checkSms() {
                if(this.certNo.length == 6){
                    var param = {"cryalTelno": this.phoneNo.replaceAll('-', ''), "authzNo": this.certNo,};

                    this.$http.post('/api/launcher/extrnl/checkSms',param)
                        .then(res => {
                            if(res.data['status'] == 'OK'){
                                this.smsValidate = true;
                                this.$toast.success("휴대폰번호가 인증 되었습니다.", );
                                this.timerStop( this.Timer);
                            }else{
                                this.$toast.error("휴대폰 인증 번호를 다시 확인해 주세요.");
                            }
                        })
                        .catch(()=>{
                            this.$toast.error("휴대폰 인증 번호를 다시 확인해 주세요.");
                        });
                    // .catch(error => console.log(error));
                }
            },
            /** 비밀번호 업데이트*/
            updatePassword() {
                if(this.password.length == 0 ){
                    this.$toast.error('변경할 비밀번호를 입력해 주세요.');
                    return;
                } else if(this.passwordConfirm.length == 0 ){
                    this.$toast.error('변경할 비밀번호 확인을 입력해 주세요.');
                    return;
                }  else if(this.passwordConfirm != this.password){
                    this.$toast.error('변경할 비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해 주세요.');
                    return;
                } else if (this.password.length!=0 && !this.checkPassword()) {
                    this.$toast.error("비밀번호가 조건에 맞지 않습니다.\n조건에 맞게 다시 입력해 주세요.");
                    return;
                }

                var param =  {
                    "id": mock.custInfo.id,
                    "custPw": this.password,
                };

                this.$post('/api/cust/updatePassword',param)
                    .then(res => {
                        if(res.code == 'OK' ){
                            this.$toast.success('비밀번호가 변경되었습니다.');
                            this.password  = '';
                            this.passwordConfirm = '';
                            this.isPopResetPw = false;
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            checkInput(target,value){
                const html = document.getElementById(target);

                if(value){
                    html.style.display = 'block';
                } else {
                    html.style.display = 'none';
                }
            },
            /** 비밀번호 양식 검사*/
            checkPassword() {
                // 비밀번호 형식 검사(영문, 숫자, 특수문자)
                const validatePassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?~^<>,.&+=]{6,15}$$/
                const matches = validatePassword.test(this.password);

                return matches;
            },
            /** 휴대폰번호 양식 검사*/
            checkPhoneNo() {
                // 비밀번호 형식 검사(영문, 숫자, 특수문자)
                const validatePassword = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/
                const matches = validatePassword.test(this.phoneNo);

                this.phoneValidate = matches;
                return matches;
            },
            /** 비밀번호 검증*/
            checkPw() {
                if(this.currentPw.length == 0 ){
                    this.$toast.error('현재 비밀번호를 입력해 주세요.');
                    return;
                }

                var params = {
                    "custIdNm": mock.custInfo.bizNo,
                    "custPw": this.currentPw,
                    "loginDstnctCd": "0",
                    // "fbToken": params["fbToken"].toString()
                };

                this.$post('/api/login',params)
                    .then(res => {
                        if(res.message != null ){
                            this.$toast.error(res.message);
                        } else {
                            this.isPopConfPw = false;
                            this.currentPw='';
                            this.editCustInfo();
                        }
                    })
                    .catch(error => {
                             this.$toast.error('입력하신 비밀번호가 일치하지 않습니다.\n확인 후, 다시 입력해 주세요');
                            console.log(error);
                        }
                    );
            },
            /** 현재 비밀번호 텍스트/비밀번호 표기*/
            fnShowCurrentPassword(value) {
                this.showCurrentPw = value;

            },
            /** 변경 비밀번호 텍스트/비밀번호 표기*/
            fnShowPassword(value) {
                this.showPassword = value;

            },
            /** 변경 비밀번호 확인 텍스트/비밀번호 표기*/
            fnShowPasswordConfirm(value) {
                this.showPasswordConfirm = value;

            },
        }
    }


</script>