<template>
    <div class="baner-detil wrap">

        <!-- AI멤버십 -->
        <div id="content5" class="baner-detil__container fee std">
            <div class="f-i">
                <p class="h4">01</p>
                <p class="h2 higlight">AI멤버십 낙찰 수수료가 0원!</p>
                <p class="h3">
                    늘 낙찰을 원하면서도 고민되었던 한 가지, <span class="line-break--01">낙찰 수수료 AI멤버십은 대표님의 고민을 없애드립니다.</span>
                </p>
            </div>

            <div class="baner-detil__area f-i-delay">
                <p class="txt">이제는 낙찰하고 소리지르세요!
                    <span class="line-break--01">똑똑한 AI분석을 자유롭게 이용하고 낙찰하면 낙찰 수수료는 0원 </span>
                    <span class="line-break--02">비드톡톡은 언제나 <i class="empha">대표님의 낙찰을 응원합니다!</i></span>
                </p>
                <div class="baner-detil__bg">
                    <a href="javascript:void(0)" class="baner-detil--move move-hover" @click="$goMenu('membership')">
<!--                        <p>1분 <span class="baner-detil&#45;&#45;move&#45;&#45;img img-join"></span>하고</p>-->
                        <p class="txt--bold">AI멤버십 요금 확인하러 TOK<i class="ic--arrow--view"></i></p>
                    </a>
                    <div class="baner-detil__bg--img"></div>
                </div>
            </div>
        </div>
        <!-- /AI멤버십 -->

        <!-- 특허 출원 중이 AI -->
        <div id="content2" class="baner-detil__container ai std">
            <div class="f-i">
                <p class="h4">02</p>
                <p class="h2 higlight">특허 출원 중인 AI</p>
                <p class="h3">
                    여기저기 무늬만 다 AI?<br>
                    AI 기술 발전과 함께 많은 기업이 AI를 활용한 서비스를 선보이고 있습니다.<br>
                    하지만 AI라고 같은 가치를 제공하는 것은 아닙니다.
                </p>
            </div>

            <div class="baner-detil__area f-i-delay">
                <p class="txt">
                    <span class="empha">특허 출원 중인 비드톡톡 AI</span>는 수십 년간 누적된 경험과 노하우를 바탕으로<span class="line-break--01">일반적인 AI 분석보다 정교하고 심층적인 입찰 데이터 분석이 가능합니다.</span><span class="empha line-break--02">고객님의 성향에 맞게 AI 분석가를 선택해 보세요.</span><br>객관적인 관점으로 분석가를 제시해 드리겠습니다
                </p>
                <div class="baner-detil__bg">
                    <a href="javascript:void(0)" class="baner-detil--move move-hover" @click="$goMenu('joinForm')">
                        <p>1분<span class="baner-detil--move--img img-join"></span>하고</p>
                        <p class="txt--bold">비드톡톡 이용하러 TOK<i class="ic--arrow--view"></i></p>
                    </a>
                    <div class="baner-detil__bg--img"></div>
                </div>
            </div>
        </div>
        <!-- /특허 출원 중이 AI -->

        <!-- 마음이 가는 대로 -->
        <div id="content3" class="baner-detil__container select std">
            <div class="f-i">
                <p class="h4">03</p>
                <p class="h2 higlight">마음이 가는 대로</p>
                <p class="h3">
                    입찰 업체가 주는 가격만 받아서 투찰 보시고 있나요?<br>
                    낙찰이 없음에도 계약기간 때문에 어쩔 수 없이 사용하고 있진 않나요?
                </p>
            </div>

            <div class="baner-detil__area f-i-delay">
                <p class="txt">
                    비드톡톡 안에서는 <span class="empha">하나의 계약서로</span> 여러 분석가를 자유롭게 바꾸고, 선택할 수 있습니다. <span class="line-break--01">3개의 AI와 6개의 분석기업 중 <span class="empha">고객님의 마음이 가는 대로</span> 원하는 분석을 받아보고</span> <span class="line-break--02 empha">차이를 경험하세요.</span>
                </p>
                <div class="baner-detil__bg">
                    <a href="javascript:void(0)" class="baner-detil--move move-hover" @click="$goMenu('joinForm')">
                        <p>1분<span class="baner-detil--move--img img-join"></span>하고</p>
                        <p class="txt--bold">비드톡톡 이용하러 TOK<i class="ic--arrow--view"></i></p>
                    </a>
                    <div class="baner-detil__bg--img"></div>
                </div>
            </div>
        </div>
        <!-- /마음이 가는 대로 -->

        <!-- 나도 모르는 내 투찰 성향!? -->
        <div id="content1" class="baner-detil__container propensity std">
            <div class="f-i">
                <p class="h4">04</p>
                <p class="h2 higlight">나도 모르는 내 투찰 성향?!</p>
                <p class="h3">
                    나를 잘 알아야 낙찰도 가능한 법!<br>고객님의 지난 1년간의 입찰 참여 데이터를 분석하여<br>투찰 성향을 파악할 수 있는 <span class="empha">'톡톡 리포트'</span>를 제공합니다.
                </p>
            </div>

            <div class="baner-detil__area f-i-delay">
                <p class="txt">
                    톡톡 리포트는 내 투찰 스타일을 이해하고 이를 바탕으로 <span class="line-break--01">체계적이고 합리적인 투찰 결정을 내릴 수 있도록 도와줍니다.</span> 익숙한 듯 익숙하지 않은 투찰! <span class="line-break--02">
                    고객님도 몰랐던 나의 <span class="empha">새로운 투찰 성향</span>을 발견해 보세요.
                </span>
                </p>
                <div class="baner-detil__bg">
                    <a href="javascript:void(0)" class="baner-detil--move move-hover" @click="$goMenu('joinForm')">
                        <p>1분 <span class="baner-detil--move--img img-join"></span>하고</p>
                        <p class="txt--bold">톡톡리포트 확인하러 TOK<i class="ic--arrow--view"></i></p>
                    </a>
                    <div class="baner-detil__bg--img"></div>
                </div>
            </div>
        </div>
        <!-- /나도 모르는 내 투찰 성향!? -->

        <!-- 꼭 필요한 순간 앱으로! -->
<!--        <div id="content4" class="baner-detil__container app std">-->
<!--            <div class="f-i">-->
<!--                <p class="h4">04</p>-->
<!--                <p class="h2 higlight">꼭 필요한 순간 앱으로!</p>-->
<!--                <p class="h3">-->
<!--                    갑자기 생각난 공고, 모바일로 확인하고 싶은데<br>-->
<!--                    화면이 깨지고, 확대되고 불편하지 않으셨나요?-->
<!--                </p>-->
<!--            </div>-->

<!--            <div class="baner-detil__area f-i-delay">-->
<!--                <p class="txt">-->
<!--                    비드톡톡은 <span class="empha">웹, 앱 모두 준비</span>되어 있습니다. <span class="line-break&#45;&#45;01">매일 사용하진 않더라도 꼭 필요한 순간에</span> 빠르고, 쉽고, 편리하게 사용할 수 있는 앱을 접속해 보세요. <span class="line-break&#45;&#45;02 empha">원하는 정보만 쏙쏙!</span> 비드톡톡은 작은 부분 하나도 놓치지 않습니다.-->
<!--                </p>-->
<!--                <div class="baner-detil__bg">-->
<!--                    <a href="javascript:void(0)" class="baner-detil&#45;&#45;move move-hover" @click="openPop()">-->
<!--                        <p>1분<span class="baner-detil&#45;&#45;move&#45;&#45;img img-dwnlad"></span>하고</p>-->
<!--                        <p class="txt&#45;&#45;bold">비드톡톡 이용하러 TOK<i class="ic&#45;&#45;arrow&#45;&#45;view"></i></p>-->
<!--                    </a>-->
<!--                    <div class="baner-detil__bg&#45;&#45;img"></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- /꼭 필요한 순간 앱으로! -->
        <!-- 앱다운로드 팝업 - QR추가 -->
        <div class="popup__wrap dowlod-guide__wrap" :class="isPopAppDown ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group dowlod-guide__group">
                    <div class="popup__item--head">
                        <p class="txt__title">비드톡톡 앱 다운로드</p>
                    </div>
                    <div class="popup__item--body dowlod-guide__group--body">
                        <p class="dowlod-guide--txt h2">Welcome 환영합니다!<span>원하시는 방법으로 앱을 다운로드 받아주세요.</span></p>
                        <div class="dowlod-guide__box dowlod-guide--img--suri">
                            <div class="dowlod-guide__item mail">
                                <div class="dowlod-guide--img--title mail"></div>
                                <p class="dowlod-guide--txt h3 dowlod-guide--img--num one">문자로 앱 다운로드 링크 받기</p>
                                <p class="dowlod-guide--txt h4">휴대폰번호를 입력해주시면 <br> 앱 다운로드 링크를 문자로 보내드려요.</p>
                                <div class="input dowlod-guide--input">
                                    <input type="text" class="input--valid disable" placeholder="휴대폰번호를 입력해 주세요." v-model="phoneNum">
                                </div>
                                <div class="dowlod-guide__element">
                                    <div class="input--check dowlod-guide--check">
                                        <input type="checkbox" id="check17" v-model="indvdlinfoAuthz">
                                        <label for="check17"></label>
                                    </div>
                                    <a href="javascript:void(0)" @click="indvdlinfoAuthz = !indvdlinfoAuthz"> <span class="dowlod-guide--txt h4">(필수) 개인정보 수집 및 이용 동의</span></a>
                                    <p class="dowlod-guide--txt h5">앱 다운로드 링크 전송을 위해 전화번호를 수집하며,<br>수집일로부터 3개월간 보관됩니다.</p>
                                </div>
                                <div class="dowlod-guide--btn">
                                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="sendSms()">문자 발송</a>
                                </div>
                            </div>
                            <div class="dowlod-guide__item qr">
                                <div class="dowlod-guide--img--title lens"></div>
                                <p class="dowlod-guide--txt h3  dowlod-guide--img--num two">QR 코드 스캔하기</p>
                                <p class="dowlod-guide--txt h4">카메라로 QR코드를 스캔해 주세요.</p>
                                <div class="dowlod-guide--img qr"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close"  @click="isPopAppDown = false;">닫기</a>
                </div>
            </div>
        </div>

        <!-- /앱다운로드 팝업 - QR추가 -->
    </div>
</template>

<script>
    export default {
        name: "HomeBannerDetail",
        data() {
            return {
                isPopAppDown: false,        //앱다운로드 팝업 여부
                phoneNum : "",
                indvdlinfoAuthz : false,
            }
        },
        methods: {
            //문자전송
            async sendSms() {
                if(this.phoneNum == ""){
                    this.$toast.success("휴대폰번호를 입력해 주세요.");
                    return ;
                }
                if(this.indvdlinfoAuthz == false){
                    this.$toast.success("개인정보 수집에 동의해 주세요.");
                    return ;
                }

                var clintIP = await this.$getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthz ? 'Y' : 'N'
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.");
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
            //문자전송팝업 오픈
            async openPop() {

                var tracking = {
                    "trackingCd": "1",
                    "trackingAction" : "CLICK",
                    "trackingNm": "앱링크 다운로드",
                    "trackingDesc" : "",
                };

                await this.$insertTracking(tracking);

                this.isPopAppDown = true;
            },
        },
        created() {

        },

        mounted() {
            ////////////////////////////////////////////////////////////////////

            /*  스크롤 애니메이션  */

            ////////////////////////////////////////////////////////////////////

            /*  기준 영역 변수 설정  */
            const boxes = document.querySelectorAll('.std');

            //1. 스크린 크기에 따른 애니메이션 구간 설정

            function calculateAnimationAreas() {
                const windowWidth = window.innerWidth;

                if (windowWidth >= 1001) {
                    return {
                        areaTxt: window.innerHeight / 5 * 4
                    };
                } else if (windowWidth >= 801) {
                    return {
                        areaTxt: window.innerHeight * 0.8
                    };
                } else  {
                    return {
                        areaTxt: window.innerHeight * 0.8
                    };
                }
            }


            //2. 텍스트 애니메이션 영역에 해당할 때 클래스 추가 / 제거
            function applyTxtAni(elements, condition) {

                elements.forEach((element) => {
                    if (condition) {
                        element.classList.add('active');
                    } else {
                        element.classList.remove('active');
                    }
                });
            }
            function contentsFiAni(elements, condition) {

                elements.forEach((element) => {
                    if (condition) {
                        setTimeout(function() {
                            element.classList.add('active');
                        }, 400);

                    } else {
                        setTimeout(function() {
                            element.classList.remove('active');
                        }, 400);
                    }
                });
            }

            // 3. 텍스트 애니메이션이 될 클래스 & 애니메이션이 될 영역 매개변수로 넘김
            function txtAni() {

                const { areaTxt } = calculateAnimationAreas();
                // 스크린에 따라 애니메이션 되어야 할 영역 가져옴

                boxes.forEach((box) => {

                    const boxTop = box.getBoundingClientRect().top;

                    const fIElements = box.querySelectorAll('.f-i');
                    const fIDlayElements = box.querySelectorAll('.f-i-delay');
                    const highlight = box.querySelectorAll('.higlight');

                    //applyTxtAni(fIElements, boxTop <=  areaTxt  && boxTop > -boxHeight);
                    applyTxtAni(fIElements, boxTop <=  areaTxt);
                    applyTxtAni(highlight, boxTop <=  areaTxt );
                    contentsFiAni(fIDlayElements, boxTop <= areaTxt );

                });
            }
            txtAni();
            window.addEventListener('resize', txtAni);
            window.addEventListener('load', txtAni);
            window.addEventListener('scroll', txtAni);

            const targetId = window.location.hash;

            setTimeout(function() {
                let targetElement = document.querySelector(`${targetId}`);

                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        }
    }


</script>



<!--<style src="./IntroduceView.scss" lang="scss" scoped />-->