<template>
    <div class="entire-search__container wrap recom-announce">
        <!-- 기간 조회 및 검색 조회 결과 -->
        <div class="period">
            <a href="javascript:void(0)" class="period--date" @click="$parent.$refs.filter.tabNo = 6">
                <span class="from-date">{{$moment(mock.searchParam.findStrtDt).format('YY.MM.DD')}}</span>
                <span class="dash"> ~ </span>
                <span class="to-date">{{$moment(mock.searchParam.findEndDt).format('YY.MM.DD')}}</span>
            </a>
            <div class="srch-txt" v-show="mock.isSearching == false && mock.searchParam.searchText.length == 0 && mock.notiSearchList.length > 0"><span>&quot;<span class="num">{{mock.notiSearchListCnt}}</span>&quot;건의 결과를 찾았습니다.</span></div>
            <div class="srch-txt" v-show="mock.isSearching == false && mock.searchParam.searchText.length > 0 && mock.notiSearchList.length > 0"><span>검색어 &quot;{{mock.searchParam.searchText}}&quot;에 대한 &quot;<span class="num">{{mock.notiSearchListCnt}}</span>&quot;건의 결과를 찾았습니다.</span></div>

        </div>
        <!-- /기간 조회 및 검색 조회 결과 -->

        <!-- 검색 결과 없을 때  -->
        <div class="entire-search--no-res no-res" v-show="mock.notiSearchList.length == 0 && mock.isSearching == false">
<!--            <p class="txt mobile">"0"건의 결과를 찾았습니다.</p>-->
            <div class="no-res--img"></div>
            <p class="title">검색어에 대한 공고가 없습니다.</p>
            <p class="sub">검색 단어 변경 또는 숫자를 변경하여 <span class="line-break">다시 검색 해보세요.</span></p>
        </div>
        <!-- /검색 결과 없을 때  -->

        <!-- 공고 리스트 -->
        <div class="layout__half announce" v-show="mock.notiSearchList.length > 0 && mock.isSearching == false">
            <div class="announce--list">
                <div class="layout__item announce--item wide-list" v-for="noti in mock.notiSearchList" v-bind:key="noti.id">
                    <!-- *투찰 현황 condition-badge__item 뒤에 클래스 덧붙임
                        투찰전 > before, 투찰가능 > ing, 투찰마감 > fin, 개찰완료 > announce,  참가신청 > application -->
                    <div class="announce__group condition-badge announce--item--badge">
                        <p class="condition-badge__item" :class="
                        noti.notiStatus == '투찰전' ? 'before' :
                        noti.notiStatus == '투찰가능' ? 'ing' :
                        noti.notiStatus == '투찰마감' ? 'fin' :
                        noti.notiStatus == '개찰완료' ? 'announce':
                        noti.notiStatus == '취소' ? 'cancle': ''
                        "><span></span></p>
                    </div>
                    <a href="javascript:void(0)" class="announce__group announce--item--move-detail tit" @click.prevent="notiDetailPop('notiDetail',noti.notiId)">
                        <p class="txt__title">
                            <span class="condition-badge__item application" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'"><span></span></span>
                            <span class="bdg-code code07 mr4" v-show="noti.rebidNo > 0">재입찰</span>
                            {{noti.notiNm}}</p>
                        <div class="viw-all" v-show="deviceType == 'Desktop'">
                            <p class="txt__title">{{noti.notiNm}}</p>
                        </div>
                        <div class="announce__copy">
                            <div class="announce__copy--item">
                                <span class="txt__sub">{{noti.notiNo}}{{noti.notiSeq != null && noti.notiSeq != '' ? ('-' + noti.notiSeq) : ''}}</span>
                                <button type="button" class="btn__copy" @click.stop="copyNotiNo(noti.notiNo)"></button>
                            </div>
                        </div>
                    </a>
                    <a href="javascript:void(0)" class="announce__group announce--item--move-detail category" @click.prevent="notiDetailPop('notiDetail',noti.notiId)">
                        <ul class="announce__info">
                            <li class="announce__info--list announce__info--company"><span
                                    class="txt__title">지역</span><span class="txt__sub">{{noti.areaNmConcat}}</span>
                            </li>
                            <li class="announce__info--list announce__info--company">
                                <span class="txt__title">면허</span>
                                <div class="main-lisc bdg-lic">
                                    <i class="main-lisc--ic bdg-lic--ic main" v-show="noti.noticeMainLicnsList.length > 0">주력</i>
                                    <div class="viw-all" v-show="deviceType == 'Desktop'">
                                        <p class="txt__title">{{noti.noticeMainLicnsList.map((e) => e.licnsNm).join(" | ")}}</p>
                                    </div>
                                    <span class="txt__sub">
                                     {{noti.licnsNmConcat}}
                                    </span>
                                </div>
                            </li>
<!--                            <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span-->
<!--                                    class="txt__title">면허</span><span class="txt__sub">{{noti.licnsNmConcat}}</span>-->
<!--                            </li>-->
<!--                            <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span class="txt__title"  v-show="noti.noticeMainLicnsList != null && noti.noticeMainLicnsList.length > 0">주력</span>-->
<!--                                <span class="txt__sub" v-for="licns in noti.noticeMainLicnsList" v-bind:key="licns.id">{{licns.licnsNm}}</span>-->
<!--                            </li>-->

                            <li class="announce__info--list"><span class="txt__title">기초금액</span><span
                                    class="txt__sub">{{noti.baseAmt | currency}}</span></li>
                        </ul>
                    </a>
                    <a href="javascript:void(0)" class="announce__group announce__period" @click.prevent="notiDetailPop('notiDetail',noti.notiId)">
                        <p class="txt__title" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'">참가마감</p>
                        <span class="txt__sub" :class="noti.isRegDt ? 'announce__period--today' : ''" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'">{{noti.regstFinDtText}}</span>
                        <span class="dividing-line" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'"></span>
                        <p class="txt__title">투찰마감</p>
                        <span class="txt__sub" :class="noti.isBddprDt ? 'announce__period--today' : ''">{{noti.bddprFinDtText}}</span>
                    </a>
                    <button type="button" class="announce--cta-btn btn-basic announce--request" v-show="noti.custNotiId == null" @click="checkCustCntrct(noti)" :class="noti.notiStatus == '취소' || noti.notiStatus == '투찰마감' || noti.notiStatus == '개찰완료' ? 'btn__non-active cancle': ''"><span>분석요청</span></button>
                    <button type="button" class="announce--cta-btn btn-basic announce--request btn__non-active" v-show="noti.custNotiId != null"><span>분석요청 완료</span></button>
                    <!-- <button type="button"
                        class="announce--cta-btn btn-basic announce--request"><span>분석요청</span></button> -->
                </div>
            </div>
        </div>
        <!-- /공고 리스트 -->
        <button class="btn-basic btn-line-cb analist--btn--more"  v-show="mock.notiSearchList.length < mock.notiSearchListCnt" @click="$parent.page++; $parent.getNotiSearchList()"><span>{{mock.notiSearchListCnt - mock.notiSearchList.length > $parent.pageUnit ? $parent.pageUnit :mock. notiSearchListCnt - mock.notiSearchList.length}}개 더 보기↓</span></button>
        <div class="analist__list--fin active"><span class="analist__list--fin--txt" v-show="mock.notiSearchList.length >= mock.notiSearchListCnt && mock.notiSearchListCnt != 0 &&mock. notiSearchListCnt > $parent.pageUnit">모두 확인하셨습니다.</span></div>

<!--         분석서비스 이용 동의 팝업 -->
        <div class="popup__wrap recom-announce__pop--wrap consent-use__pop--wrap" :class="isPopCustCntrct ? 'active' : ''">
            <!-- 시작 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container cover" :class="isPopCustCntrctStep1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="javascript:void(0)" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt--sub">분석 서비스를 이용을 위해<br> 이용약관 및 계약서 동의가 필요합니다.</p>
                        <p class="txt--title">지금 바로 동의하고 분석 서비스를 이용해 보세요!</p>
                        <a href="javascript:void(0)" class="btn-basic btn-cta popup__next consent-use__pop--btn" @click=" isPopCustCntrctStep1 = false; isPopCustCntrctStep2 = true;">네.<br>동의하고 바로 이용할게요.</a>
                        <a href="javascript:void(0)" class="btn-basic btn-line-cb consent-use__pop--btn consent-use__pop--btn--close" @click="isPopCustCntrct = false">아니오.<br>그냥 둘러만 볼래요.</a>
                    </div>
                </div>
            </div>
            <!-- /시작 -->

            <!-- 내용 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-01" :class="isPopCustCntrctStep2 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="javascript:void(0)" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title border-bottom">① 분석서비스 이용 동의</p>
                        <div class="join-form__box">
                            <p class="join-form__title consent-title">⑤ 약관동의</p>
                            <ul class="input join-form__wrap--link consent-use__pop--link">
                                <li class="join-form__wrap--move use-consent">
                                    <a href="javascript:void(0)" class="btn-basic btn-line-cw link__consent" @click="isPopUseAgree = true;">(필수)분석서비스 이용 약관 동의
                                        <span class="link__consent--check">
                                            <i class="check-none" title="체크 비활성화" :style="{ display: !anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            <i class="check-active" title="체크 활성화" :style="{ display: anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            동의
                                        </span>
                                    </a>
                                </li>
                                <li class="join-form__wrap--move info-consent">
                                    <a href="javascript:void(0)" class="btn-basic btn-line-cw link__consent" @click="isPopCntrct = true;">(필수)분석서비스 제공계약서
                                        <span class="link__consent--check">
                                    <i class="check-none" title="체크 비활성화" :style="{ display: !cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    <i class="check-active" title="체크 활성화" :style="{ display: cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    동의
                                     </span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <p class="txt__title">② 세금계산서 수신 이메일(선택)</p>
                        <div class="input step-01--input">
                            <input type="text" placeholder="이메일을 입력해주세요." v-model="email">
                        </div>
                        <!--                        <p class="txt__sub">* 성공보수료의 세금계산서를 수신할 이메일을 입력해 주세요.</p>-->
                        <!--                        <div class="input--check">-->
                        <!--                            <input type="checkbox" id="email" v-model="checkEmail">-->
                        <!--                            <label for="email"></label>-->
                        <!--                        </div>-->
                        <!--                        <label for="email">이메일로 분석서비스 제공계약서 받기</label>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__next"
                       :class="anlsAgreeYn && cntrctAgreeYn && email != ''? 'btn__active':'btn__non-active'"
                       @click="insertAnlsAgree()">
                        완료
                    </a>
                </div>
            </div>
            <!-- / 내용 -->

            <!-- 마지막 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-last" :class="isPopCustCntrctStep3 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="javascript:void(0)" class="popup__close">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title">분석서비스 이용 동의가<br>완료되었습니다.</p>
                        <div class="grid__wrap">
                            <div class="grid__container consent-use__pop--grid">
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">동의날짜</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDt}}</li>

                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">기간</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDate}}(1년)</li>
                                </ul>
                            </div>
                        </div>
                        <!--                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="isPopCustCntrct = false">확인</a>
                </div>
            </div>
            <!-- /마지막 -->
        </div>

        <!-- 분석요청 팝업 -->
        <div class="popup__wrap  recom-announce__pop--wrap set-pop__wrap request__pop--wrap" :class="isPopSelectAnlst ? 'active' : ''">
            <div class="popup__item--container recom-announce__pop--container">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group request__pop--group">
                    <div class="popup__item--head request__pop--head">
                        <p class="txt__title">분석 요청</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body">
                        <div class="set-pop__unit analist__unit request__pop--unit">
                            <h3 class="h3">가격 분석을 요청할 분석가를 선택해 주세요.</h3>
                            <div class="set-pop__box analist__box">
                                <label  v-for="anlst in notiAnlstList" v-bind:key="anlst.id" :for="'anls_'+anlst.id"
                                        class="analist--btn analist--btn--label" :class="(anlst.anlstType=='AI' ? 'ai' : '') + (selAnlst == anlst.id ? ' active':'')"
                                        @click.prevent="clickAnlst(anlst.id)"  >
                                    <div class="input--check analist--btn--check">
                                        <input type="checkbox" :id="'anls_'+anlst.id" name="analist" value="suri" :checked="(selAnlst == anlst.id ? 'checked':'')">
                                        <label :for="'anls_'+anlst.id" ></label>
                                    </div>
                                    <img :src="anlst.iconImg" alt="분석가 수리" class="analist--btn--img suri">
                                    <span class="analist--btn--txt">{{anlst.anlstNickNm}}</span>
                                </label>
                            </div>
                            <p class="txt--announce"> * 성공보수료<span class="deco"> -  </span><span class="line-break">AI분석가 : 낙찰가의 {{aiFee}}% (부가세 포함)</span><span class="deco"> / </span><span class="line-break">분석기업 : 낙찰가의 {{coFee}}% (부가세 포함)</span></p>
                            <div class="set-pop__box analist__box request__pop--warning">
                                <p class="txt--title">잠깐, 참가자격 확인하셨나요?</p>
                                <p class="txt--sub">공고의 참가자격을 숙지해 주시고<br>입찰에 신중히 참여해 주시기 바랍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb  popup__close" @click="isPopSelectAnlst = false;">취소</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" :class="isProcessing ? 'loading-box' : ''" @click="insertCustNoti(selectNotiId)"><LoadingSpinner v-show="isProcessing"></LoadingSpinner>{{isProcessing ? 'ㅤ' : '분석 요청'}}</a>
                </div>
            </div>
        </div>
        <!-- /분석요청 팝업 -->


        <!-- 분석서비스 이용 약관 동의 팝업-->
        <div class="popup__wrap use-consent__wrap use" :class="isPopUseAgree ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석서비스 이용 약관 동의</h3>
                        <button type="button" class="popup__close" @click="isPopUseAgree = false;"></button>
                    </div>
                    <div id="searchUseAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <EtcUseAgree></EtcUseAgree>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="searchAnlsAgreeBtn" type="submit" class="btn-basic btn__non-active" @click="closeAnlsAgreePop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석서비스 이용 약관 동의 -->

        <!-- 분석 서비스 제공 계약서 팝업-->
        <div class="popup__wrap use-consent__wrap Provis" :class="isPopCntrct ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석 서비스 제공 계약서</h3>
                        <button type="button" class="popup__close" @click="isPopCntrct = false"></button>
                    </div>
                    <div id="searchEtcAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <div class="clause wrap agree">
                            <div class="agree__table--wrap">
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자
                                                (“동”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span class="agree__table--title">
                                                    주식회사 비드톡톡</span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title">
                                                    최경호</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자
                                                (“행”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span id="cmpNm"
                                                                                                                class="agree__table--title"><strong>{{custBiz.cmpNm}}</strong></span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span id="userNm"
                                                                                                                  class="agree__table--title"><strong>{{custBiz.rpsntvNm}}</strong></span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약명</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>전자입찰 분석정보 제공계약서 </span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약 금액</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub">금<span
                                                        class="agree__table--title"><strong>0</strong></span>원 (VAT포함) </p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">성공 보수료</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름 (분석가 선택 시 수수료율 적용)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">성공 보수료 지급
                                                조건</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※낙찰일로 부터 14일 이내 (별첨 계약내용 참조)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">계약
                                                기간</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">{{currentDate}} ~ {{nextYearDate}}</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">기타</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름 *계약 기간 경과 후 상호
                                                    이의가 없을 경우 자동 연장 되며 계약금은 발생하지 않습니다.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clause__group">
                                <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨계약내용에 따라 본 “분석서비스 제공
                                    계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
                            </div>
                            <AnlsFee></AnlsFee>
                            <div class="clause__group agree__company">
                                <h2 class="clause__group--tit align-c auto-input">{{currentDate}}</h2>
                                <p class="clause__group--info agree__company--announce"><span>※ 주식회사 비드톡톡 임직원들은 전자입찰 분석정보 제공계약서에 명시된 계약금 및 성공보수료
                                    이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span></p>
                                <div class="comppany-info__group agree__company--info">
                                    <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                                    <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                                </div>
                            </div>
                            <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
                                <p class="clause__group--info"><span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를
                                    “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
                                <p class="clause__group--info"><span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나
                                    지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는
                                    정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한
                                        대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span><span>②
                                        “행”은 본 계약 체결일로부터 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우
                                        횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관
                                        제10조 참조)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서
                                        표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수
                                        없다.</span><span>② “행”이 “동”으로부터 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이
                                        확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를
                                        지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span><span>③ 공동 도급을
                                        진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span><span>④ “행”은 “동”의 웹페이지
                                        및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에
                                        참여한 것으로 보아 ②항을 적용하기로 한다. </span><span>⑤ 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex
                                        만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)
                                    </span><span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을
                                        중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반
                                        사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제5조(비밀 보장) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을
                                        제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀
                                        (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제6조(분쟁의 해결) </h2>
                                <p class="clause__group--info"><span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span></p>
                            </div>
                            <div class="agree__check--area">
                                <div class="agree__check--group pc">
                                    <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을
                                        인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                                    <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;{{custBiz.cmpNm}}</span><span></span></div>
                                    <div class="agree__check--box agree clause__group--info"><span>동의</span><input type="checkbox"
                                                                                                                   id="check16" :checked="anlsAgreeFlag" @click="anlsAgreeFlag = !anlsAgreeFlag"><label for="check16"></label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="searchCntrctAgreeBtn" type="submit" class="btn-basic btn__non-active"><p class="txt__empha" @click="closeCntrctAgreePop(true)">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석 서비스 제공 계약서 -->

        <!--  분석실 이동 성공 팝업 active 추가 -->
        <div class="popup__wrap off-auto-anal" :class="isPopSuccessCustNoti? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                        <h3 class="txt__title">가격 분석 요청 완료</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">가격 분석 요청이 완료되었습니다.</p>
                    </div>
                </div>
                <!-- 버튼이 하나일 때 popup__button--wrap 클래스 뒤에 single 추가,
                     버튼이 두개일 때 popup__button--wrap 클래스 뒤에 pair 추가-->
                <!--                <div class="popup__button&#45;&#45;wrap single">-->
                <!--                    <a href="javascript:void(0)" class="btn-basic btn-cta"><span>분석실로 이동</span></a>-->
                <!--                </div>-->
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="isPopSuccessCustNoti = false;">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="$scrollActive(); $goMenu('analysis')">분석실 이동</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /분석실 이동 성공 팝업  active 추가 -->

        <!-- 분석 요청 제한 안내 팝업 -->
        <div class="popup__wrap analy-rstrt" :class="isPopNonFulfliment ? 'active' : ''">
            <div class="popup__item--container analy-rstrt--container">
                <div class="popup__item--group">
                    <div class="popup__item--head analy-rstrt--head">
                        <h3 class="txt__title">분석 요청 제한 안내</h3>
                    </div>
                    <div class="popup__item--body analy-rstrt--body">
                        <p class="txt__title">비드톡톡 분석 가격 이행률이 낮아</p>
                        <p class="txt__title strong">분석 요청 서비스 이용이<br>
                            <span class="limit-count">{{custFulfilmentMngt != null ? custFulfilmentMngt.lockCnt : ''}}회</span> 제한되었습니다.
                        </p>
                        <p class="txt__sub mtb24">
                            <span class="date"> {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).add(1,'days').format('MM.DD (ddd)'): ''}}</span>부터 분석 요청 서비스를<br>이용하실 수 있습니다.
                        </p>
                        <p class="txt__sub rstrt-durin">분석 요청 제한 기간</p>
                        <p class="txt__sub date">
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockStartDt).format('YYYY.MM.DD (ddd)') : ''}} ~
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).format('YYYY.MM.DD (ddd)'): ''}}</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="updateLockConfirm">확인</a>
                </div>
            </div>
        </div>
        <!-- /분석 요청 제한 안내 팝업 -->
        <!-- 지역, 면허 불일치 시 분석요청 제한 팝업 -->
        <div class="popup__wrap confim limit-pop entire-img-pop" :class="isPopRejectAnls ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="popup__img"></div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__title">{{popupText}}</p>
                        <p class="txt__sub">{{popupSubText}}
                            <span v-show="popupSubText.length>0">공고문을 다시 확인해 주세요.</span>
                        </p>

                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="isPopRejectAnls = false;">확인</a>
                </div>
            </div>
        </div>
        <!-- /지역, 면허 불일치 시 분석요청 제한 팝업  -->

        <!-- 비로그인 > 분석요청시 로그인 요청 팝업 -->
        <div class="popup__wrap confim login-pop entire-img-pop" :class="isPopLogin ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="popup__img"></div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__title">로그인이 필요해요.</p>
                        <p class="txt__sub">로그인 후, 이용 가능한 기능입니다.
                            <br>로그인하고 이용해보시겠어요?
                        </p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="isPopLogin = false">둘러보기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="isPopLogin = false;  $goMenu('login')">로그인</a>
                </div>
            </div>
        </div>
        <!-- /비로그인 > 분석요청시 로그인 요청 팝업 -->
    </div>
</template>
<script>
    import mock from '../NotiSearchData';
    import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
    import EtcUseAgree from '@/pages/Comn/EtcUseAgree'
    import AnlsFee from '@/pages/Comn/AnlsFee'

    export default {
        components: {
            LoadingSpinner,
            EtcUseAgree,
            AnlsFee
        },
        data() {
            return {

                mock,
                isScrollActive : false,
                deviceType: 'Desktop',

                custBiz: [],    //사업자 목록
                custCntrctList: [], //계약서 정보
                notiAnlstList: [],  //분석가 목록


                isPopCustCntrct: false, //약관 동의 팝업
                isPopCustCntrctStep1: false,
                isPopCustCntrctStep2: false,
                isPopCustCntrctStep3: false,
                isPopUseAgree: false,   //분석서비스 약관동의팝업
                isPopCntrct: false,     //분석서비스 제공 계약서
                isPopSelectAnlst: false,    //분석가 선택 팝업
                isPopSuccessCustNoti: false,    //분석실 이동 성공
                isPopNonFulfliment: false, //불이행 팝업
                isPopRejectAnls: false,  //분석요청 실패
                isPopLogin: false,  //로그인 필요 팝업
                popupText: '',  //분석제한 팝업 텍스트
                popupSubText: '',  //분석제한 팝업 서브 텍스트

                custFulfilmentMngt: [], // 불이행 정보

                activeScroll: false,    //스크롤 활성화 여부
                anlsAgreeYn: false, //분석서비스 이용 약관 동의 여부
                cntrctAgreeYn: false, // /분석서비스 제공 계약서 동의 여부
                anlsAgreeFlag: false,



                selAnlst: '', // 분석가 선택
                selectNotiId: '', //마지막 선택 공고ID

                isProcessing: false, //클릭여부

                agreeDt: '',    //계약서 동의 일
                agreeDate: '',  //계약서 유효 기간

                email: '', //이메일
                checkEmail: false, //이메일 동의 여부

                aiFee: 2.5, // 내 AI 수수료 정보
                coFee: 2, // 내 협력사 수수료 정보
            }
        },
        computed: {
            currentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            nextYearDate(){
                const today = new Date();
                const year = today.getFullYear() + 1;
                const month = today.getMonth() + 1;
                const day = today.getDate() - 1;

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
        },
        watch:{
            isPopCustCntrct(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSelectAnlst(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopNonFulfliment(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSuccessCustNoti(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopRejectAnls(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopLogin(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },

        },
        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            this.deviceType  = this.$getDeviceType();

            if(this.loginInfo != null) {
                //계약서 정보 로드
                this.getCustCntrct();

                //불이행 정보
                this.getFulfilmentMngt();

                //수수료 정보
                this.getMyAnlsFee();
            }

            //분석가 목록
            this.getNotiAnlst()

        },
        methods: {
            //분석이용 약관 동의
            insertAnlsAgree(){
                if(!this.anlsAgreeYn || !this.cntrctAgreeYn) {
                    this.$toast.error("약관에 동의해 주세요.");
                    return;
                }
                // if(this.email != '' && this.checkEmail) {
                if(this.email != '') {
                    this.insertEmailInfo();
                }

                this.getCntrctTmple();

            },
            //템플릿 가져오기
            getCntrctTmple() {
                var param = {};

                this.$post('/api/cust/custCntrctTmpl',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            var custCntrctTmpl= res.custCntrctTmplList[0];
                            var custtmplHtml = custCntrctTmpl.cntrctTmplHtml;
                            var today = this.$moment();

                            custtmplHtml = custtmplHtml.replaceAll('#{CMP_NM}', this.loginInfo.cmpNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{USER_NM}', this.loginInfo.userNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{currentDate}',  today.format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{nextYearDate}', today.add(1, 'year').subtract(1,'days').format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                            this.insertCntrct(custCntrctTmpl,custtmplHtml);

                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            //템플릿 저장
            insertCntrct(custCntrctTmpl,custtmplHtml){
                var param = {
                    "custId": this.loginInfo.id,
                    "anlsAgreeYn": this.anlsAgreeYn ? 'Y' : 'N',
                    "cntrctAgreeYn": this.cntrctAgreeYn ? 'Y': 'N',
                    "cntrctTmplId": custCntrctTmpl.id,
                    "cntrctHtml": custtmplHtml
                };

                this.$post('/api/cust/insertCntrct',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            this.isPopCustCntrctStep1= false;
                            this.isPopCustCntrctStep2= false;
                            this.isPopCustCntrctStep3 = true;
                            this.agreeDt = this.$moment().format('YYYY.MM.DD');
                            this.agreeDate = this.$moment().format('YYYY.MM.DD') + ' ~ ' + this.$moment().add(1,"years").subtract(1,'days').format('YYYY.MM.DD');
                            this.getCustCntrct();

                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            closeAnlsAgreePop(value){

                if(value){
                    const anlsAgreeBtn = document.getElementById('searchAnlsAgreeBtn');

                    if (anlsAgreeBtn.classList.contains('btn__active')) {
                        this.anlsAgreeYn = true;
                        this.isPopUseAgree = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                } else {
                    this.isPopUseAgree = false;
                }
                // this.$refs.agree.focus();
            },
            closeCntrctAgreePop(value){
                if(value){
                    const cntrctAgreeBtn = document.getElementById('searchCntrctAgreeBtn');

                    if (cntrctAgreeBtn.classList.contains('btn__active')) {
                        this.cntrctAgreeYn = true;
                        this.anlsAgreeFlag = true;
                        this.isPopCntrct = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                    // if (this.anlsAgreeFlag) {
                    //     this.cntrctAgreeYn = true;
                    //     this.isPopCntrct = false;
                    // } else {
                    //     this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    // }
                } else {
                    this.isPopCntrct = false;
                }
                // this.$refs.agree.focus();

            },
            //공고번호 복사
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                // this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.");

                // alert("공고번호를 복사했습니다.");
            },
            //공고 상세페이지이동
            notiDetailPop(menuUrl, id) {
                if (this.$route.path != "/" + menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    window.open('/' + menuUrl + '/' + id, '_blank', 'width=1200,height=1000');
            },
            //분석실로 이동
            insertCustNoti(notiId) {
                if(this.selAnlst == ''){
                    this.$toast.error('분석가를 선택하세요.');
                    return;
                }
                if(this.isProcessing){
                    return;
                }

                this.isProcessing = true;



                var param = {
                    "custNotiIdList": [notiId],
                    "custNotiInputCd": "0",
                    "notiAnlstId": this.selAnlst,
                    "custBizId" : this.loginInfo.custBizList[0].id,
                    "accessLocation": '-통합'
                };
                this.$post('/api/noti/insertCustNoti',param)
                    .then(res => {
                        this.isProcessing = false;
                        if(res.code == 'OK'){
                            this.isPopSelectAnlst = false;
                            this.isPopSuccessCustNoti = true;

                            var idx =  mock.notiSearchList.findIndex((e) => e.id == notiId);
                            mock.notiSearchList[idx].custNotiId = 'Y';
                            // mock.notiSearchListCnt--;
                            // this.reloadNotiSuggestList();
                        } else {
                            this.$toast.error(res.msg);
                        }
                    })
                    .catch(error => {
                            console.log(error);
                            this.isProcessing = false;
                        }
                    );

            },
            //분석가 선택
            clickAnlst(id){
                this.selAnlst = id;
            },
            //분석가 목록
            getNotiAnlst() {
                this.notiAnlstList = [];

                this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                        this.notiAnlstList = notiAnlstList;
                    })
                    .catch(error => console.log(error));
            },
            //불이행 해제 팝업 확인
            updateLockConfirm(){
                var param = {
                    "id":  this.custFulfilmentMngt.id
                };

                this.$post('/api/cust/fulfilmentMngt/lockConfirm',param)
                    .then(res => {
                        if(res.code == "OK"){
                            this.isPopNonFulfliment = false;
                        } else {
                            this.isPopNonFulfliment = false;
                        }
                    })
                    .catch(error => console.log(error));
            },
            activeScrollEvent(){
                if(!this.isScrollActive){

                    this.isScrollActive = true;
                    const useAgreeScroll = document.getElementById('searchUseAgreeScroll');

                    useAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = useAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = useAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (useAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const anlsAgreeBtn = document.getElementById('searchAnlsAgreeBtn');
                            anlsAgreeBtn.classList.remove('btn__non-active');
                            anlsAgreeBtn.classList.add('btn__active');
                        }
                    });

                    // /* 개인정보 스크롤 이벤트*/
                    const etcAgreeScroll = document.getElementById('searchEtcAgreeScroll');

                    etcAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = etcAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = etcAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (etcAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const cntrctAgreeBtn = document.getElementById('searchCntrctAgreeBtn');
                            // cntrctAgreeBtn.disabled = false;
                            cntrctAgreeBtn.classList.remove('btn__non-active');
                            cntrctAgreeBtn.classList.add('btn__active');
                            //요기!!!!!!
                        }
                    });
                }
            },
            //계약서 정보 로드
            async getCustCntrct() {

                var param = {
                    "custId": this.loginInfo.id,
                };
                this.$post('/api/cust/custCntrct',param)
                    .then(res => {
                        this.custCntrctList = res.custCntrctList;
                    })
                    .catch(error => console.log(error));

            },

            async checkCustCntrct(noti) {
                this.selectNotiId = noti.notiId;

                if(this.loginInfo == null){
                    //로그인 요청 팝업
                    this.isPopLogin = true;
                } else if (this.custCntrctList.length == 0) {
                    await this.getCustCntrct();
                    // 계약서가 없으면 체크 약관 동의 팝업
                    this.$parent.$parent.showCustCntrctPop();
                    this.activeScrollEvent();

                } else if (await this.checkNonFulfilmentMngt()) {
                    this.isPopNonFulfliment = true;
                } else if (!await this.checkNotiStatus(noti)) {

                    return;
                } else {
                    this.selAnlst = '';
                    //분석요청 팝업
                    this.isPopSelectAnlst = true;
                }
            },
            async checkNotiStatus(noti){

                const today = this.$moment();
                var bddprFinDt= this.$moment(noti.bddprFinDt);
                var opbdDt= this.$moment(noti.opbdDt);


                if( today.isAfter(opbdDt) && noti.notiStatus == '개찰완료') {
                    this.isPopRejectAnls = true;
                    this.popupText = '개찰이 완료된 공고입니다.';
                    this.popupSubText = ''
                    return false;
                }

                if( today.isAfter(bddprFinDt) && noti.notiStatus == '투찰마감') {
                    this.isPopRejectAnls = true;
                    this.popupText = '투찰이 마감된 공고입니다.';
                    this.popupSubText = ''
                    return false;
                }

                //취소공고 체크
                if (noti.notiDstnctCd.toString() == '2') {
                    this.isPopRejectAnls = true;
                    this.popupText = '취소된 공고입니다.';
                    this.popupSubText = ''
                    return false;
                }

                //분석공고 체크
                if (noti.custNotiId != null) {
                    this.isPopRejectAnls = true;
                    this.popupText = '분석요청된 공고입니다.';
                    this.popupSubText = ''
                    return false;
                }
                var licnsCalFlag = true;
                var areaCalFlag = true;

                if (this.loginInfo != null) {
                    var custBiz = this.loginInfo.custBizList[0];

                    if (custBiz != null) {
                        var notiLicnsList = noti.notiLicnsList;
                        for (var idx in notiLicnsList) {
                            var licnsList = custBiz.custLicnsList
                                .filter((e) => e.licnsCd.toString() == notiLicnsList[idx].licnsCd);

                            if (licnsList.length ==0) {
                                licnsCalFlag = false;
                            } else {
                                licnsCalFlag = true;
                                break;
                            }
                        }

                        var prvncl = mock.prvnclList.filter((e) => e.areaCd.toString() == custBiz.areaCd);

                        var areaList = noti.notiAreaList
                            .filter((e) =>
                                    (e.areaNm.toString() == ""
                                            ? e.prvnclNm.toString() == prvncl[0].prvnclNm
                                            : e.prvnclNm.toString() == prvncl[0].prvnclNm && e.areaCd.toString() == prvncl[0].areaCd)
                                || e.areaCd.toString() == '0'
                            );

                        if (areaList.length == 0) {
                            areaCalFlag = false;
                        }
                    }
                }
                if (!licnsCalFlag && !areaCalFlag) {
                    this.isPopRejectAnls = true;
                    this.popupText = '분석 요청이 불가한 지역과 면허예요.';
                    this.popupSubText = '설정한 지역과 면허가 공고 정보와 일치하지 않아 분석 요청이 어렵습니다.'
                    return false;
                }
                else if (!licnsCalFlag) {
                    this.isPopRejectAnls = true;
                    this.popupText = '분석 요청이 불가한 면허예요.';
                    this.popupSubText = '설정한 면허가 공고 정보와 일치하지 않아 분석 요청이 어렵습니다.'
                    return false;
                }
                else if (!areaCalFlag) {
                    this.isPopRejectAnls = true;
                    this.popupText = '분석 요청이 불가한 지역이예요.';
                    this.popupSubText = '설정한 지역이 공고 정보와 일치하지 않아 분석 요청이 어렵습니다.'
                    return false;
                }

                return true;

            },
            //분석요청 제한 기간 체크
            async checkNonFulfilmentMngt(){

                await this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        this.custFulfilmentMngt = res.data;
                    })
                    .catch(error => console.log(error));



                if(this.custFulfilmentMngt == null){
                    return false;
                }

                const today = this.$moment();

                var lockStartDt= this.$moment(this.custFulfilmentMngt.lockStartDt);
                var lockEndDt= this.$moment(this.custFulfilmentMngt.lockEndDt).add(1,'days');

                //오늘날짜가 분석요청제한기간일 경우
                if( today.isAfter(lockStartDt) && today.isBefore(lockEndDt) && this.custFulfilmentMngt.lockStatus == 'Y') {
                    return true;
                } else {
                    return false;
                }
            },
            //불이행 여부 체크
            getFulfilmentMngt(){
                this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        this.custFulfilmentMngt = res.data;
                    })
                    .catch(error => console.log(error));
            },
            //내 수수료 정보
            getMyAnlsFee(){
                this.$get('/api/anlsFee/myAnlsFee')
                    .then(res => {
                        this.myAnlsFee = res.myAnlsFee;
                        if(this.myAnlsFee.length > 0){
                            this.aiFee = res.myAnlsFee.find((e) => e.anlstType == 'AI').anlsFee;
                            this.coFee = res.myAnlsFee.find((e) => e.anlstType == 'CO').anlsFee;
                        }
                    })
                    .catch(error => console.log(error));
            },

        }

    }


</script>