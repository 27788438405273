<template>
    <!-- 맞춤공고 자동분석 종료 알림 팝업  D-day && D+1 -->
    <div class="popup__item--container auto-fin__container">
        <div class="popup__item--group auto-fin__group">
            <div class="popup__item--head  auto-fin--head">
                <p class="txt__title">설정하신 맞춤공고 <br>자동분석이 <span class="empha">종료</span>되었습니다.</p>
            </div>
            <div class="popup__item--body auto-fin--body">
                <p class="txt__sub">{{endDt}} 맞춤공고 자동분석이<span class="line-break">꺼짐으로 변경되었습니다.</span></p>
                <p class="txt__sub">맞춤공고 자동분석을 <span class="empha">켜짐 상태로 변경해 주세요.</span> <span class="line-break">공고를 누락 없이 분석요청 받으실 수 있습니다.</span></p>
            </div>
        </div>
        <div class="popup__button--wrap single auto-fin__button--wrap">
            <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="showPop()">자동분석 기간연장</a>
        </div>
    </div>
    <!-- 맞춤공고 자동분석 종료 알림 팝업  D-day && D+1 -->

</template>

<script>
    export default {
        name: 'AutoAnlsDdayPop',

        data () {
            return {
                endDt : this.$moment().format('YYYY.MM.DD (ddd)'),
            }


        },
        methods: {
            showPop() {

                var tracking = {
                    "trackingCd": "4",
                    "trackingAction" : "CLICK",
                    "trackingNm": "자동분석 종료 후 기간연장",
                    "trackingDesc" : "자동분석 종료 후 기간연장",
                };
                this.$insertTracking(tracking);
                if(this.$parent.isPopAutoAnlsEnd){
                    this.updateAutoAnlsEndConfirmDt();
                }

                this.$parent.isPopAutoAnlsDdayPop = false;
                this.$parent.isPopAutoAnlsEnd = false;
                this.$parent.isPopSetAutoAnls = true;
            },
            /** 자동분석 종료 팝업 확인날짜 변경*/
            updateAutoAnlsEndConfirmDt(){
                this.loginInfo =JSON.parse(localStorage.getItem("custInfo"));
                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                };

                this.$post('/api/autoAnlsConfig/updAutoAnlsEndConfirmDt',param)

                    .catch(error => console.log(error));
            },
        },
        mounted() {
        }

    }

</script>