<template>
    <div class="popup__item--container">
        <div class="popup__item--group">
            <div class="popup__item--head">
                <i class="popup__img"></i>
                <p class="txt__title">
                    공사 실적 정보<br>변경이 필요합니다!
                </p>
            </div>
            <div class="popup__item--body">
                <p class="txt__sub">
                            <span class="empha">
                                24년 6월 1일부터<br>
                                23년 공사 실적이 공시되었습니다.<br>
                            </span>
                    <br>
                    하단 버튼을 클릭하여<br>
                    정보를 변경해 주세요.
                </p>
                <p class="noti-empha">
                    변경 방법에 관한 자세한 내용은<br>공지사항 확인 또는 고객센터<br>1670-0508로 문의 부탁드립니다.<br><!--<span class="empha">고객센터 1670-0508로 연락주세요.</span>-->
                </p>
            </div>
        </div>
        <div class="popup__button--wrap pair">
            <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="$parent.isPopAcrslt = false;">닫기</a>
            <a href="javascript:void(0)" class="btn-basic btn-cta" @click="pageMove()">정보 변경 이동</a>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'AcrsltPop',

        data: () => ({}),
        methods: {
            pageMove() {
                this.$goMenu('myPage#2');
                var tracking = {
                    "trackingCd": "4",
                    "trackingAction" : "CLICK",
                    "trackingNm": "경상정보 실적변경 팝업",
                    "trackingDesc" : "공사 실적 정보 변경 필요 팝업",
                };

                this.$insertTracking(tracking);
                this.$parent.isPopAcrslt = false;
            }
        },
        mounted() {
        }

    }
</script>