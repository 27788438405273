<template>
    <div class="ser-detail wrap analy">
        <h3 class="h3 f-i">한번의 클릭으로 <span>분석 요청</span></h3>
        <h2 class="h2 f-i">분석 요청은 원클릭으로 <span>톡톡!</span></h2>
        <h4 class="h4 f-i">분석가를 일일이 선택하기가 어려웠다면 나에게 맞는 분석가만 선택하고<span class="line-break--01">분석 요청하여 간편하게 분석실에서 공고를 확인하세요.</span></h4>
        <div class="ser-detail__container descrip-video f-i-d">
            <video src="@/assets/file/auto-analy_pc.mp4" autoplay muted loop class="video-pc"></video>
            <video src="@/assets/file/auto-analy_mobile.mp4" autoplay muted loop class="video-mobile"></video>
            <div class="descrip-video__group--move pc">
                <a class="move-page-btn--bg" href="javascript:void(0)" @click="$goMenu('notiSuggest')">
                    <p class="move-page-btn--title">한번의 클릭으로 분석요청</p>
                    <div class="move-page-btn--ani">
                        <span class="ani--target"><img src="@/assets/images/main/ic_view-more_service-introduce.png" alt="화살표 모양 아이콘"></span>
                        <span class="ani--txt">TOKTOK</span>
                    </div>
                </a>
            </div>
        </div>
        <a class="descrip-video__group--move mobile" href="javascript:void(0)" @click="$goMenu('notiSuggest')">
            <p class="move-page-btn--title">한번의 클릭으로 분석요청</p>
            <div class="move-page-btn--ani">
                <span class="ani--target"><img src="@/assets/images/main/ic_view-more_service-introduce.png" alt="화살표 모양 아이콘"></span>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "ServiceDetailAnaly",
        mounted() {
            const fiItem = document.querySelectorAll('.f-i');
            setTimeout(function() {
                let i = 0;
                function fiItemAddClass() {
                    if (i < fiItem.length) {
                        fiItem[i].classList.add('active');
                        i++;
                        setTimeout(fiItemAddClass, 200);
                    }
                }
                fiItemAddClass();
            }, 1200);

            setTimeout(function() {
                document.querySelector('.f-i-d').classList.add('active');
            }, 2500);

        }
    }


</script>



<!--<style src="@/IntroduceView.scss" lang="scss" scoped />-->