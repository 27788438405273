<template>
    <!-- footer -->
    <div class="help-sticky help-footer">
        <div class="help-sticky__wrap help-footer__wrap">
            <div class="select-box help-footer__group  help-footer--select">
                <button class="select-box__sel help-footer__sel">발주처 바로 가기</button>
                <ul class="select-box__list help-footer__sel--list">
                    <li v-for="(item) in bidSiteList" v-bind:key="item.id" @click="goSite(item.url,'other')"><button type="button">{{item.title}}</button></li>
                </ul>
            </div>
            <a href="javascript:void(0)" class="help-footer__group help-footer--link" @click="goSite('home','local')"><i class="ic_logo_tok_basic"></i>홈페이지</a>
        </div>
    </div>
    <!-- footer -->
</template>

<script>
    import Vue from 'vue'
    import VueCryptojs from 'vue-cryptojs'
    Vue.use(VueCryptojs)

    export default {
        name: 'BidHelperFooter',

        data: () => ({
            bidSiteList: [
                {url:"https://www.g2b.go.kr", title:"나라장터(G2B)"},
                {url:"http://srm.kepco.net", title:"한국전력공사"},
                {url:"http://www.d2b.go.kr", title:"국방종합전자조달"},
                {url:"http://ebid.lh.or.kr", title:"한국토지주택공사"},
                {url:"https://ebid.ex.co.kr", title:"한국도로공사"},
                {url:"http://ebiz.khnp.co.kr", title:"한국수력원자력"},
                {url:"http://ebid.kwater.or.kr", title:"한국수자원공사"},
                {url:"http://ebid.airport.kr/UserMain.dc", title:"인천국제공항"},
                {url:"http://bid.kogas.or.kr", title:"한국가스공사"},
                {url:"http://ebid.kra.co.kr", title:"한국마사회"},
                {url:"http://ebid.etri.re.kr/ebid/", title:"한국전자통신연구원"},
                {url:"http://ebid.korail.com", title:"한국철도공사"},
                {url:"http://ebid.kr.or.kr", title:"국가철도공단"},
                {url:"http://ebid.knoc.co.kr", title:"한국석유공사"},
                {url:"http://www.s2b.kr", title:"학교장터(S2B)"}
            ],
        }),
        methods: {
            goSite(url,type) {

                if(type == 'local'){

                    this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                    var param = 'custId=' +  this.loginInfo.id.toString();
                    param += '&custDstnctCd=' +  this.loginInfo.custDstnctCd.toString();
                    param += '&empId=' + '1';
                    param += '&notiId=' + '';
                    param += '&bizNo='+ this.loginInfo.bizNo.toString();
                    param += '&nextPage=' + url;
                    // console.log(url);
                    param += '&linkMode=' + '';

                    var encrypted  = this.$CryptoJS.AES.encrypt(param, 'MeMbErL@Gin!.!.!');
                    encrypted = encrypted.toString().replace(/\+/gi, '.').replace(/\//gi, '_').replace(/=/gi, '-');

                    window.open('/home/managerLogin?q=' + encrypted, "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
                } else {
                    window.open(url , "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
                }
            }

        },
        mounted() {
            const selectBoxes = document.querySelectorAll('.help-footer__sel');

            selectBoxes.forEach(selectBox => {
                const selBoxParent = selectBox.parentElement;
                const selBoxDisable = selBoxParent.classList.contains('disable');

                if (!selBoxDisable) {
                    /* 선택창 켜짐 */
                    selectBox.addEventListener('click', () => {
                        selectBox.classList.toggle('on');
                        document.querySelector('.help-home__wrap').style.zIndex= '-1';
                    });
                    /* /선택창 켜짐 */

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                            document.querySelector('.help-home__wrap').style.zIndex= '0';
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */

                    const selectBoxItems = selectBox.closest('.select-box').querySelectorAll('.select-box__list');

                    if (selectBoxItems.length > 0) {
                        selectBoxItems.forEach(selectBoxItem => {
                            selectBoxItem.addEventListener('click', (event) => {
                                const selectedText = event.target.innerText;
                                selectBox.innerText = selectedText;
                                document.querySelector('.help-home__wrap').style.zIndex= '0';
                            });
                        });
                    }
                }
            });
        }

    }
</script>
<style src="../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>