import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import BidHelperLayout from '@/components/Layout/HelperLayout';
//import LayoutNoFooter from '@/components/Layout/LayoutNoFooter';

// Pages

/** GNB s */
//import Home from '@/pages/Home/Home';
import Home from '@/pages/V2/Home/Home';
import HomeBannerDetail from '@/pages/V2/Home/HomeBannerDetail';
import ServiceDetailAnaly from '@/pages/V2/Home/ServiceDetailAnaly';
import ServiceDetailSuit from '@/pages/V2/Home/ServiceDetailSuit';
import ServiceDetailDownload from '@/pages/V2/Home/ServiceDetailDownload';
import ServiceDetailHelper from '@/pages/V2/Home/ServiceDetailHelper';

import NotiSearch from '@/pages/V2/NotiSearch/NotiSearch';

import NotiSuggest from '@/pages/V2/NotiSuggest/NotiSuggest';
import NoticeView from '@/pages/V2/NoticeView/NoticeView';
import NotiDetail from '@/pages/V2/NotiDetail/NotiDetail';

import Analysis from "@/pages/V2/Analysis/Analysis";

import Notice from '@/pages/V2/Notice/Notice';

import TokStory from '@/pages/V2/TokStory/TokStory';
import HowtoDetail from '@/pages/V2/TokStory/HowtoDetail';
import TokStoryView from '@/pages/V2/TokStoryView/TokStoryView';

import TokReportMain from "@/pages/V2/TokReport/TokReportMain";
import TokReportMainApp from "@/pages/V2/TokReport/TokReportMainApp";
import TokReport from "@/pages/V2/TokReport/TokReport";
import TokReportOpen from "@/pages/V2/TokReport/TokReportOpen";
import TokReportPdf from "@/pages/V2/TokReport/TokReportPdf";
import TokReportPdfMonth from "@/pages/V2/TokReport/TokReportPdfMonth";
import TokReportPdfMain from "@/pages/V2/TokReport/TokReportPdfMain";

import Membership from '@/pages/V2/Membership/Membership';
import MembershipApp from '@/pages/V2/Membership/MembershipApp';

import MyPage from '@/pages/V2/MyPage/MyPage';
import CounselBoard from '@/pages/V2/MyPage/CounselBoard';

import TokBlog from "@/pages/V2/TokStory/TokBlog";
import TokBlogDetail from "@/pages/V2/TokStory/TokBlogDetail";
import TokG2BNotice from "@/pages/V2/TokStory/TokG2BNotice";
import TokG2BCfmtUndwrt from "@/pages/V2/TokStory/TokG2BCfmtUndwrt";
/** GNB e */


import Login from "@/pages/V2/login/login";
import LoginProfile from "@/pages/V2/LoginProfile/LoginProfile";
import PasswordSet from "@/pages/V2/PasswordSet/PasswordSet";
import FindId from "@/pages/V2/FindId/FindId";
import History from '@/pages/V2/History/History';
import AppStory from "@/pages/V2/AppStory/AppStory";
import Board from '@/pages/V2/Board/Board';
import BddprList from '@/pages/V2/BddprList/BddprList';
import Faq from '@/pages/V2/Faq/Faq';
import IntroduceView from '@/pages/V2/IntroduceView/IntroduceView';
import Event from '@/pages/V2/Event/Event';
import EventView from '@/pages/V2/EventView/EventView';
import G2b from "@/pages/V2/Seo/g2b";
import Bddpr from "@/pages/V2/Seo/bddpr";
import Bid from "@/pages/V2/Seo/bid";
import Bidding from "@/pages/V2/Seo/bidding";
import G2bBddpr from "@/pages/V2/Seo/g2bBddpr";
import G2bBidding from "@/pages/V2/Seo/g2bBidding";
import G2bNoti from "@/pages/V2/Seo/g2bNoti";
import G2bProcurement from "@/pages/V2/Seo/g2bProcurement";
// import ProgNoti from '@/pages/ProgNoti/ProgNoti';
import Dashboard from '@/pages/Dashboard/Dashboard';
import Typography from '@/pages/Typography/Typography'
// import Tables from '@/pages/Tables/Basic'
// import Cust from '@/pages/Cust/Cust';
import Notifications from '@/pages/Notifications/Notifications'
// import Icons from '@/pages/Icons/Icons'
// import Charts from '@/pages/Charts/Charts'
// import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import LoginAccess from "@/pages/Login/LoginAccess";
import KakaoLogin from "@/pages/Login/KakaoLogin";
import KakaoAccess from "@/pages/Login/KakaoAccess";
import Test from "@/pages/tests/test";
import AiAnalysis from '@/pages/AiAnalysis/AiAnalysis';
import Kakao from '@/pages/Comn/kakao';
import PcPay from '@/pages/Comn/PcPay';
import PayComplete from '@/pages/Comn/PayComplete';
import PayClose from '@/pages/Comn/PayClose';
import PayInfoPop from '@/pages/Comn/PayInfoPop';
import EtcPrivacy from '@/pages/Comn/EtcPrivacy';
import EtcUseAgree from '@/pages/Comn/EtcUseAgree';
import UseAgreeApp from '@/pages/Comn/UseAgreeApp';
import EtcUseCntrctApp from '@/pages/Comn/EtcUseCntrctApp';
import EtcCntrctDwlApp from '@/pages/Comn/EtcCntrctDwlApp';
import CustCntrct from '@/pages/Comn/CustCntrct';
import CustSubscription from '@/pages/Comn/CustSubscription';
import CustSubscriptionApp from '@/pages/Comn/CustSubscriptionApp';
// import CustInfo from '@/pages/Cust/CustInfo';
import Landing from '@/pages/Landing/Landing';
import LandingV2 from '@/pages/Landing/LandingV2';
import HomePop from '@/pages/PopMenu/HomePop';
import download from "./pages/Download/download";
import Poster from "./pages/Poster/Poster";
import TrfPerInfo from "./pages/TrfPerInfo/TrfPerInfo";
import Gate from "@/pages/V2/Gate/Gate";
import Join from "@/pages/V2/login/Join";
import JoinForm from "@/pages/V2/login/JoinForm";
import JoinComplete from "@/pages/V2/login/JoinComplete";
import Redirect from "@/pages/V2/Gate/Redirect";

// import Chat from '@/pages/Chat/Chat';
//import Bidtoktok from "@/pages/Bidtoktok/Bidtoktok";

import BidHelperAnalysis from '@/pages/V2/BidHelper/Analysis/Analysis';
import IntroduceAnalysist from "@/pages/V2/Home/IntroduceAnalysist";
import BidHelperLogin from '@/pages/V2/BidHelper/Login/BidHelperLogin'


Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: "/login/naver",
      name: "naver",
      component: LoginAccess,
    },
    {
      path: "/kakaoLogin",
      name: "kakaoLogin",
      component: KakaoLogin,
    },
    {
      path: "/login/kakao",
      name: "kakao",
      component: KakaoAccess,
    },
    {
        path: '/loginProfile',
        name: 'LoginProfile',
        component: LoginProfile
    },
    {
      path: '/findId',
      name: 'FindId',
      component: FindId
    },
    {
      path: '/passwordSet',
      name: 'PasswordSet',
      component: PasswordSet
    },
    {
      path: '/appStory',
      name: 'AppStory',
      component: AppStory
    },
    {
      path: '/download',
      name: 'download',
      component: download,
      beforeEnter(to,from,next){
        console.log(to,from,next);
        window.location.href = 'https://v01appflutter.page.link/openApp';
      }
    },
    {
      path: '/etcUseAgreeApp',
      name: 'EtcUseAgreeApp',
      component: EtcUseAgree
    },
    {
      path: '/etcUseCntrctApp',
      name: 'EtcUseCntrctApp',
      component: EtcUseCntrctApp
    },
    {
      path: '/kakao',
      name: 'Kakao',
      component: Kakao,
    },
    {
      path: '/etcCntrctDwlApp',
      name: 'EtcCntrctDwlApp',
      component: EtcCntrctDwlApp
    },
    {
      path: '/custCntrct/:id',
      name: 'CustCntrct',
      component: CustCntrct
    },
    {
      path: '/custSubscription/:id',
      name: 'CustSubscription',
      component: CustSubscription
    },
    {
      path: '/custSubscriptionApp/:id',
      name: 'CustSubscriptionApp',
      component: CustSubscriptionApp
    },
    {
      path: '/etcPrivacyApp',
      name: 'EtcPrivacyApp',
      component: EtcPrivacy
    },
    {
      path: '/useAgreeApp',
      name: 'UseAgreeApp',
      component: UseAgreeApp
    },

    {
      path: '/landing',
      name: 'Landing',
      component: Landing,
      redirect: 'home'
    },
    {
      path: '/landingV2',
      name: 'LandingV2',
      component: LandingV2
    },
    {
      path: '/homePop',
      name: 'HomePop',
      component: HomePop
    },
    {
      path: '/poster',
      name: 'Poster',
      component: Poster
    },
    {
      path: '/notidetail/:id',
      name: 'NotiDetail',
      component: NotiDetail
    },
    {
      path: '/bddprList/:id',
      name: 'BddprList',
      component: BddprList
    },
    {
      path: '/g2b',
      name: 'G2b',
      component: G2b
    },
    {
      path: '/bid',
      name: 'Bid',
      component: Bid
    },
    {
      path: '/bidding',
      name: 'Bidding',
      component: Bidding
    },
    {
      path: '/bddpr',
      name: 'Bddpr',
      component: Bddpr
    },
    {
      path: '/g2bBddpr',
      name: 'G2bBddpr',
      component: G2bBddpr
    },
    {
      path: '/g2bBidding',
      name: 'G2bBidding',
      component: G2bBidding
    },
    {
      path: '/g2bNoti',
      name: 'G2bNto',
      component: G2bNoti
    },
    {
      path: '/g2bProcurement',
      name: 'G2bProcurement',
      component: G2bProcurement
    },
    {
      path: '/TrfPerInfo',
      name: 'TrfPerInfo',
      component: TrfPerInfo
    },
    {
      path: '/gate',
      name: 'gate',
      component: Gate
    },
    {
      path: '/join',
      name: 'join',
      component: Join
    },
    {
      path: '/joinForm',
      name: 'joinForm',
      component: JoinForm
    },
    {
        path: '/joinComplete',
        name: 'joinComplete',
        component: JoinComplete
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path:'/bidHelper',
      redirect: 'bidHelper/bidHelperAnalysis',
      name: 'BidHelperLayout',
      component: BidHelperLayout,
      children: [
        {
          path: 'bidHelperAnalysis',
          name: 'BidHelperAnalysis',
          component: BidHelperAnalysis,
        },
          /** 테스트 환경 경로*/
        {
          path: 'bidasist',
          name: 'bidasist',
          component: BidHelperAnalysis,
        },
      ]
    },
    {
      path: '/bidHelperLogin',
      name: 'bidHelperLogin',
      component: BidHelperLogin,
    },
    {
      path: '/jthturn=-5A%3B',
      name: 'TokReportPdfMain',
      component: TokReportPdfMain
    },
    {
      path: '/tokReportPdf/:bizNo',
      name: 'TokReportPdf',
      component: TokReportPdf
    },
    {
      path: '/tokReportPdf/:bizNo/:height',
      name: 'TokReportPdfH',
      component: TokReportPdf
    },
    {
      path: '/trp/:bizNo',
      name: 'trp',
      component: TokReportOpen
    },
    {
      path: '/tokReportPdfMonth/:bizNo/:month',
      name: 'TokReportPdfMonth',
      component: TokReportPdfMonth
    },
    {
      path: '/redirect/qr',
      name: 'QrRedirect',
      component: Redirect
    },
    {
      path: '/pay/pcPay/:id',
      component: PcPay,
      name: 'PcPay'
    },
    {
      path: '/pay/payComplete',
      component: PayComplete,
      name: 'PayComplete'
    },
    {
      path: '/pay/payClose',
      component: PayClose,
      name: 'PayClose'
    },
    {
      path: '/pay/payInfoPop',
      component: PayInfoPop,
      name: 'PayInfoPop'
    },
    {
      path: '/membershipApp',
      name: 'MembershipApp',
      component: MembershipApp,
    },
    {
      path: '/tokReportMainApp',
      name: 'TokReportMainApp',
      component: TokReportMainApp
    },
    {
    path: '/',
    redirect: 'home',
    name: 'Layout',
    component: Layout,
    children: [

      {
        path: 'home/managerLogin',
        name: 'home/managerLogin',
        component: Home,
        beforeEnter: (to, from, next) => {
          if (to.query.q) {
            if (typeof Home.methods.bidtokLogin === 'function') {
              Home.methods.bidtokLogin(to.query.q);
            }
          }
          next();
        }
      },
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'homeBannerDetail',
        name: 'HomeBannerDetail',
        component: HomeBannerDetail,
      },
      {
        path: 'introduceAnalysist',
        name: 'IntroduceAnalysist',
        component: IntroduceAnalysist,
      },
      {
        path: 'serviceDetailSuit',
        name: 'ServiceDetailSuit',
        component: ServiceDetailSuit,
      },
      {
        path: 'serviceDetailAnaly',
        name: 'serviceDetailAnaly',
        component: ServiceDetailAnaly,
      },
      {
        path: 'serviceDetailDownload',
        name: 'ServiceDetailDownload',
        component: ServiceDetailDownload,
      },
      {
        path: 'serviceDetailHelper',
        name: 'ServiceDetailHelper',
        component: ServiceDetailHelper,
      },
      // {
      //   path: 'progNoti',
      //   name: 'ProgNoti',
      //   component: ProgNoti,
      // },
      {
        path: 'typography',
        name: 'Typography',
        component: Typography,
      },
      // {
      //   path: 'cust',
      //   name: 'Cust',
      //   component: Cust
      // },
      // {
      //   path: 'tables',
      //   name: 'Tables',
      //   component: Tables
      // },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      // {
      //   path: 'icons',
      //   name: 'Icons',
      //   component: Icons
      // },
      // {
      //   path: 'charts',
      //   name: 'Charts',
      //   component: Charts
      // },
      // {
      //   path: 'maps',
      //   name: 'Maps',
      //   component: Maps
      // },
      {
        path: 'test',
        name: 'test',
        component: Test
      },
      {
        path: 'aiAnalysis',
        name: 'AiAnalysis',
        component: AiAnalysis,
      },
      {
        path: '/analysis',
        name: 'Analysis',
        component: Analysis,
        // meta :{ requiresAuth: true }
      },
      {
        path: '/faq',
        name: 'Faq',
        component: Faq
      },
      {
        path: '/notice',
        name: 'Notice',
        component: Notice
      },
      {
        path: '/board',
        name: 'Board',
        component: Board
      },
      {
        path: '/notiSuggest',
        name: 'NotiSuggest',
        component: NotiSuggest
      },
      {
        path: '/noticeView/:id',
        name: 'NoticeView',
        component: NoticeView
      },
      {
        path: '/event',
        name: 'Event',
        component: Event
      },
      {
        path: '/eventView',
        name: 'EventView',
        component: EventView
      },
      {
        path: '/notiSearch',
        name: 'NotiSearch',
        component: NotiSearch
      },
      {
        path: '/myPage',
        name: 'MyPage',
        component: MyPage
      },
      {
        path: '/counselBoard',
        name: 'CounselBoard',
        component: CounselBoard
      },
      {
        path: '/tokStory',
        name: 'TokStory',
        component: TokStory
      },
      {
        path: '/howtoDetail',
        name: 'HowtoDetail',
        component: HowtoDetail
      },
      {
        path: '/tokStoryView/:id',
        name: 'TokStoryView',
        component: TokStoryView
      },
      {
        path: '/membership',
        name: 'Membership',
        component: Membership,
        props: true
      },
      {
        path: '/history',
        name: 'History',
        component: History
      },
      {
        path: '/etcPrivacy',
        name: 'EtcPrivacy',
        component: EtcPrivacy
      },
      {
        path: '/etcUseAgree',
        name: 'EtcUseAgree',
        component: EtcUseAgree
      },
      {
        path: '/introduceView',
        name: 'IntroduceView',
        component: IntroduceView
      },
      {
        path: '/tokReportMain',
        name: 'TokReportMain',
        component: TokReportMain
      },
      {
        path: '/tokReport',
        name: 'TokReport',
        component: TokReport
      },
      {
        path: '/tokBlog',
        name: 'TokBlog',
        component: TokBlog
      },
      {
        path: '/tokBlogDetail/:id',
        name: 'TokBlogDetail',
        component: TokBlogDetail
      },
      {
        path: '/tokG2BNotice',
        name: 'TokG2BNotice',
        component: TokG2BNotice
      },
      {
        path: '/tokG2BCfmtUndwrt',
        name: 'TokG2BCfmtUndwrt',
        component: TokG2BCfmtUndwrt
      },
    ],
  },
    // {
    //   path: '/',
    //   name: 'CustInfo',
    //   component: CustInfo,
    //   children: [
    //     {
    //       path: '/custInfo',
    //       name: 'CustInfo',
    //       component: CustInfo
    //     },
    //     {
    //       path: '/custInfo/:cmpNum',
    //       name: 'CustInfo',
    //       component: CustInfo
    //     },
    //   ]
    // },
    {
      path: '*',
      name: 'Error',
      component: Error
    },


  ],
});
