<template>
    <div>
        <!-- 재계약 연장팝업 - 이벤트 -->
        <div class="popup__wrap exp-prd chg-fee event" :class="(isPopEventUser &&!isPopBasic1User && isPopCntrctDday ? ' active':'')">
            <div class="popup__item--container exp-prd--container ">
                <div class="popup__item--group exp-prd--group">
                    <div class="popup__item--body exp-prd--body">
                        <p class="chg-fee--tit">서비스 연장 안내</p>
                        <p class="txt">안녕하세요!</p>
                        <p class="txt mb14"><span class="bold big cust">{{loginInfo.cmpNm}}</span> 님
                        </p>
                        <p class="txt">비드톡톡 서비스를 <span class="line-break--01">이용해주셔서 감사합니다. </span>
                            <span class="bold line-break">
                                <span class="bg">분석 서비스가 자동 연장되어 금일부터 1년간</span>
                            </span>
                            이용 가능합니다.
                        </p>
                        <p class="txt bold txt--noti">이벤트 기간 종료 수수료 변경 알림</p>
                        <p class="txt small">성공 보수료는 기본 보수료로 <br> 변경되는 점을 알려드립니다.</p>
                        <ul class="chg-fee--list">
                            <li class="small">
                                <span class="txt bold">AI분석가</span> 낙찰 시 <span class="txt bold">&nbsp;{{AIFee}}% </span>(부가세 포함)
                            </li>
                            <li class="small">
                                <span class="txt bold">분석기업</span> 낙찰 시 <span class="txt bold">&nbsp;2% </span>(부가세 포함)
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="chg-fee--img"></div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="closeCntrctDDayPop(false)">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="closeCntrctDDayPop(true)">확인</a>
                </div>
            </div>
        </div>
        <!-- /재계약 연장팝업 - 이벤트 -->

        <!-- 재계약 연장팝업 - 비 이벤트 -->
        <div class="popup__wrap exp-prd chg-fee" :class="((!isPopEventUser &&isPopBasic1User && isPopCntrctDday) ? ' active':'')">
            <div class="popup__item--container exp-prd--container ">
                <div class="popup__item--group exp-prd--group">
                    <div class="popup__item--body exp-prd--body">
                        <p class="chg-fee--tit">서비스 연장 안내</p>
                        <p class="txt">안녕하세요!</p>
                        <p class="txt mb14"><span class="bold big cust">{{loginInfo.cmpNm}}</span> 님
                        </p>
                        <p class="txt">비드톡톡 서비스를 <span class="line-break--01">이용해주셔서 감사합니다. </span>
                            <span class="bold line-break">
                                <span class="bg">분석 서비스가 자동 연장되어 금일부터 1년간</span>
                            </span>
                            이용 가능합니다.
                        </p>
                        <p class="txt bold txt--noti">수수료 변경 알림</p>
                        <p class="txt small">성공 보수료는 기본 보수료로 <br> 변경되는 점을 알려드립니다.</p>
                        <ul class="chg-fee--list">
                            <li class="small">
                                <span class="txt bold">AI분석가</span> 낙찰 시 <span class="txt bold">&nbsp;{{AIFee}}% </span>(부가세 포함)
                            </li>
                            <li class="small">
                                <span class="txt bold">분석기업</span> 낙찰 시 <span class="txt bold">&nbsp;2% </span>(부가세 포함)
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="chg-fee--img"></div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="closeCntrctDDayPop(false)">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="closeCntrctDDayPop(true)">확인</a>
                </div>
            </div>
        </div>
        <!-- /재계약 연장팝업 - 비 이벤트 -->

        <!-- 재계약 연장팝업 - 동의 -->
        <div class="popup__wrap exp-prd basic" :class="(!isPopEventUser &&!isPopBasic1User && isPopCntrctDday ? ' active':'')">
            <div class="popup__item--container exp-prd--container ">
                <div class="chg-fee--img basic--img"></div>
                <div class="popup__item--group exp-prd--group basic--group">
                    <div class="popup__item--body chg-fee--body basic--body">
                        <p class="chg-fee--tit">서비스 연장 안내</p>
                        <p class="txt">안녕하세요!</p>
                        <p class="txt mb14"><span class="bold big cust">{{loginInfo.cmpNm}}</span> 님
                        </p>
                        <p class="txt">비드톡톡 서비스를 <span class="line-break--01">이용해주셔서 감사합니다. </span>
                            <span class="bold line-break">
                                <span class="bg">분석 서비스가 자동 연장되어 금일부터 1년간</span>
                            </span>
                            이용 가능합니다.
                        </p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="closeCntrctDDayPop(false)">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="closeCntrctDDayPop(true)">확인</a>
                </div>
            </div>
        </div>
        <!-- /재계약 연장팝업 - 동의 -->

        <!-- 재계약 연장팝업 - 분석서비스 이용 동의 홍보 -->
        <div class="popup__wrap exp-prd agre" :class="isPopJoinYear ? 'active':''">
            <div class="popup__item--container exp-prd--container">
                <div class="popup__item--group exp-prd--group">
                    <div class="popup__item--head">
                        <p class="txt__title">비드톡톡 서비스 동의 안내</p>
                    </div>
                    <div class="popup__item--body exp-prd--body">
                        <p class="chg-fee--tit">서비스 연장 안내</p>
                        <p class="txt">안녕하세요!</p>
                        <p class="txt mb14"><span class="bold big cust">{{loginInfo.cmpNm}}</span> 님
                        </p>
                        <p class="txt">비드톡톡의 더 편리한 서비스를 이용하기 위해 <br>분석 서비스 이용 동의해주세요.</p>
                    </div>
                </div>
                <ul class="agre__slide">
                    <li class="agre__slide--item ai"></li>
                    <li class="agre__slide--item sel"></li>
                </ul>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="closeJoinYearPop();">동의하러 가기</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: 'ExtendCntrctPop',

        data: () => ({
            loginInfo: {
                cmpNm: '',
                id: '',
                custBizList: [],
            },

            isPopJoinYear: false,   //분석서비스 동의 유도 팝업
            isPopCntrctDday: false, //분석서비스 연장 팝업
            isPopBasic1User: false, //기본1 이용권 여부
            isPopEventUser: false,  //이벤트 이용권 여부
            anlsFeeGroup: [],
            anlsFeeList: [],
            AIFee:'',
        }),
        watch: {
            isPopJoinYear(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopCntrctDday(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
        },
        methods: {
            /** 분석연장 유도팝업 닫기*/
            closeJoinYearPop() {
                var tracking = {
                    "trackingCd": "8",
                    "trackingAction": "CLICK",
                    "trackingNm": "분석동의 유도 팝업",
                    "trackingDesc": "분석동의 유도 팝업",
                };

                this.$insertTracking(tracking);
                this.isPopJoinYear = false;
                this.updateConf1y();
                this.$parent.isPopCustCntrct = true;
                this.$parent.isPopCustCntrctStep1 = true;
                this.$parent.activeScrollEvent();
            },

            /** 분석연장 유도팝업 확인 플래그 업데이트*/
            updateConf1y() {
                var params = {};
                this.$post('/api/home/updateConf1y', params)
                    .then(res => {
                        console.log(res);

                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );
            },

            /** 분석연장 동의 팝업창 닫기*/
            closeCntrctDDayPop(flag) {
                var tracking = {
                    "trackingCd": "8",
                    "trackingAction": flag ? "CLICK" : "CLOSE",
                    "trackingNm": "분석동의서 연장 팝업",
                    "trackingDesc": "분석동의서 연장 팝업",
                };

                this.$insertTracking(tracking);
                this.isPopCntrctDday = false;
                if (flag) {
                    this.updateConfDDay();
                }
            },

            /** 분석연장 동의 팝업창 확인 플래그 업데이트*/
            updateConfDDay() {
                var params = {};
                this.$post('/api/home/updateConfDDay', params)
                    .then(res => {
                        console.log(res);

                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            /** 팝업 슬라이드 스크립트*/
            popupSlideScript() {
                $('.agre__slide').slick({
                    dots: true,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 1,
                    customPaging: function (slider, i) {
                        // var thumb = $(slider.$slides[i]).data();
                        if (i == '0') {
                            i = "1";
                        } else if (i == '1') {
                            i = "2";
                        }
                        return '<a class="agre__slide--dot">' + i + '</a>';
                    }
                });
            },
            //수수료 가져오기
            getAnlsFee() {
                this.$get('/api/anlsFee/list')
                        .then(res => {
                            this.anlsFeeList = res.anlsFeeList;

                            if (this.anlsFeeList.length > 0) {
                                if (this.anlsFeeList[0].notiAnlstId == 3) { // 임시
                                    this.AIFee = this.anlsFeeList[0].anlsFee;
                                }
                            }
                        })
                        .catch(error => console.log(error));
            },
        },
        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo")) ?? this.loginInfo;

            setTimeout(()=>this.popupSlideScript(), 500);

            this.getAnlsFee();

        }

    }
</script>